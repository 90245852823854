import axios from 'axios';

import { message } from 'antd';
import BASE_URL from 'views/constants/urlConstants';
import { UiActions } from './ui';

import Cookies from 'js-cookie';

const AuthActions = {
  login(email, password) {
    return dispatch =>
      axios({
        method: 'POST',
        url: `${BASE_URL}/login`,
        data: {
          email,
          password
        }
      })
        .then(res => {
          console.info(`Successful login, env is ${process.env.NODE_ENV}`);

          const token = res.data.data.token;
          sessionStorage.setItem('accessToken', token);

          // Also setting token into cookie for SSO
          // Only for production
          if (process.env.NODE_ENV === 'production') {
            Cookies.set('AT', token, { domain: 'ttl.cool' });
          }

          dispatch(UiActions.updateIn(['common', 'isLoggedIn'], true));
        })
        .catch(error => {
          console.log('Error is', error);
          message.error('Error: Unable to login user');
        });
  }
};

export { AuthActions };
