import React from 'react';
// import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Popconfirm, Card, Divider } from 'antd';

import Checkbox from '@material-ui/core/Checkbox';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import {
  CategoryActions,
  categoriesByNameSelector
} from 'redux/modules/categories';

function CategoriesTable() {
  const categoriesByName = useSelector(state =>
    categoriesByNameSelector(state)
  );
  // const categories = useSelector(state => state.categories);
  const dispatch = useDispatch();

  return (
    <Card size="small">
      {Object.keys(categoriesByName).map((categoryName, index) => (
        <React.Fragment key={index}>
          <div>
            <div
              style={{
                backgroundColor: 'whitesmoke'
              }}
            >
              <span
                style={{
                  marginRight: 10,
                  backgroundColor: `#${categoriesByName[categoryName].color}`
                }}
              >
                <TextField
                  id="standard-basic"
                  onKeyDown={event => {
                    if (event.which === 13) {
                      const cat = Object.assign(categoriesByName[categoryName]);

                      cat.color = event.target.value;
                      delete cat.projects;
                      dispatch(CategoryActions.update(cat));
                    }
                  }}
                  defaultValue={categoriesByName[categoryName].color}
                />
              </span>

              {`${categoriesByName[categoryName].displayOrder}  ${categoriesByName[categoryName].name}`}

              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  const cat = Object.assign(categoriesByName[categoryName]);

                  const newProject = {
                    name: '',
                    estimatedPomos: 0
                  };

                  dispatch(
                    CategoryActions.createNested(
                      'projects',
                      cat.categoryId,
                      newProject,
                      'projectId'
                    )
                  );
                }}
              >
                Add
              </Button>

              <Checkbox
                checked={categoriesByName[categoryName].isPomo}
                onClick={() => {
                  const cat = Object.assign(categoriesByName[categoryName]);

                  cat.isPomo = !cat.isPomo;
                  dispatch(CategoryActions.update(cat));
                }}
              />

              <Popconfirm
                title={`Are you sure to delete this category ${categoriesByName[categoryName].name}? \n This might cause unwanted behavior. Please make sure you have not used this category in last 7 days`}
                onConfirm={() => {
                  const cat = Object.assign(categoriesByName[categoryName]);
                  // TODO: implement this only a weeek after 02/24
                  dispatch(CategoryActions.deleteRecord(cat));
                }}
                okText="Yes"
                cancelText="No"
              >
                Delete
              </Popconfirm>
            </div>

            {Object.values(categoriesByName[categoryName].projects)
              .sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1))
              .map(project => (
                <div key={project.id}>
                  <TextField
                    label="Order"
                    style={{ width: 100, height: 40 }}
                    type="number"
                    margin="dense"
                    // placeholder={record.get('name')}
                    defaultValue={project.displayOrder}
                    onKeyDown={event => {
                      if (event.which === 13) {
                        const newProject = Object.assign(project);
                        newProject.displayOrder = event.target.value;

                        dispatch(
                          CategoryActions.updateNested(
                            'projects',
                            categoriesByName[categoryName].categoryId,
                            newProject,
                            newProject.id
                          )
                        );
                      }
                    }}
                    variant="outlined"
                  />

                  <TextField
                    label="Name"
                    style={{ width: 300, height: 40, marginLeft: 10 }}
                    margin="dense"
                    // placeholder={record.get('name')}
                    defaultValue={project.name}
                    onKeyDown={event => {
                      if (event.which === 13) {
                        const newProject = Object.assign(project);
                        newProject.name = event.target.value;

                        dispatch(
                          CategoryActions.updateNested(
                            'projects',
                            categoriesByName[categoryName].categoryId,
                            newProject,
                            newProject.id
                          )
                        );
                      }
                    }}
                    variant="outlined"
                  />

                  <TextField
                    label={`Plan ${
                      categoriesByName[categoryName].isPomo ? 'min' : 'max'
                    }`}
                    style={{ width: 100, marginLeft: 10 }}
                    type="number"
                    margin="dense"
                    defaultValue={project.estimatedHourWeek}
                    onKeyDown={event => {
                      if (event.which === 13) {
                        const newProject = Object.assign(project);
                        newProject.estimatedHourWeek = event.target.value;

                        dispatch(
                          CategoryActions.updateNested(
                            'projects',
                            categoriesByName[categoryName].categoryId,
                            newProject,
                            newProject.id
                          )
                        );
                      }
                    }}
                    variant="outlined"
                  />

                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      if (window.confirm('Deleting project')) {
                        const cat = Object.assign(
                          categoriesByName[categoryName]
                        );

                        // const newProject = {
                        //   name: '',
                        //   estimatedPomos: 0
                        // };

                        dispatch(
                          CategoryActions.deleteNestedRecord(
                            'projects',
                            cat.categoryId,
                            project,
                            project.id
                          )
                        );
                      }
                    }}
                  >
                    Delete
                  </Button>
                </div>
              ))}
          </div>
          <Divider />
        </React.Fragment>
      ))}
    </Card>
  );
}

CategoriesTable.defaultProps = {};

CategoriesTable.propTypes = {};

export default CategoriesTable;
