import { createSelector } from 'reselect';
import { isImmutable, fromJS } from 'immutable';
import moment from 'moment';
import _ from 'lodash';
import { reducerCreate } from '../_base/reducerCreate';
import { actionCreate } from '../_base/actionCreate';
import { selectedDayDailyTasksSelector } from './dailyTasks';

import { categoriesByProjectSelector } from './categories';

import { getMetricsForOneDailyRecord } from './dailyRecordsUtil';

export default reducerCreate('daily_record');
export const { DailyRecordActions } = actionCreate('daily_record');

const getDailyRecords = state => state.dailyRecords;
const getSelectedDate = state =>
  state.ui !== undefined ? state.ui.getIn(['hourblock', 'selectedDate']) : '';
const getCategories = state => state.categories;

export const getDailyRecordByDateObject = createSelector(
  [getDailyRecords],
  dailyRecords => {
    const res = {};

    if (isImmutable(dailyRecords)) {
      const dailyRecordsOriginal = dailyRecords.toJS();
      Object.values(dailyRecordsOriginal).forEach(rec => {
        res[rec.dailyRecordDate] = rec;
      });
    }

    return res;
  }
);

// export const dailyRecordsListSortedSelector = createSelector(
//   [getDailyRecords],
//   (dailyRecords) => {
//     let res = []
//     if (isImmutable(dailyRecords)) {
//       res = dailyRecords.valueSeq().toJS().
//     }

//     return res
//   }
// )

export const dailyRecordsDataForChartSelector = createSelector(
  [getDailyRecords],
  dailyRecords => {
    const res = [[], [], [], []]; // label, weight, wake, sleep

    if (isImmutable(dailyRecords)) {
      const d = dailyRecords.toJS();

      Object.values(d)
        .filter(rec => rec.dailyRecordDate < moment().format('YYYY-MM-DD'))
        .sort((a, b) => (a.dailyRecordDate < b.dailyRecordDate ? 1 : -1))
        .forEach(rec => {
          res[0].push(rec.dailyRecordDate);
          res[1].push(parseFloat(rec !== undefined ? rec.weight : 0));

          // Default wake time is 9:00
          res[2].push(
            (rec === undefined || rec.wakeHour === null ? 9 : rec.wakeHour) *
              100 +
              (rec === undefined || rec.wakeMinute === null
                ? 0
                : rec.wakeMinute)
          );

          res[3].push(
            (rec === undefined || rec.sleepHour === null || rec.sleepHour === ''
              ? 26 // Default rest time is 26:00
              : parseInt(rec.sleepHour, 10)) *
              100 +
              (rec === undefined ||
              rec.sleepMinute === null ||
              rec.sleepMinute === ''
                ? 0
                : parseInt(rec.sleepMinute, 10))
          );
        });
    }
    return res;
  }
);

export const dailyRecordsWorkoutsObjectSelector = createSelector(
  [getDailyRecords],
  dailyRecords => {
    const res = {};

    if (isImmutable(dailyRecords)) {
      dailyRecords.keySeq().forEach(t => {
        res[t] = dailyRecords.hasIn([t, 'workout'])
          ? dailyRecords.getIn([t, 'workout']).toJS()
          : {};
      });
    }
    return fromJS(res);
  }
);

export const todayDailyRecordSelector = createSelector(
  [getDailyRecords],
  dailyRecords => {
    const todayString = moment().format('YYYY-MM-DD');

    if (isImmutable(dailyRecords)) {
      const res = dailyRecords.find(
        rec => rec.get('dailyRecordDate') === todayString
      );

      return res;
    }

    return fromJS({});
  }
);

export const selectedDailyRecordSelector = createSelector(
  [getDailyRecords, getSelectedDate],
  (dailyRecords, selectedDate) => {
    if (isImmutable(dailyRecords)) {
      const res = dailyRecords.find(rec => {
        return rec.get('dailyRecordDate') === selectedDate;
      });

      return res;
    }

    return fromJS({});
  }
);

export const dailyRecordMetricsSelector = createSelector(
  [selectedDailyRecordSelector, categoriesByProjectSelector],
  (selectedDailyRecord, categoriesByProject) => {
    const res = getMetricsForOneDailyRecord(
      selectedDailyRecord === undefined
        ? fromJS({})
        : selectedDailyRecord.toJS(),
      categoriesByProject
    );

    return res;
  }
);

/**
 * Selected daily record reminder for unfilled routine
 */
export const dailyRecordRoutineMetricsSelector = createSelector(
  [selectedDailyRecordSelector],
  selectedDailyRecord => {
    let unfilledRoutineCount = 0; // wake time, sleep time, weight

    if (selectedDailyRecord !== undefined) {
      if (
        selectedDailyRecord.get('sleepHour') === null ||
        selectedDailyRecord.get('sleepMinute') === null
      ) {
        unfilledRoutineCount += 1;
      }

      if (
        selectedDailyRecord.get('wakeHour') === null ||
        selectedDailyRecord.get('wakeMinute') === null
      ) {
        unfilledRoutineCount += 1;
      }

      if (selectedDailyRecord.get('weight') === null) {
        unfilledRoutineCount += 1;
      }
    }

    return unfilledRoutineCount;
  }
);

/**
 * Getting the score for the day
 */
// Daily Points: 25 (Pomo) for 12H core work,
// 50 for 12H completing task,
// 25 for completing 5 key tasks
//           <br />
//           Weekly Points: 25% for completing the planned hours, 25% for
//           completing weekly tasks, 50% from daily points
//           <br />
//           Need to customize this
// TODO: merge this with the scoreSelectedDailyRecordSelector in util
export const scoreSelectedDailyRecordSelector = createSelector(
  [dailyRecordMetricsSelector, selectedDayDailyTasksSelector],
  (dailyRecordMetrics, selectedDayDailyTasks) => {
    let pointFromPomo = 0; // out of 25
    let pointFromCompletingTaskPomo = 0; // from 50
    let pointFromCompletingKeyTasks = 0; // from 25

    const countKeyTasks = selectedDayDailyTasks
      .filter(a => a.get('isKeyTask'))
      .count();

    const completedCountKeyTasks = selectedDayDailyTasks
      .filter(a => a.get('isKeyTask') && a.get('isCompleted'))
      .count();

    pointFromPomo =
      dailyRecordMetrics.isPomoHour >= 12
        ? 25
        : dailyRecordMetrics.isPomoHour * 2;

    pointFromCompletingTaskPomo =
      dailyRecordMetrics.isCompletedTaskMainHour >= 12
        ? 50
        : dailyRecordMetrics.isCompletedTaskMainHour * 4;

    pointFromCompletingKeyTasks = parseInt(
      (completedCountKeyTasks / (countKeyTasks || 1)) * 25,
      10
    );

    return {
      pointFromPomo,
      pointFromCompletingTaskPomo,
      pointFromCompletingKeyTasks,
      total:
        pointFromPomo +
        pointFromCompletingTaskPomo +
        pointFromCompletingKeyTasks
    };
  }
);

const getThisWeekDailyRecord = state => {
  const records = state.dailyRecords.toJS();
  const res = [];

  Object.values(records).forEach(rec => {
    if (moment(rec.dailyRecordDate).isoWeek() === moment().isoWeek()) {
      res.push(rec);
    }
  });
  return res;
};

/**
 * use for weekly planning
 * show the hourblock count for each categry
 * Format is: { byCategory: {__name__: __count__}, byProject: { name: {category, value: __value__}}}
 */
export const currentWeekHourblockSelector = createSelector(
  [getThisWeekDailyRecord, getCategories, categoriesByProjectSelector],
  (dailyRecordsOfWeek, categories, categoriesByProject) => {
    const byCategory = {};
    const byProject = {};

    if (isImmutable(categories)) {
      categories
        .valueSeq()
        .toJS()
        .forEach(cat => {
          if (byCategory[cat.name] === undefined) {
            byCategory[cat.name] = 0;
          }
        });

      Object.values(categoriesByProject).forEach(proj => {
        byProject[proj.name] = {
          category: proj.category,
          value: 0
        };
      });

      dailyRecordsOfWeek.forEach(dailyRecord => {
        if (dailyRecord.dailyRecordHourblocks !== null) {
          Object.values(dailyRecord.dailyRecordHourblocks).forEach(pomo => {
            if (pomo.projectId !== null) {
              const catName = categoriesByProject[pomo.projectId].category.name;
              byCategory[catName] += 0.5;
              byProject[categoriesByProject[pomo.projectId].name].value += 0.5;
            }
          });
        }
      });
    }

    return {
      byCategory,
      byProject
    };
  }
);

/**
 * For the stack bar chart on the hourblock table
    {
      category: 'Play',
      week: 17,
      count: 50
    },
 */
export const allWeeksHourblockByCategorySelector = createSelector(
  [getDailyRecords, getCategories, categoriesByProjectSelector],
  (dailyRecords, categories, categoriesByProject) => {
    const res = [];

    if (
      isImmutable(categories) &&
      isImmutable(dailyRecords) &&
      categoriesByProject !== undefined
    ) {
      const records = dailyRecords.valueSeq().toJS();

      // Then add the metrics for each day
      records.forEach(dailyRecord => {
        const weekNumber = moment(dailyRecord.dailyRecordDate).isoWeek();

        if (dailyRecord.dailyRecordHourblocks !== null) {
          Object.values(dailyRecord.dailyRecordHourblocks).forEach(
            hourblock => {
              if (
                hourblock.projectId !== null &&
                Object.values(categoriesByProject).length
              ) {
                const catName =
                  categoriesByProject[hourblock.projectId] === undefined
                    ? null
                    : categoriesByProject[hourblock.projectId].category.name;

                const recordIndex = _.findIndex(
                  res,
                  rec => rec.category === catName && rec.week === weekNumber
                );

                if (res[recordIndex] === undefined) {
                  res.push({
                    category: catName,
                    week: weekNumber,
                    count: 0.5
                  });
                } else {
                  res[recordIndex].count += 0.5;
                }
              }
            }
          );
        }
      });
    }

    // Sort res by week then category order
    // Object.values(categories.toJS()).forEach(cat => {
    //   res.push({
    //     category: cat.name,
    //     data: [],
    //     backgroundColor: `#${cat.color}`
    //   });
    // });

    const getCategoryOrder = rec => {
      if (rec.category == null) return 99; // In case of deleting projects from category

      const res = _.find(
        Object.values(categories.toJS()),
        cat => cat.name === rec.category
      );

      return res.displayOrder;
    };

    res.sort((a, b) => {
      if (a.weekNumber !== b.weekNumber) {
        return a.weekNumber < b.weekNumber ? -1 : 1;
      }

      return getCategoryOrder(a) > getCategoryOrder(b) ? 1 : -1;
    });

    const categoriesColors = [];
    Object.values(categories.toJS()).forEach(c => {
      categoriesColors.push(`#${c.color}`);
    });

    return [res, categoriesColors];
  }
);

const getLastFourWeekDailyRecord = state => {
  const records = state.dailyRecords.toJS();
  const res = [];

  Object.values(records).forEach(rec => {
    const beginningOf4WeekAgo = moment()
      .startOf('isoWeek')
      .add('-4', 'week');
    const endOf4WeekAgo = moment()
      .endOf('isoWeek')
      .add('-1', 'week');

    if (
      moment(rec.dailyRecordDate).valueOf() >= beginningOf4WeekAgo.valueOf() &&
      moment(rec.dailyRecordDate).valueOf() <= endOf4WeekAgo.valueOf()
    ) {
      res.push(rec);
    }
  });

  return res;
};

// Last 7 days excluding today
const getLast7DayDailyRecord = state => {
  const records = state.dailyRecords.toJS();
  const res = [];

  const getDayOfYear = dailyRecordDate =>
    moment(dailyRecordDate).year() * 356 + moment(dailyRecordDate).dayOfYear();

  Object.values(records).forEach(rec => {
    if (
      moment().diff(moment(rec.dailyRecordDate), 'days') <= 7 &&
      moment().diff(moment(rec.dailyRecordDate), 'days') > 0
    ) {
      res.push(rec);
    }
  });

  return res;
};

/**
 * Getting weekly metrics for every week
 */
export const weeklyRecordMetricsSelector = createSelector(
  [
    getThisWeekDailyRecord,
    getLastFourWeekDailyRecord,
    getLast7DayDailyRecord,
    categoriesByProjectSelector
  ],
  (
    weeklyRecords,
    lastFourWeekDailyRecord,
    last7DaysDailyRecord,
    categoriesByProject
  ) => {
    const res = {
      currentWeekIsPomoHour: 0,
      currentWeekTaskMainCompleted: 0,
      currentWeekIsProjectFollowPlan: 0,
      lastWeekAverageHealthData: [] // [number, number, number]
    };

    if (weeklyRecords === undefined) {
      return res;
    }

    weeklyRecords.forEach(rec => {
      const newRes = getMetricsForOneDailyRecord(rec, categoriesByProject);
      res.currentWeekIsPomoHour += newRes.isPomoHour;
      res.currentWeekIsProjectFollowPlan += newRes.isProjectFollowPlan;
      // res.currentWeekTask;
    });

    const last7DaysDailyRecordSleepArray = [];
    const last7DaysDailyRecordWakeArray = [];
    const last7DaysDailyRecordWeightArray = [];
    const last7DaysDailyRecordStartWorkArray = [];

    last7DaysDailyRecord.forEach(rec => {
      // const oneRecordData = getMetricsForOneDailyRecord(
      //   rec,
      //   categoriesByProject
      // );

      if (rec.sleepHour !== null) {
        last7DaysDailyRecordSleepArray.push(
          rec.sleepHour * 60 + rec.sleepMinute
        );
      }

      if (rec.wakeHour !== null) {
        last7DaysDailyRecordWakeArray.push(rec.wakeHour * 60 + rec.wakeMinute);
      }

      if (rec.weight !== null && rec.weight !== undefined) {
        last7DaysDailyRecordWeightArray.push(rec.weight);
      }

      if (rec.startWorkHour !== null && rec.startWorkMinute !== undefined) {
        last7DaysDailyRecordStartWorkArray.push(
          rec.startWorkHour * 60 + rec.startWorkMinute
        );
      }
    });

    res.lastWeekAverageHealthData = [
      (
        _.sum(last7DaysDailyRecordWakeArray) /
        last7DaysDailyRecordWakeArray.length
      ).toFixed(0),
      (
        _.sum(last7DaysDailyRecordSleepArray) /
        last7DaysDailyRecordSleepArray.length
      ).toFixed(0),
      _.sum(last7DaysDailyRecordWeightArray) /
        last7DaysDailyRecordWeightArray.length,
      (
        _.sum(last7DaysDailyRecordStartWorkArray) /
        last7DaysDailyRecordStartWorkArray.length
      ).toFixed(0)
    ];

    return res;
  }
);
