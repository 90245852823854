import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import ProjectSelect from 'views/components/atoms/ProjectSelect';
import { categoriesByProjectSelector } from 'redux/modules/categories';
import ProCard from '@ant-design/pro-card';
import { Space } from 'antd';
import WeeklyPlanningInfo from './WeeklyPlanningInfo';
import WeeklyPlanningBlock from './WeeklyPlanningBlock';

const useStyles = makeStyles({
  root: {
    width: '100%',
    marginTop: 10,
    overflowX: 'auto'
  },
  block: {
    minWidth: 40
  }
});

export default () => {
  const categoriesByProject = useSelector(state =>
    categoriesByProjectSelector(state)
  );

  const classes = useStyles({});

  const hourblocks = () => {
    const res = [];
    for (let i = 0; i < 48; i += 1) {
      res.push({
        index: i,
        time: `${parseInt(i / 2, 10)}:${i % 2 === 0 ? '00' : '30'}`
      });
    }

    return res;
  };

  const [selectedProject, setSelectedProject] = useState(null);

  const updateSelectedProject = (cat, projectName) => {
    let newlySelectedProject = _.find(
      Object.values(categoriesByProject),
      rec => rec.name === projectName
    );

    console.log(
      `Newly selected proejct is ${JSON.stringify(newlySelectedProject)}`
    );

    if (newlySelectedProject === null || newlySelectedProject === undefined) {
      newlySelectedProject = '';
    }

    setSelectedProject(newlySelectedProject);
  };

  return (
    <ProCard
      title="计划本周"
      extra={
        <Space>
          Pick Project
          <ProjectSelect
            selectedProject={selectedProject}
            onChangeCallBack={updateSelectedProject}
          />
        </Space>
      }
      split="vertical"
      ghost
      gutter={8}
      headerBordered
    >
      <ProCard
        colSpan="200px"
        direction="column"
        size="small"
        gutter={[0, 8]}
        ghost
        style={{
          maxHeight: 'calc(100vh - 200px)',
          overflow: 'auto'
        }}
      >
        <WeeklyPlanningInfo />
        {/* <Button
          variant="contained"
          color="primary"
          onClick={() => setUpdateMinorTask(!updateMinorTask)}
        >
          Minor
        </Button> */}
      </ProCard>

      <ProCard
        size="small"
        style={{
          maxHeight: 'calc(100vh - 200px)',
          overflow: 'auto'
        }}
      >
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="center">MON</TableCell>
              <TableCell align="center">TUE</TableCell>
              <TableCell align="center">WED</TableCell>
              <TableCell align="center">THU</TableCell>
              <TableCell align="center">FRI</TableCell>
              <TableCell align="center">SAT</TableCell>
              <TableCell align="center">SUN</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {hourblocks().map(row => (
              <TableRow key={row.index}>
                <TableCell component="th" scope="row" align="center">
                  {row.time}
                </TableCell>

                {[0, 1, 2, 3, 4, 5, 6].map(i => (
                  <TableCell key={i} align="center">
                    <WeeklyPlanningBlock
                      dayIndex={i}
                      plannedPomosIndex={row.index}
                      selectedProject={selectedProject}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </ProCard>
    </ProCard>
  );
};
