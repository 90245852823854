let env = 'local';
if (process.env.REACT_APP_ENV !== undefined) {
  env = process.env.REACT_APP_ENV;
} else {
  env =
    window.document.location.href.indexOf('localhost') > 0 ? 'local' : 'prod';
}

console.log('🔥🔥env🔥🔥');
console.log(env);

// http://82.156.119.19:32762/api/v1
// http://localhost:8080/api/v1
// https://www.ttl.cool/api
// http://35.77.37.195:8080/api/v1

const BASE_URL =
  env === 'dev' || env === 'local'
    ? 'http://localhost:7002/api'
    : 'https://api.tanchen.me/api'; // Using nginx

export default BASE_URL;
