// import moment from 'moment';
// import { createSelector } from 'reselect';
// import { isImmutable, fromJS } from 'immutable';
// import moment from 'moment';

// import { createSelector } from 'reselect';
import { actionCreate } from '../_base/actionCreate';
import { reducerCreate } from '../_base/reducerCreate';

export default reducerCreate('yearly_task');

export const { YearlyTaskActions } = actionCreate('yearly_task');
