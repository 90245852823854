import React from 'react';
import { useDispatch } from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';

import { HabitActions } from 'redux/modules/habits';
import HabitTableRowName from 'views/4-Routine/HabitRowName';
import MATERIAL_COLORS from 'views/styles/colors';

function HabitRow({ habit, days }) {
  const isChecked = day => {
    return (
      habit.get('completedDates') !== undefined &&
      habit.get('completedDates') !== null &&
      habit.get('completedDates').indexOf(day) > -1
    );
  };

  const dispatch = useDispatch();

  return (
    <>
      <HabitTableRowName habit={habit} />

      <TableRow height={50}>
        <TableCell />

        {days.map(day => (
          <TableCell
            key={day}
            align="right"
            style={{
              backgroundColor:
                day === moment().format('YYYY-MM-DD')
                  ? MATERIAL_COLORS.Primary
                  : MATERIAL_COLORS.LightGray
            }}
          >
            <Checkbox
              value={1}
              disabled={
                moment(`${day}`).dayOfYear() + 2 <= moment().dayOfYear()
              }
              checked={isChecked(day)}
              onChange={() => {
                let completedDates = habit.has('completedDates')
                  ? habit.get('completedDates')
                  : '';

                if (isChecked(day)) {
                  completedDates = completedDates.replaceAll(day, '');
                } else {
                  completedDates =
                    completedDates !== null && completedDates.length >= 0
                      ? completedDates.concat(` ${day}`)
                      : day;
                }

                const newHabit = habit.set('completedDates', completedDates);
                dispatch(HabitActions.update(newHabit));
              }}
              // inputProps={{
              //   'aria-label': 'primary checkbox'
              // }}
            />
          </TableCell>
        ))}
      </TableRow>
    </>
  );
}

export default HabitRow;
