import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { isImmutable } from 'immutable';
import moment from 'moment';

import { Card } from 'antd';

import _ from 'lodash';

import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import MATERIAL_COLORS from 'views/styles/colors';
import { categoriesByProjectSelector } from 'redux/modules/categories';
import HourblockCalendarBox from './HourblockCalendarBox';

export default () => {
  let [events, setEvents] = useState([]);

  let [showPopUp, setShowPopUp] = useState(false);
  let [popupX, setPopupX] = useState(0);
  let [popupY, setPopupY] = useState(0);

  const dailyPlannedHourblocks = useSelector(
    state => state.dailyPlannedHourblocks
  );

  const categoriesByProject = useSelector(state =>
    categoriesByProjectSelector(state)
  );

  const getColor = projectId => {
    const project = categoriesByProject[projectId];

    return project.category.color;
  };

  const findNextBlockWithTask = block => {
    let itemWithTasks = Object.values(dailyPlannedHourblocks.toJS())
      // .filter(i => i.taskMain.length)
      .sort((a, b) => {
        return a.sectionOfWeek > b.sectionOfWeek ? 1 : -1;
      });

    let index = _.findIndex(
      itemWithTasks,
      e => {
        return e.sectionOfWeek === block;
      },
      0
    );

    // If next task is today, end time will be end of day
    if (
      index + 1 < itemWithTasks.length &&
      parseInt(itemWithTasks[index + 1].sectionOfWeek / 24, 10) ===
        parseInt(block / 24, 10)
    ) {
      return itemWithTasks[index + 1].sectionOfWeek - block;
    }

    return 1;
  };

  useEffect(() => {
    if (isImmutable(dailyPlannedHourblocks)) {
      const newEvents = [];

      Object.values(dailyPlannedHourblocks.toJS())
        // .filter(i => i.taskMain.length)
        .forEach(item => {
          newEvents.push({
            title: item.taskMain,
            raw: item,
            start: moment()
              .startOf('isoWeek')
              .add(item.sectionOfWeek * 30, 'minute')
              .format(),
            end: moment()
              .startOf('isoWeek')
              .add(
                (item.sectionOfWeek +
                  findNextBlockWithTask(item.sectionOfWeek)) *
                  30,
                'minute'
              )
              .format(),
            color: `#${getColor(item.projectId)}`
            // description: 'first description'
            // display: 'background'
          });
        });

      setEvents(newEvents);

      // setEvents([
      //   { title: 'event 11', date: '2022-02-13' },
      //   {
      //     title: 'event 2',
      //     date: '2022-02-09',
      //     color: 'blue'
      //   },
      //   {
      //     title: 'event 3\n  ddddd',
      //     start: '2022-02-13T10:00:00',
      //     end: '2022-02-13T12:00:00',
      //     color: 'red',
      //     description: 'first description'
      //     // display: 'background'
      //   },
      //   {
      //     title: 'event 4',
      //     start: '2022-02-13T10:00:00',
      //     end: '2022-02-13T12:00:00',
      //     color: 'black'
      //     // display: 'background'
      //   }
      // ]);
    }
  }, [dailyPlannedHourblocks]);

  return (
    <>
      {/* {showPopUp && (
        <div
          style={{
            position: 'absolute',
            top: popupY,
            left: popupX,
            zIndex: 1000,
            background: 'red',
            border: '10px solid black'
          }}
        >
          <HourblockCalendarBox dailyPlannedHourblock={{}} />
        </div>
      )} */}

      <Card
        size="small"
        style={{
          width: '100%',
          padding: 10
        }}
        bodyStyle={{
          padding: 0
        }}
      >
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="timeGridWeek"
          themeSystem="standard"
          headerToolbar={{
            start: 'title', // will normally be on the left. if RTL, will be on the right
            center: '',
            end: 'today prev,next' // will normally be on the right. if RTL, will be on the left
          }}
          firstDay={1}
          editable
          // droppable
          eventStartEditable
          eventResizableFromStart
          eventDurationEditable
          eventResize={info => {
            console.log('🔥🔥resizing...🔥🔥');
            console.log(
              info.event.title + ' end is now ' + info.event.end.toISOString()
            );

            // if (!confirm('is this okay?')) {
            //   info.revert();
            // }
          }}
          eventDrop={info => {
            console.log('dropping...🔥🔥');
            console.log(
              info.event.title +
                ' was dropped on ' +
                info.event.start.toISOString()
            );

            // if (!confirm('Are you sure about this change?')) {
            //   info.revert();
            // }
          }}
          eventClick={info => {
            console.log('🔥🔥info🔥🔥');
            console.log(info.event);
            setShowPopUp(true);
            setPopupX(info.jsEvent.pageX - info.jsEvent.offsetX);
            setPopupY(info.jsEvent.pageY - info.jsEvent.offsetY);

            console.log('🔥🔥info.jsEvent🔥🔥');
            console.log(info.jsEvent);
            console.log('clicking...🔥🔥');
            console.log('Event: ' + info.event.title);
            console.log(
              'Coordinates: ' + info.jsEvent.pageX + ',' + info.jsEvent.pageY
            );
            console.log('View: ' + info.view.type);

            // var eventObj = info.event;
            // alert(
            //   'ID: ' +
            //     info.event.id +
            //     'Title: ' +
            //     info.event.title +
            //     'Start: ' +
            //     info.event.start +
            //     'Description: ' +
            //     info.event.extendedProps.description
            // );

            // change the border color just for fun
            info.el.style.borderColor = 'red';
          }}
          drop={v => {
            console.log('🔥🔥v🔥🔥');
            console.log(v);
          }}
          dateClick={v => {
            console.log('🔥🔥v🔥🔥');
            console.log(v);
          }}
          nowIndicator
          weekNumbers
          slotDuration={'00:15:00'}
          slotLabelFormat={{
            hour: '2-digit',
            minute: '2-digit',
            omitZeroMinute: false,
            meridiem: 'short'
          }}
          eventContent={arg => {
            var event = arg.event;

            var customHtml = '';

            customHtml +=
              "<span class='r10 font-xxs font-bold' style='overflow: hidden;'>" +
              event.title +
              '</span>';

            if (
              event.extendedProps.description !== undefined &&
              event.extendedProps.description.length
            ) {
              customHtml +=
                "<span class='r10 highlighted-badge font-xxs font-bold'>" +
                event.extendedProps.description +
                '</span>';
            }

            return { html: customHtml };
          }}
          events={events}
        />
      </Card>
    </>
  );
};
