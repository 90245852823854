import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { userSettingsValueSelector } from 'redux/modules/user';
import userSettingConstants from 'views/constants/userSettingConstants';
import moment from 'moment';

import TaskRowSimplified from './TaskRowSimplified';
import MATERIAL_COLORS from '../../../styles/colors';

export default ({ recentWeekOnly = false }) => {
  const weeklyTasks = useSelector(state => {
    return state.weeklyTasks;
  });

  return (
    <div>
      {/* <div className={classes.title}>
        Weekly Tasks
        <span className={classes.titleRight}>
          {weeklyTasks.valueSeq().count()} / {maxWeeklyTakss} Per Week
        </span>
      </div> */}

      {weeklyTasks
        .valueSeq()
        .sort((a, b) => {
          if (a.get('targetCompletionWeek') !== b.get('targetCompletionWeek')) {
            return a.get('targetCompletionWeek') > b.get('targetCompletionWeek')
              ? 1
              : -1;
          }

          return a.get('projectId') > b.get('projectId') ? 1 : -1;
        })
        .filter(record => {
          if (!recentWeekOnly) {
            return true;
          }

          return (
            moment(record.get('targetCompletionWeek')).diff(moment(), 'week') <
            2
          );
        })
        .map((record, index) => (
          <TaskRowSimplified
            key={index}
            record={record}
            index={index}
            type="weekly"
          />
        ))}
    </div>
  );
};
