import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import KingBedIcon from '@material-ui/icons/KingBed';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';

import { FireFilled } from '@ant-design/icons';

import { weeklyRecordMetricsSelector } from 'redux/modules/dailyRecords';
import { useSelector } from 'react-redux';
import { Divider, Space } from 'antd';

import RoutineRowItem from './RoutineRowItem';
import MATERIAL_COLORS from 'views/styles/colors';

const useStyles = makeStyles({
  icon: {
    height: 20,
    width: 30,
    color: MATERIAL_COLORS.LightBlue
  },
  rightText: {
    // display: 'inline-b/lock',
    // float: 'right'
    // height: 30,
    // lineHeight: '30px'
  }
});

export default ({ selectedDailyRecord }) => {
  const classes = useStyles();

  const weeklyRecordMetrics = useSelector(state =>
    weeklyRecordMetricsSelector(state)
  );

  const convertMinuteToTime = minutes => {
    const padZero = res => (res < 10 ? `0${res}` : res);
    return `${padZero(parseInt(minutes / 60, 10))}:${padZero(minutes % 60)}`;
  };

  return (
    <>
      <Space size="small">
        <KingBedIcon color="primary" className={classes.icon} />
        <RoutineRowItem
          value={selectedDailyRecord}
          filePaths={[['wakeHour'], ['wakeMinute']]}
        />
        (
        <RoutineRowItem
          value={selectedDailyRecord}
          filePaths={[['startWorkHour'], ['startWorkMinute']]}
        />
        )<span>~</span>
        <RoutineRowItem
          value={selectedDailyRecord}
          filePaths={[['sleepHour'], ['sleepMinute']]}
        />
      </Space>

      <br />
      <Space
        size="small"
        style={{
          marginLeft: 30
        }}
      >
        [{convertMinuteToTime(weeklyRecordMetrics.lastWeekAverageHealthData[0])}
        ({convertMinuteToTime(weeklyRecordMetrics.lastWeekAverageHealthData[3])}
        ) ~
        {convertMinuteToTime(weeklyRecordMetrics.lastWeekAverageHealthData[1])}]
      </Space>
      <br />
      {/* <Divider /> */}
      <Space size="small">
        <FitnessCenterIcon color="primary" className={classes.icon} />
        <RoutineRowItem value={selectedDailyRecord} filePaths={[['weight']]} />[
        {weeklyRecordMetrics.lastWeekAverageHealthData[2].toFixed(1)}]
      </Space>

      <Divider type="vertical" />

      <Space size="small">
        <RoutineRowItem
          value={selectedDailyRecord}
          isTime={false}
          filePaths={[['workoutRunDistance']]}
        />
        x
        <RoutineRowItem
          value={selectedDailyRecord}
          isTime={false}
          filePaths={[['workoutRunMinutes']]}
        />
        -
        <RoutineRowItem
          value={selectedDailyRecord}
          isTime={false}
          filePaths={[['workoutSitupCount']]}
        />
      </Space>

      <Divider type="vertical" />

      <Space size="small">
        <FireFilled color="primary" className={classes.icon} />
        <RoutineRowItem
          value={selectedDailyRecord}
          filePaths={[['calorieBreakfast']]}
        />
        -
        <RoutineRowItem
          value={selectedDailyRecord}
          filePaths={[['calorieLunch']]}
        />
        -
        <RoutineRowItem
          value={selectedDailyRecord}
          filePaths={[['calorieDinner']]}
        />
        Extra:
        <RoutineRowItem
          value={selectedDailyRecord}
          filePaths={[['calorieExtra']]}
        />
      </Space>
    </>
  );
};
