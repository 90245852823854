import React, { useState } from 'react';
// import { makeStyles } from '@material-ui/core/styles';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';

import DeleteIcon from '@material-ui/icons/Delete';
import SimpleSelect from 'views/components/atoms/SimpleSelect';
import { useDispatch } from 'react-redux';
import { HabitActions } from 'redux/modules/habits';

import { Space, Tag, Input, Divider } from 'antd';

// const useStyles = makeStyles(() => ({}));

export default ({ habit }) => {
  // const classes = useStyles();

  const [editMode, setEditMode] = useState(false);

  const habitTypes = ['daily', 'weekly', 'monthly'];

  const dispatch = useDispatch();

  const updateHabit = (field, event) => {
    const newRec = habit.set(field, event.target.value);
    dispatch(HabitActions.update(newRec)).then(_ => setEditMode(false));
  };

  const padZero = num => (num !== null && num < 10 ? `0${num}` : num);

  return (
    <TableRow>
      <TableCell
        colSpan={8}
        component="th"
        scope="row"
        style={{
          background:
            habit.get('targetCompletionCount') > '0' ? 'wheat' : 'white',
          width: 300,
          zIndex: 100
        }}
      >
        <Space split={<Divider type="vertical" />}>
          {!editMode ? (
            <span onClick={() => setEditMode(!editMode)}>
              <Space>
                {`${habit.get('habitId')} - ${habit.get('name')}`}

                <Tag>
                  {`${padZero(habit.get('targetCompletionHour'))}:${padZero(
                    habit.get('targetCompletionMinute')
                  )}(${habit.get('targetCompletionCount')})`}
                </Tag>

                {`${habit.get('goal')}`}
              </Space>
            </span>
          ) : (
            <>
              <Input
                style={{ width: 300 }}
                defaultValue={habit.get('name')}
                onKeyDown={event => {
                  if (event.which === 13) {
                    updateHabit('name', event);
                  }
                }}
              />

              <Input
                style={{ width: 50 }}
                defaultValue={habit.get('targetCompletionHour')}
                type="number"
                onKeyDown={event => {
                  if (event.which === 13) {
                    updateHabit('targetCompletionHour', event);
                  }
                }}
              />

              <Input
                style={{ width: 50 }}
                defaultValue={habit.get('targetCompletionMinute')}
                type="number"
                onKeyDown={event => {
                  if (event.which === 13) {
                    updateHabit('targetCompletionMinute', event);
                  }
                }}
              />

              <Input
                style={{ width: 50 }}
                defaultValue={habit.get('targetCompletionCount')}
                type="number"
                onKeyDown={event => {
                  if (event.which === 13) {
                    updateHabit('targetCompletionCount', event);
                  }
                }}
              />

              <Input
                style={{ width: 100 }}
                defaultValue={habit.get('goal')}
                onKeyDown={event => {
                  if (event.which === 13) {
                    updateHabit('goal', event);
                  }
                }}
              />
            </>
          )}

          <SimpleSelect
            onChangeCallBack={event => updateHabit('type', event)}
            currentValue={habit.get('type')}
            values={habitTypes}
          />

          <IconButton
            edge="end"
            color="secondary"
            aria-label="Delete"
            onClick={() => {
              if (window.confirm(`Delete habit ${habit.get('name')}`)) {
                dispatch(HabitActions.deleteRecord(habit.toJS()));
              }
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Space>
      </TableCell>
    </TableRow>
  );
};
