const pluralizeWord = word =>
  word[word.length - 1] === 'y' ? `${word.slice(0, -1)}ies` : `${word}s`;
const capitalizeWord = word => word.toUpperCase()[0] + word.slice(1);

module.exports = apiNameSnake => {
  function turnIntoCamel(snakeName) {
    let res = snakeName;
    let arr = null;

    if (snakeName.indexOf('_') > -1) {
      arr = snakeName.split('_');
      res = arr[0];

      for (let i = 1; i < arr.length; i += 1) {
        res += arr[i].substr(0, 1).toUpperCase() + arr[i].substr(1);
      }
    }

    return res;
  }

  const apiNameCamel = turnIntoCamel(apiNameSnake);
  const apiNameCamelPlural = pluralizeWord(apiNameCamel);
  const capitalizedNameCamel = capitalizeWord(apiNameCamel);
  const capitalizedNameSnakePlural = pluralizeWord(apiNameSnake).toUpperCase();
  const capitalizedNameSnake = apiNameSnake.toUpperCase();

  return {
    apiNameCamel,
    apiNameCamelPlural,
    capitalizedNameCamel,
    capitalizedNameSnakePlural,
    capitalizedNameSnake,
    modelName: apiNameCamel
  };
};
