import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import { isImmutable } from 'immutable';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Paper from '@material-ui/core/Paper';

import RoutineTableRow from './RoutineTableRow';

// const useStyles = makeStyles(() => ({}));

export default ({ dailyRecords }) => {
  // const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Day</TableCell>
            <TableCell align="left">Wake</TableCell>
            <TableCell align="left">Sleep</TableCell>
            <TableCell align="left">Weight</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {isImmutable(dailyRecords) &&
            dailyRecords
              .valueSeq()
              .sort((a, b) =>
                a.get('dailyRecordDate') < b.get('dailyRecordDate') ? 1 : -1
              )
              .map(rec => <RoutineTableRow key={rec.get('id')} value={rec} />)}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
