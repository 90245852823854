import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Checkbox from '@material-ui/core/Checkbox';
import { Card, Button, Input, Switch, Space, Select, Table } from 'antd';
import {
  WorkoutActions,
  sortedWorkoutsSelector
} from '../../redux/modules/workouts';
import { fromJS } from 'immutable';

const { Option } = Select;

export default () => {
  // constructor(props, context) {
  // super(props, context);
  // }

  const dispatch = useDispatch();

  const workouts = useSelector(state => sortedWorkoutsSelector(state));
  console.log('🔥🔥workouts🔥🔥');
  console.log(workouts.toJS());

  const [name, setName] = useState('');
  const [focus, setFocus] = useState('');
  const [isMachine, setIsMachine] = useState(false);

  const saveWorkout = () => {
    const newRec = {
      name,
      focus,
      isMachine
    };

    dispatch(WorkoutActions.create(newRec));
    setName('');
    setFocus('');
    setIsMachine(false);
  };

  // const handleChange = varName => event => {
  //   // this.setState({
  //   //   [varName]: event.target.value
  //   // });
  // };

  const workoutFocuses = [
    'CHEST',
    'SHOULDER',
    'ARM',
    'BACK',
    'LEG',
    'ABS',
    'WAIST'
  ];

  const columns = [
    {
      title: 'id',
      dataIndex: 'workoutId',
      key: 'workoutId'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Focus',
      dataIndex: 'focus',
      key: 'focus'
    },
    {
      title: 'Is Machine',
      dataIndex: 'isMachine',
      key: 'isMachine',
      render: (text, record) => (
        <Checkbox
          checked={record['isMachine']}
          onClick={event => {
            record['isMachine'] = event.target.checked;

            dispatch(WorkoutActions.update(fromJS(record)));
          }}
        />
      )
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <IconButton
          edge="end"
          aria-label="Delete"
          onClick={() => {
            // eslint-disable-next-line no-restricted-globals
            if (window.confirm(`Deleting record ${record['name']}`)) {
              dispatch(WorkoutActions.deleteRecord(record));
            }
          }}
        >
          <DeleteIcon />
        </IconButton>
      )
    }
  ];

  return (
    <>
      <Card size="small">
        <Select
          size="small"
          style={{ width: 120 }}
          value={focus}
          onChange={value => {
            setFocus(value);
          }}
        >
          {workoutFocuses.map(focus => (
            <Option value={focus} key={focus}>
              {focus}
            </Option>
          ))}
        </Select>

        <Input
          size="small"
          style={{
            width: 180,
            margin: '0 5px 5px 5px'
          }}
          placeholder="Enter name"
          // prefix={<UserOutlined className="site-form-item-icon" />}
          onChange={e => setName(e.target.value)}
        />

        <Switch
          checked={isMachine}
          onChange={() => {
            setIsMachine(!isMachine);
          }}
        />

        <Button
          size="small"
          style={{
            marginLeft: 5
          }}
          type="primary"
          onClick={() => saveWorkout()}
        >
          Save
        </Button>
      </Card>
      <br />

      <Card size="small">
        <Table
          size="small"
          columns={columns}
          dataSource={workouts
            .valueSeq()
            .sort((a, b) => {
              if (a.get('focus') !== b.get('focus')) {
                return a.get('focus') > b.get('focus') ? 1 : -1;
              }

              return a.get('name') > b.get('name') ? 1 : -1;
            })
            .toJS()}
        />
      </Card>
    </>
  );
};
