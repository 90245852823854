import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import LocationMap from './locationMap';
import LocationNew from './locationNew';
import LocationTable from './locationTable';

const styles = theme => ({
  paper: {
    padding: 10,
    maxWidth: '100vw',
    margin: 'auto',
    overflow: 'hidden'
  }
});

class Location extends Component {
  state = {};

  render() {
    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <LocationNew />
          </Grid>
          <Grid item xs={8}>
            <LocationMap />
          </Grid>
        </Grid>
        <LocationTable />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Location));
