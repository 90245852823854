import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import TextField from '@material-ui/core/TextField';

import { isImmutable } from 'immutable';

import { DailyRecordActions } from 'redux/modules/dailyRecords';

export default ({ value, filePaths, isTime = true }) => {
  const [editMode, setEditMode] = useState(false);

  const dispatch = useDispatch();

  const updateValue = (event, fieldPath) => {
    if (event.which === 13) {
      const newRec = value.setIn(fieldPath, event.target.value);

      dispatch(DailyRecordActions.update(newRec)).then(() =>
        setEditMode(false)
      );
    }
  };

  const padNumber = num => (num < 10 ? `0${num}` : num);

  return (
    <div
      style={{
        display: 'inline-block'
      }}
      onClick={() => setEditMode(true)}
      role="button"
      tabIndex="0"
    >
      {!editMode ? (
        <span
          style={{
            display: 'inline-block'
          }}
        >
          {filePaths.map(path => (
            <span
              key={path}
              style={{
                width: 20,
                height: 15,
                // height: 'inherit',
                display: 'inline-block',
                background:
                  isImmutable(value) && value.getIn(path) !== null
                    ? 'transparent'
                    : 'red'
              }}
            >
              {isImmutable(value) && value.getIn(path) !== null
                ? isTime
                  ? padNumber(value.getIn(path))
                  : value.getIn(path)
                : ''}
            </span>
          ))
          // .join(' : ')
          }
        </span>
      ) : (
        <React.Fragment>
          {filePaths.map((path, key) => {
            return (
              <TextField
                style={{
                  display: 'inline',
                  height: 'inherit',
                  width: 30,
                  padding: 0,
                  margin: 0,
                  marginRight: 10,
                  float: 'left'
                }}
                key={key}
                id={path.join('-')}
                margin="dense"
                defaultValue={isImmutable(value) ? value.getIn(path) : ''}
                onKeyDown={event => updateValue(event, path)}
              />
            );
          })}
        </React.Fragment>
      )}
    </div>
  );
};
