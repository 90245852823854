import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';

const styles = theme => ({});

const { google } = window;

class LocationTable extends Component {
  // constructor() {
  //   super();

  //   const sydney = new google.maps.LatLng(-33.867, 151.195);

  //   const infowindow = new google.maps.InfoWindow();
  // }

  componentDidMount() {
    // this.initMap();
  }

  createMarker = place => {
    const marker = new google.maps.Marker({
      map: this.map,
      position: place.geometry.location
    });

    google.maps.event.addListener(marker, 'click', function() {
      this.infowindow.setContent(place.name);
      this.infowindow.open(this.map, this);
    });
  };

  // initMap = () => {
  //   const map = new google.maps.Map(document.getElementById('map'), {
  //     center: this.sydney,
  //     zoom: 15
  //   });

  //   const request = {
  //     query: 'Museum of Contemporary Art Australia',
  //     fields: ['name', 'geometry']
  //   };

  //   const service = new google.maps.places.PlacesService(map);

  //   // let that = this;
  //   service.findPlaceFromQuery(request, (results, status) => {
  //     if (status === google.maps.places.PlacesServiceStatus.OK) {
  //       for (let i = 0; i < results.length; i += 1) {
  //         // that.createMarker(results[i]);
  //       }

  //       map.setCenter(results[0].geometry.location);
  //     }
  //   });
  // };

  render() {
    const { locations } = this.props;

    return (
      <TableContainer component={Paper}>
        <Table
          size="small"
          style={{
            minWidth: 650
          }}
          aria-label="simple table"
        >
          <TableHead
            style={{
              background: 'whitesmoke'
            }}
          >
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell align="left">Lat/Lng</TableCell>
              <TableCell align="left">Notes</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {locations.valueSeq().map(item => (
              <TableRow>
                <TableCell>{item.get('description')}</TableCell>
                <TableCell>
                  {item.get('latitude')}/{item.get('longitude')}
                </TableCell>
                <TableCell>{item.get('notes')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    locations: state.locations
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(LocationTable));
