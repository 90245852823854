import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { Divider } from 'antd';
import { dailyPlannedHourblocksMetricsSelector } from 'redux/modules/dailyPlannedHourblocks';
import { currentWeekHourblockSelector } from 'redux/modules/dailyRecords';
import {
  categoriesByNameSelector,
  categoriesByProjectSelector
} from 'redux/modules/categories';
import WeeklyInsightByPlannedProjectTarget from 'views/components/organisms/List/WeeklyInsightByPlannedProjectTarget';
import ProCard from '@ant-design/pro-card';

const useStyles = makeStyles(() => ({
  block: {
    border: '1px solid black',
    width: 160,
    height: 20,
    display: 'inline-block',
    backgroundSize: '30px 1px',
    marginRight: 10,
    color: 'black',
    position: 'relative',
    backgroundColor: 'whitesmoke',
    boxSizing: 'content-box'
  },
  // blockProgressBar: {
  //   position: 'absolute',
  //   left: 0,
  //   top: 0,
  //   height: 'inherit',
  //   display: 'inline-block',
  //   zIndex: 0
  // },
  blockText: {
    display: 'inline-block',
    zIndex: 1,
    position: 'relative',
    width: 'inherit'
  }
}));

export default () => {
  const dailyPlannedHourblocksMetrics = useSelector(state =>
    dailyPlannedHourblocksMetricsSelector(state)
  );

  const categoriesByName = useSelector(state =>
    categoriesByNameSelector(state)
  );

  const categoriesByProject = useSelector(state =>
    categoriesByProjectSelector(state)
  );

  const currentWeekHourblock = useSelector(state =>
    currentWeekHourblockSelector(state)
  );

  const classes = useStyles({});

  const BASIC_BLOCK_WIDTH = 160;

  return (
    <>
      <ProCard
        size="small"
        title="Completed vs Planned"
        type="inner"
        bordered
        extra={[
          `${
            dailyPlannedHourblocksMetrics.weeklyPlannedByCategory !==
              undefined &&
            Object.values(dailyPlannedHourblocksMetrics.weeklyPlannedByCategory)
              .length > 0
              ? Object.values(
                  dailyPlannedHourblocksMetrics.weeklyPlannedByCategory
                ).reduce((a, b) => a + b)
              : ''
          }`
        ]}
      >
        {/* Completed vs PLanned - By Project */}
        {dailyPlannedHourblocksMetrics.weeklyPlannedByProject !== undefined &&
          Object.values(dailyPlannedHourblocksMetrics.weeklyPlannedByProject)
            .length > 0 &&
          Object.keys(dailyPlannedHourblocksMetrics.weeklyPlannedByProject)
            .sort((a, b) => {
              return (
                dailyPlannedHourblocksMetrics.weeklyPlannedByProject[a].category
                  .displayOrder -
                dailyPlannedHourblocksMetrics.weeklyPlannedByProject[b].category
                  .displayOrder
              );
            })
            .map(project => (
              <div
                key={project}
                className={classes.block}
                style={{ marginBottom: 5 }}
              >
                <span
                  style={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    height: 'inherit',
                    display: 'inline-block',
                    zIndex: 0,
                    width:
                      currentWeekHourblock.byProject[project].value <
                      dailyPlannedHourblocksMetrics.weeklyPlannedByProject[
                        project
                      ].value
                        ? (BASIC_BLOCK_WIDTH *
                            currentWeekHourblock.byProject[project].value) /
                          dailyPlannedHourblocksMetrics.weeklyPlannedByProject[
                            project
                          ].value
                        : BASIC_BLOCK_WIDTH,
                    backgroundColor:
                      dailyPlannedHourblocksMetrics.weeklyPlannedByProject[
                        project
                      ] !== undefined
                        ? `#${dailyPlannedHourblocksMetrics.weeklyPlannedByProject[project].category.color}`
                        : 'black'
                  }}
                />

                <span className={classes.blockText}>
                  <span>{`${project !== undefined ? project : '-'}`}</span>-
                  <span
                    style={{
                      float: 'right'
                    }}
                  >
                    {`${currentWeekHourblock.byProject[project].value}/${
                      dailyPlannedHourblocksMetrics.weeklyPlannedByProject[
                        project
                      ].value
                    } (${(
                      (dailyPlannedHourblocksMetrics.weeklyPlannedByProject[
                        project
                      ].value *
                        100) /
                      168
                    ).toFixed(0)}%)`}
                  </span>
                </span>
              </div>
            ))}

        <Divider plain />
        {/* Completed vs PLanned - By Category */}
        {dailyPlannedHourblocksMetrics.weeklyPlannedByCategory !== undefined &&
          Object.values(dailyPlannedHourblocksMetrics.weeklyPlannedByCategory)
            .length > 0 &&
          Object.keys(dailyPlannedHourblocksMetrics.weeklyPlannedByCategory)
            .sort((a, b) => {
              return (
                categoriesByName[a].displayOrder -
                categoriesByName[b].displayOrder
              );
            })
            .map(cat => (
              <div
                key={cat}
                className={classes.block}
                style={{ marginBottom: 5 }}
              >
                <span
                  style={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    height: 'inherit',
                    display: 'inline-block',
                    zIndex: 0,
                    width:
                      currentWeekHourblock.byCategory[cat] <
                      dailyPlannedHourblocksMetrics.weeklyPlannedByCategory[cat]
                        ? (BASIC_BLOCK_WIDTH *
                            currentWeekHourblock.byCategory[cat]) /
                          dailyPlannedHourblocksMetrics.weeklyPlannedByCategory[
                            cat
                          ]
                        : BASIC_BLOCK_WIDTH,
                    backgroundColor:
                      categoriesByName[cat] !== undefined
                        ? `#${categoriesByName[cat].color}`
                        : 'black'
                  }}
                />

                <span className={classes.blockText}>
                  <span>
                    {`${
                      categoriesByName[cat] !== undefined
                        ? categoriesByName[cat].name
                        : '-'
                    }`}
                  </span>
                  -
                  <span
                    style={{
                      float: 'right'
                    }}
                  >
                    {`${currentWeekHourblock.byCategory[cat]}/${
                      dailyPlannedHourblocksMetrics.weeklyPlannedByCategory[cat]
                    } (${(
                      (dailyPlannedHourblocksMetrics.weeklyPlannedByCategory[
                        cat
                      ] *
                        100) /
                      168
                    ).toFixed(0)}%)`}
                  </span>
                </span>
              </div>
            ))}
      </ProCard>

      {/* Weekly Actuals */}
      <WeeklyInsightByPlannedProjectTarget />
    </>
  );
};
