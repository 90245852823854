import Grid from '@material-ui/core/Grid';
import AllOutIcon from '@material-ui/icons/AllOut';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { UiActions } from 'redux/modules/ui';
import { Button } from 'antd';

export default () => {
  const [fullScreeen, setFullScreeen] = useState(false);
  const [isPaused, setIsPaused] = useState(true);
  const [accumulatedPomo, setAccumulatedPomo] = useState(0);
  const [pomoSec, setPomoSec] = useState(0);

  useEffect(() => {
    const interval = setInterval(_ => {
      if (!isPaused) {
        setPomoSec(pomoSec + 1);

        // setAccumulatedPomo(
        //   pomoSec === 1 ? accumulatedPomo + 1 : accumulatedPomo
        // );
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [isPaused, pomoSec, accumulatedPomo]);

  const renderPomoToolBar = () => {
    const pad = num => `${num > 9 ? '' : '0'}${num}`;

    const min = pad(parseInt(pomoSec / 60, 10));
    const sec = pad(pomoSec % 60);

    return (
      <React.Fragment>
        <span
          style={{
            color: 'orange',
            fontWeight: 'bold',
            fontSize: fullScreeen ? 120 : 24
          }}
        >
          {`${min}:${sec}`}
        </span>

        {fullScreeen && <br />}

        {/* <span
          style={{
            color: 'black',
            fontSize: 16
          }}
        >
          {accumulatedPomo}
        </span> */}

        {!isPaused ? (
          <Button
            variant="contained"
            onClick={() => {
              setIsPaused(true);
            }}
            icon={<PauseIcon />}
          />
        ) : (
          <Button
            variant="contained"
            onClick={() => {
              setIsPaused(false);
            }}
            icon={<PlayArrowIcon />}
          />
        )}

        <Button
          variant="contained"
          onClick={() => {
            // clearInterval(updateTimeRunner);

            setIsPaused(true);
            setPomoSec(0);
          }}
          icon={<StopIcon />}
        />

        <Button
          color={fullScreeen ? 'secondary' : 'inherit'}
          onClick={() => {
            setFullScreeen(!fullScreeen);
          }}
          icon={<AllOutIcon />}
        />
      </React.Fragment>
    );
  };

  return (
    <>
      {!fullScreeen ? (
        <>
          {/* <Grid item>
                <Typography className={classes.link} component="a" href="#">
                  {`Habit: ${currentMonthHabit !== null &&
                    currentMonthHabit.get('name')}`}
                </Typography>
              </Grid> */}
          {renderPomoToolBar()}
        </>
      ) : (
        <>
          <span
            style={{
              width: 200,
              display: 'block',
              top: '30vh',
              left: 'calc(50vw - 100px)',
              float: 'right',
              position: 'absolute',
              zIndex: 100
            }}
          >
            {renderPomoToolBar()}
          </span>
          <div
            style={{
              background: 'black',
              color: 'white',
              position: 'fixed',
              left: 0,
              top: 0,
              width: '100vw',
              height: '100vh',
              zIndex: 99
            }}
          />
        </>
      )}
    </>
  );
};
