import { isImmutable, fromJS } from 'immutable';
import { createSelector } from 'reselect';
import { actionCreate } from '../_base/actionCreate';
import { reducerCreate } from '../_base/reducerCreate';

export default reducerCreate('diary');
export const { DiaryActions } = actionCreate('diary');

const getSelectedDiaryDate = state =>
  state.ui.getIn(['diary', 'selectedDiaryDate']);

const getDiaries = state => state.diaries;

export const selectedDiarySelector = createSelector(
  [getDiaries, getSelectedDiaryDate],
  (diaries, selectedDiaryDate) => {
    let res = fromJS({});

    if (isImmutable(diaries)) {
      diaries.valueSeq().forEach(rec => {
        if (rec.get('date') === selectedDiaryDate) {
          res = rec;
        }
      });
    }

    return res;
  }
);
