// import { createSelector } from 'reselect';

import { actionCreate } from '../_base/actionCreate';
import { reducerCreate } from '../_base/reducerCreate';

import { isImmutable, fromJS } from 'immutable';

import { createSelector } from 'reselect';
import moment from 'moment';

export default reducerCreate('workout_record');
export const { WorkoutRecordActions } = actionCreate('workout_record');

const getWorkoutRecords = state => state.workoutRecords;

export const workoutRecordsByDateSelector = createSelector(
  [getWorkoutRecords],
  tasks => {
    const results = {};

    if (isImmutable(tasks)) {
      Object.values(tasks.toJS()).forEach(task => {
        if (results[task.workoutDate] === undefined) {
          results[task.workoutDate] = [];
        }

        results[task.workoutDate].push(task);
      });

      // Show upcoming 28 days
      for (let i = 0; i < 28; i += 1) {
        const day = moment()
          .add(i, 'days')
          .format('YYYY-MM-DD');
        if (results[day] === undefined) {
          results[day] = [];
        }
      }
    }
    return fromJS(results);
  }
);
