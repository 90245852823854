import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Input, Row, Col, Tag, Checkbox, Dropdown } from 'antd';
import YearlySubTaskRightClickDialogBox from 'views/3-Task/calendar/YearlySubTaskRightClickBox';
import { YearlySubTaskActions } from 'redux/modules/yearlySubTasks';

export default ({ yearlySubTask }) => {
  const dispatch = useDispatch();

  const [isEditing, setIsEditing] = useState(false);
  const [savingTask, setSavingTask] = useState(false);

  const updateTask = (event, field) => {
    if (event.which === 13) {
      const newRec = yearlySubTask.setIn([field], event.target.value);
      setSavingTask(true);

      dispatch(YearlySubTaskActions.create(newRec)).then(() => {
        setIsEditing(false);
        setSavingTask(false);
      });
    }
  };

  return (
    <Row
      style={{
        height: 25,
        background: savingTask ? 'orange' : 'white',
        textDecoration: yearlySubTask.get('isCompleted')
          ? 'line-through'
          : 'none'
      }}
    >
      <Col flex="none">
        <Checkbox
          style={{
            marginLeft: 10
          }}
          checked={
            yearlySubTask.has('isCompleted') &&
            yearlySubTask.get('isCompleted') !== null &&
            yearlySubTask.get('isCompleted')
          }
          onChange={event => {
            const newRec = yearlySubTask.set(
              'isCompleted',
              event.target.checked
            );

            dispatch(YearlySubTaskActions.create(newRec));
          }}
        />
      </Col>

      <Col flex="1" style={{ overflow: 'hidden', height: '100%' }}>
        <Dropdown
          overlay={
            <YearlySubTaskRightClickDialogBox yearlySubTask={yearlySubTask} />
          }
          trigger={['contextMenu']}
        >
          {!isEditing ? (
            <span
              role="button"
              tabIndex={0}
              onClick={() => {
                setIsEditing(true);
              }}
              style={{
                overflow: 'hidden',
                height: '100%',
                textDecoration: yearlySubTask.get('isCompleted')
                  ? 'line-through'
                  : ''
              }}
            >
              {yearlySubTask.get('name')}
            </span>
          ) : (
            <Input
              style={{
                padding: 0,
                width: '100%',
                borderBottom: '1px black solid'
              }}
              bordered={false}
              id="item"
              defaultValue={yearlySubTask.get('name')}
              onKeyDown={event => {
                updateTask(event, 'name');
              }}
            />
          )}
        </Dropdown>
      </Col>

      <Col flex="none">
        {moment(yearlySubTask.get('targetStartDate')).format('MM-DD')}~
        {moment(yearlySubTask.get('targetCompletionDate')).format('MM-DD')}
        <Tag size="small">
          {yearlySubTask.get('pomoCount')}/
          {!isEditing ? (
            <span
              role="button"
              tabIndex={0}
              onClick={() => {
                setIsEditing(true);
              }}
              style={{
                overflow: 'hidden',
                height: '100%',
                textDecoration: yearlySubTask.get('isCompleted')
                  ? 'line-through'
                  : ''
              }}
            >
              {yearlySubTask.get('targetCompletionPomo')}
            </span>
          ) : (
            <Input
              style={{
                width: 30,
                padding: 0,
                borderBottom: '1px black solid'
              }}
              bordered={false}
              id="item"
              defaultValue={yearlySubTask.get('targetCompletionPomo')}
              onKeyDown={event => {
                updateTask(event, 'targetCompletionPomo');
              }}
            />
          )}
        </Tag>
      </Col>
    </Row>
  );
};
