// import moment from 'moment';
// import { createSelector } from 'reselect';
import { isImmutable, fromJS } from 'immutable';
import moment from 'moment';

import { createSelector } from 'reselect';
import { actionCreate } from '../_base/actionCreate';
import { reducerCreate } from '../_base/reducerCreate';
import { categoriesByProjectSelector } from './categories';

export default reducerCreate('daily_task');
export const { DailyTaskActions } = actionCreate('daily_task');

export class SubTask {
  static default = new SubTask();

  constructor(
    name = '',
    isCompleted = false,
    targetCompletionDate = '',
    plannedPomosCount = 0
  ) {
    this.name = name;
    this.isCompleted = isCompleted;
    this.targetCompletionDate = targetCompletionDate;
    this.plannedPomosCount = plannedPomosCount;
    this.actualPomosCount = 0;
  }

  get asJSON() {
    return {
      name: this.name,
      isCompleted: this.isCompleted,
      targetCompletionDate: this.targetCompletionDate,
      plannedPomosCount: this.plannedPomosCount,
      actualPomosCount: this.actualPomosCount
    };
  }
}

export class Task {
  static default = new Task();

  constructor(name, isKeyTask, targetCompletionDate, category, project) {
    this.name = name;
    this.isKeyTask = isKeyTask;
    this.targetCompletionDate = targetCompletionDate;

    this.isCompleted = false;
    this.ref = {
      category,
      project
    };

    this.subtasks = [];
  }

  get asJSON() {
    return {
      name: this.name,
      isKeyTask: this.isKeyTask,
      targetCompletionDate: this.targetCompletionDate,
      isCompleted: this.isCompleted,
      ref: this.ref,
      subtasks: this.subtasks
    };
  }
}

const getDailyTasks = state => state.dailyTasks;

// Both list of tasks and also metrics for total count
export const tasksByDateSelector = createSelector([getDailyTasks], tasks => {
  const results = {};

  if (isImmutable(tasks)) {
    Object.values(tasks.toJS()).forEach(task => {
      if (results[task.targetCompletionDate] === undefined) {
        results[task.targetCompletionDate] = [];
      }

      results[task.targetCompletionDate].push(task);
    });

    // Show upcoming 28 days
    for (let i = 0; i < 60; i += 1) {
      const day = moment()
        .add(i, 'days')
        .format('YYYY-MM-DD');
      if (results[day] === undefined) {
        results[day] = [];
      }
    }
  }
  return fromJS(results);
});

// Both list of tasks and also metrics for total count
// For display on the left menu
export const todayTasksSelector = createSelector(
  [getDailyTasks, categoriesByProjectSelector],
  (tasks, categoriesByProject) => {
    const res = {
      tasks: [],
      completedTasks: [],
      inCompletedTasks: [],
      metrics: {}
    };

    const today = moment().format('YYYY-MM-DD');
    if (isImmutable(tasks)) {
      tasks
        .valueSeq()
        .toJS()
        .forEach(t => {
          const projectId = t.projectId;

          if (
            projectId !== null &&
            categoriesByProject[projectId] !== undefined
          ) {
            const categoryName = categoriesByProject[projectId].category.name;

            if (res.metrics[categoryName] === undefined) {
              res.metrics[categoryName] = 0;
            }

            if (t.targetCompletionDate === today) {
              if (t.isCompleted) {
                res.completedTasks.push(t);
              } else {
                res.inCompletedTasks.push(t);
              }
            }

            // // Get tasks that is targeted today
            // if (t.targetCompletionDate === today) {
            //   res.tasks.push(t);

            //   // Just get the target pomo
            //   t.subtasks.forEach(sub => {
            //     res.metrics[categoryName] += sub.plannedPomosCount;
            //   });
            // } else {
            //   let isMainTaskAdded = false;
            //   t.subtasks.forEach(sub => {
            //     if (sub.targetCompletionDate === today) {
            //       if (!isMainTaskAdded) {
            //         res.tasks.push(t);
            //         isMainTaskAdded = true;
            //       }
            //       res.metrics[categoryName] += sub.plannedPomosCount;
            //     }
            //   });
            // }
          }
        });
    }

    return fromJS(res);
  }
);

// [Unfinished (incl today)],[today]
// Today tasks will just push name and target
export const unfinishedTasksSelector = createSelector(
  [getDailyTasks],
  tasks => {
    const res = [];
    const today = moment().format('YYYY-MM-DD');
    if (isImmutable(tasks)) {
      tasks
        .valueSeq()
        .toJS()
        .forEach(t => {
          if (
            t.targetCompletionDate !== '' &&
            t.targetCompletionDate <= today &&
            !t.isCompleted
          ) {
            res.push(t);
          }

          // if (t.targetCompletionDate === today && !t.isCompleted) {
          //   res[1].push({
          //     category: t.ref.category,
          //     name: t.name,
          //     plannedPomosCount: 0
          //   });
          // } else {
          //   let isMainTaskAdded = false;
          //   t.subtasks.forEach(sub => {
          //     if (sub.targetCompletionDate === today && !t.isCompleted) {
          //       if (!isMainTaskAdded) {
          //         res[1].push({
          //           category: t.ref.category,
          //           name: sub.name,
          //           plannedPomosCount: sub.plannedPomosCount
          //         });
          //         isMainTaskAdded = true;
          //       }
          //     }
          //   });
          // }
        });
    }

    return res;
  }
);

export const uncompletedTaskByCategorySelector = createSelector(
  [unfinishedTasksSelector, categoriesByProjectSelector],
  (unfinishedTasks, categoriesByProject) => {
    const uncompletedTaskByCategory = {};

    // 0 is all pending tasks, 1 is today pending tasks
    unfinishedTasks.forEach(t => {
      const projectId = t.projectId;

      if (projectId !== null && categoriesByProject[projectId] !== undefined) {
        const categoryName = categoriesByProject[projectId].category.name;
        if (uncompletedTaskByCategory[categoryName] === undefined) {
          uncompletedTaskByCategory[categoryName] = [];
        }

        uncompletedTaskByCategory[categoryName].push(t);
      }
    });

    // First priority is today tasks. then will be pending tasks
    return uncompletedTaskByCategory;
  }
);

const getSelectedProject = state => state.ui.getIn(['task', 'selectedProject']);

const getSelectedDate = state => state.ui.getIn(['hourblock', 'selectedDate']);

export const selectedDayDailyTasksSelector = createSelector(
  [getDailyTasks, getSelectedDate],
  (tasks, selectedDate) => {
    if (isImmutable(tasks)) {
      return tasks
        .valueSeq()
        .filter(a => a.get('targetCompletionDate') === selectedDate);
    }
    return fromJS({});
  }
);

export const tasksFilteredListSelector = createSelector(
  [
    getDailyTasks,
    getSelectedProject,
    getSelectedDate,
    categoriesByProjectSelector
  ],
  (tasks, selectedProject, selectedDate, categoriesByProject) => {
    const res = {
      All: [],
      Past: [],
      Today: [],
      TodayAll: [],
      Tomorrow: [],
      RestOfWeek: [],
      Week: [],
      Month: [],
      Future: [],
      Unassigned: []
    }; // all, past, today, this week, this month, unassigned
    if (isImmutable(tasks)) {
      res.All = tasks.valueSeq();

      // Sort by date first
      res.All = res.All.sort((a, b) => {
        if (isImmutable(tasks)) {
          // Order by priority first, date, then category
          if (a.get('isKeyTask') !== b.get('isKeyTask'))
            return a.get('isKeyTask') ? -1 : 1;

          // if (a.get('targetCompletionDate') === b.get('targetCompletionDate')) {
          //   return a.get('isKeyTask') ? 1 : -1;
          // }

          if (a.get('targetCompletionDate') !== b.get('targetCompletionDate')) {
            return a.get('targetCompletionDate') > b.get('targetCompletionDate')
              ? -1
              : 1;
          }

          if (categoriesByProject[a.get('projectId')] === undefined) return -1;
          if (categoriesByProject[b.get('projectId')] === undefined) return 1;

          return categoriesByProject[a.get('projectId')].category.displayOrder >
            categoriesByProject[b.get('projectId')].category.displayOrder
            ? 1
            : -1;
        }
      });

      // if (selectedProject !== '') {
      //   res.All = res.All.filter(
      //     a => a.getIn(['projectId']) === selectedProject
      //   );
      // }

      res.Past = res.All.filter(
        a =>
          a.get('targetCompletionDate') !== '' &&
          a.get('targetCompletionDate') < moment().format('YYYY-MM-DD')
      );

      res.CurrentDay = res.All.filter(
        a =>
          a.get('targetCompletionDate') !== '' &&
          a.get('targetCompletionDate') === selectedDate
      );

      res.Today = res.All.filter(
        a =>
          a.get('targetCompletionDate') !== '' &&
          a.get('targetCompletionDate') === moment().format('YYYY-MM-DD')
      );

      // Task for both today and past, and 7 days in advance
      res.TodayAll = res.All.filter(
        a =>
          a.get('targetCompletionDate') !== '' &&
          a.get('targetCompletionDate') <=
            moment()
              .add(1, 'day') // Just show 2 days ahead
              .format('YYYY-MM-DD')
      );

      // Task for both today and past, and 7 days in advance
      res.UpcomingWeek = res.All.filter(
        a =>
          a.get('targetCompletionDate') !== '' &&
          a.get('targetCompletionDate') <=
            moment()
              .add(2, 'weeks') // Just show 2 days ahead
              .format('YYYY-MM-DD') &&
          a.get('targetCompletionDate') >
            moment() // Just show 2 days ahead
              .format('YYYY-MM-DD')
      );

      res.RestOfWeek = res.All.filter(
        a =>
          a.get('targetCompletionDate') !== '' &&
          a.get('targetCompletionDate') > moment().format('YYYY-MM-DD') &&
          moment(a.get('targetCompletionDate')).isoWeek() === moment().isoWeek()
      );

      res.Week = res.All.filter(
        a =>
          a.get('targetCompletionDate') !== '' &&
          moment(a.get('targetCompletionDate')).isoWeek() === moment().isoWeek()
      );

      res.Month = res.All.filter(
        a =>
          a.get('targetCompletionDate') !== '' &&
          moment(a.get('targetCompletionDate')).month() === moment().month()
      );

      res.Future = res.All.filter(
        a =>
          a.get('targetCompletionDate') !== '' &&
          moment(a.get('targetCompletionDate')).month() > moment().month()
      );

      res.Unassigned = res.All.filter(
        a =>
          a.get('targetCompletionDate') === '' ||
          a.get('targetCompletionDate') === null
      );
    }
    // }

    return fromJS(res);
  }
);

export const todayDailyKeyTasksCountSelector = createSelector(
  [tasksFilteredListSelector],
  tasksFilteredList => {
    return tasksFilteredList !== undefined && tasksFilteredList.has('Today')
      ? tasksFilteredList
          .get('Today')
          .filter(r => r.get('isKeyTask'))
          .count()
      : 0;
  }
);
