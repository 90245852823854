// http://materialuicolors.co/?utm_source=launchers
// https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=81C784
const MATERIAL_COLORS = {
  Primary: '#81c784', // calming gree
  PrimaryLight: '#b2fab4',

  LightRed: '#e74c3c',
  DarkRed: '#c0392b',
  LightBlue: '#03A9F4',

  DarkBlue: '#2196F3',

  DarkGray: '#7f8c8d',
  DarkerGray: '#262F3E',
  Gray: '#95a5a6',
  LightGray: '#bdc3c7',
  LighterGray: '#ecf0f1'
};

export default MATERIAL_COLORS;
