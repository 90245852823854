import { createSelector } from 'reselect';
import _ from 'lodash';
import { isImmutable } from 'immutable';
import { actionCreate } from '../_base/actionCreate';
import { reducerCreate } from '../_base/reducerCreate';

export default reducerCreate('category');
export const { CategoryActions } = actionCreate('category');

const getCategories = state => state.categories;

export const orderedCategoriesSelector = createSelector(
  [getCategories],
  categories =>
    categories.sort((a, b) => a.get('displayOrder') - b.get('displayOrder'))
);

export const categoriesByNameSelector = createSelector(
  [getCategories],
  categories => {
    const res = {};
    categories
      .valueSeq()
      .toJS()
      .sort((a, b) => a.order - b.order)
      .forEach(cat => {
        res[cat.name] = cat;
      });
    return res;
  }
);

/**
 * Transform the category object into a project first item
 * project will embed category instead
 * Transform here in FE code instead of from API so that changes to categories will be propagated automatically
 * {projectId: {_project_details_(including category)}}
 */
export const categoriesByProjectSelector = createSelector(
  [getCategories],
  categories => {
    const res = {};

    if (isImmutable(categories)) {
      const categoriesList = categories
        .valueSeq()
        .toJS()
        .sort((a, b) => a.displayOrder - b.displayOrder);

      categoriesList.forEach(cat => {
        const categoryWithOutProject = _.cloneDeep(cat);
        delete categoryWithOutProject.projects;

        cat.projects
          .sort((a, b) => a.displayOrder - b.displayOrder)
          .forEach(proj => {
            // proj['category'] = cat;
            const newProject = Object.assign(proj);
            newProject.category = categoryWithOutProject;
            res[proj.id] = newProject;
          });
      });
    }

    return res;
  }
);

export const weeklyPlannedHourSelector = createSelector(
  [getCategories],
  categories => {
    // total completed hours, total planned hours
    let res = 0;

    Object.values(categories.toJS()).forEach(cat => {
      Object.values(cat.projects).forEach(proj => {
        res += proj.estimatedHourWeek;
      });
    });

    return res;
  }
);
