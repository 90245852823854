import React, { useState } from 'react';

import { Layout } from 'antd';

import { TabBar } from 'antd-mobile';

import { FieldTimeOutlined, ArrowUpOutlined } from '@ant-design/icons';

import Hourblock from './1-Hourblock/_indexMobile';
import Workout from './5-Workout/_index';

const { Content } = Layout;
// import {
//   Route,
// } from 'react-router-dom';

export default () => {
  const [tab, setTab] = useState('hourblock');
  // state = {
  //   selectedTab: 'redTab',
  //   hidden: false,
  //   fullScreen: false
  // };

  return (
    <Content
      className="site-layout-background"
      style={{
        margin: 0,
        overflow: 'auto',
        height: '100vh'
      }}
    >
      <TabBar
        unselectedTintColor="#949494"
        tintColor="#33A3F4"
        barTintColor="white"
        // hidden={this.state.hidden}
      >
        <TabBar.Item
          title="Hourblock"
          key="Hourblock"
          icon={<FieldTimeOutlined />}
          selectedIcon={<FieldTimeOutlined />}
          selected={tab === 'hourblock'}
          // badge={1}
          onPress={() => {
            setTab('hourblock');
          }}
          data-seed="logId"
        >
          {/* {this.renderContent('Life')} */}
          <Hourblock />
        </TabBar.Item>
        <TabBar.Item
          icon={<ArrowUpOutlined />}
          selectedIcon={<ArrowUpOutlined />}
          title="Workout"
          key="Workout"
          // badge={'new'}
          selected={tab === 'workout'}
          onPress={() => {
            setTab('workout');
          }}
          data-seed="logId1"
        >
          {/* {this.renderContent('Koubei')} */}
          <Workout />
        </TabBar.Item>
        {/* <TabBar.Item
          icon={
            <div
              style={{
                width: '22px',
                height: '22px',
                background:
                  'url(https://zos.alipayobjects.com/rmsportal/psUFoAMjkCcjqtUCNPxB.svg) center center /  21px 21px no-repeat'
              }}
            />
          }
          selectedIcon={
            <div
              style={{
                width: '22px',
                height: '22px',
                background:
                  'url(https://zos.alipayobjects.com/rmsportal/IIRLrXXrFAhXVdhMWgUI.svg) center center /  21px 21px no-repeat'
              }}
            />
          }
          title="Friend"
          key="Friend"
          dot
          selected={this.state.selectedTab === 'greenTab'}
          onPress={() => {
            this.setState({
              selectedTab: 'greenTab'
            });
          }}
        >
          {this.renderContent('Friend')}
        </TabBar.Item>
        <TabBar.Item
          icon={{
            uri:
              'https://zos.alipayobjects.com/rmsportal/asJMfBrNqpMMlVpeInPQ.svg'
          }}
          selectedIcon={{
            uri:
              'https://zos.alipayobjects.com/rmsportal/gjpzzcrPMkhfEqgbYvmN.svg'
          }}
          title="My"
          key="my"
          selected={this.state.selectedTab === 'yellowTab'}
          onPress={() => {
            this.setState({
              selectedTab: 'yellowTab'
            });
          }}
        >
          {this.renderContent('My')}
        </TabBar.Item> */}
      </TabBar>
    </Content>
  );
};
//
