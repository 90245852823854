import React from 'react';
import Chart from 'views/components/organisms/Chart/Chart';

import { useSelector } from 'react-redux';
import { dailyHabitCompletionSelector } from 'redux/modules/habits';

export default () => {
  const habitsCompletion = useSelector(state =>
    dailyHabitCompletionSelector(state)
  );

  const dailyData = [];

  // if (isImmutable(dailyRecords)) {
  Object.keys(habitsCompletion)
    .sort((a, b) => (a > b ? -1 : 1))
    .forEach((day, index) => {
      dailyData.push({
        date: day,
        value: habitsCompletion[day],
        category: 'Habit'
      });
    });

  // }

  return (
    <Chart
      chartName="Habit"
      defaultTab="daily"
      // labels={isImmutable(dailyRecords) ? dailyRecordsDataForChart[0] : []}
      dailyData={dailyData}
      chartType="column"
    />
  );
};
