import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Checkbox, Divider } from 'antd';

import MATERIAL_COLORS from 'views/styles/colors';

import { categoriesByNameSelector } from 'redux/modules/categories';
import { UiActions } from 'redux/modules/ui';

function TaskLeftMenu() {
  const dispatch = useDispatch();

  // const tasksFilteredList = useSelector(state =>
  //   tasksFilteredListSelector(state)
  // );

  const categoriesByName = useSelector(state =>
    categoriesByNameSelector(state)
  );

  // const selectedFilter = useSelector(state =>
  //   state.ui.getIn(['task', 'selectedFilter'])
  // );
  const filterOnlyKeyTask = useSelector(state =>
    state.ui.getIn(['task', 'filterOnlyKeyTask'])
  );

  const selectedProject = useSelector(state =>
    state.ui.getIn(['task', 'selectedProject'])
  );

  return (
    <React.Fragment>
      {/* <TableContainer component={Paper}>
        <Table aria-label="simple table" size="small">
          <TableBody>
            {[
              'All',
              'Past',
              'Today',
              'Week',
              'Month',
              'Future',
              'Unassigned'
            ].map(i => (
              <TableRow
                key={i}
                style={{
                  backgroundColor: selectedFilter === i ? 'green' : 'white'
                }}
                onClick={() => {
                  dispatch(UiActions.updateIn(['task', 'selectedFilter'], i));
                }}
              >
                <TableCell component="th" scope="row">
                  {`${i}`}
                  <span
                    style={{
                      float: 'right'
                    }}
                  >
                    {tasksFilteredList.has(i)
                      ? tasksFilteredList.get(i).toJS().length
                      : 0}
                  </span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <br /> */}
      <Checkbox
        checked={filterOnlyKeyTask}
        onChange={() =>
          dispatch(
            UiActions.updateIn(
              ['task', 'filterOnlyKeyTask'],
              !filterOnlyKeyTask
            )
          )
        }
      >
        Only Key{' '}
      </Checkbox>

      <Divider />

      <div
        role="button"
        tabIndex={0}
        style={{
          backgroundColor:
            selectedProject === '' ? MATERIAL_COLORS.LightBlue : 'white'
        }}
        onClick={() => {
          dispatch(UiActions.updateIn(['task', 'selectedProject'], ''));
        }}
      >
        Show All
      </div>
      {Object.values(categoriesByName)
        .sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1))
        .map(row => (
          <React.Fragment key={row.name}>
            <div
              style={{
                textAlign: 'left',
                marginLeft: 5,
                fontWeight: 'bold',
                borderTop: '1px solid black'
              }}
            >
              {`${row.displayOrder}.${row.name}`}

              <span style={{ float: 'right' }}>{row.isPomo}</span>
            </div>

            {Object.values(row.projects).map((project, index) => (
              <div
                role="button"
                tabIndex={0}
                key={index}
                style={{
                  textAlign: 'right',
                  marginRight: 5,
                  backgroundColor:
                    selectedProject === project.id
                      ? MATERIAL_COLORS.LightBlue
                      : 'white'
                }}
                onClick={() => {
                  dispatch(
                    UiActions.updateIn(['task', 'selectedProject'], project.id)
                  );
                }}
              >
                {project.name}
              </div>
            ))}
          </React.Fragment>
        ))}
    </React.Fragment>
  );
}

export default TaskLeftMenu;
