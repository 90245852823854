import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
// import clsx from 'clsx';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { DiaryActions, selectedDiarySelector } from 'redux/modules/diaries';
import Button from '@material-ui/core/Button';
import { TextField } from '@material-ui/core';

import { FilePond, registerPlugin } from 'react-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import log from 'loglevel';

import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

log.setDefaultLevel(process.env.NODE_ENV === 'production' ? 'error' : 'debug');

// If you need emoji need to add - https://www.jianshu.com/p/c552af25b56b

const useStyles = makeStyles(() => ({
  quill: {
    background: 'white'
  },
  button: {
    float: 'right'
  }
}));

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileEncode
);

export default () => {
  // let reactQuillRef = null;

  const selectedDiary = useSelector(state => selectedDiarySelector(state));

  const selectedDiaryDate = useSelector(state =>
    state.ui.getIn(['diary', 'selectedDiaryDate'])
  );

  const [text, setText] = useState();
  const [title, setTitle] = useState();
  // log.info(`Diary text is ${text}`);

  const modules = {
    toolbar: {
      container: [
        [{ size: ['small', false, 'large', 'huge'] }], //字体设置
        // [{ 'header': [1, 2, 3, 4, 5, 6, false] }], //标题字号，不能设置单个字大小
        ['bold', 'italic', 'underline', 'strike'],
        [
          { list: 'ordered' },
          { list: 'bullet' },
          { indent: '-1' },
          { indent: '+1' }
        ],
        ['link', 'image'], // a链接和图片的显示
        ['clean']
      ],
      handlers: {
        image: () => console.log('Adding image here')
      }
    },
    ImageDrop: true
  };

  useEffect(() => {
    // Get current date of record
    log.debug(`In useEffect, selectedDiary value is ${selectedDiary}`);
    setText(selectedDiary.has('content') ? selectedDiary.get('content') : '');
    setTitle(selectedDiary.has('title') ? selectedDiary.get('title') : '');
  }, [selectedDiary]);

  const classes = useStyles();

  const dispatch = useDispatch();

  const [files, setFiles] = useState([]);

  const [pond, setPond] = useState();

  const saveDiary = () => {
    const diaryData = {
      title,
      content: text,
      date: selectedDiaryDate,
      imageBase64String: null
    };

    if (selectedDiary.has('diaryId')) {
      diaryData['diaryId'] = selectedDiary.get('diaryId');
    }

    // For existing item with image, then just keep the same
    if (selectedDiary.has('imageUrl')) {
      diaryData['imageUrl'] = selectedDiary.get('imageUrl');
    }

    if (pond.getFiles().length > 0) {
      let file = pond.getFiles()[0];
      log.info('Appending data for image');
      diaryData.imageBase64String = file.getFileEncodeBase64String();
    }

    dispatch(DiaryActions.create(diaryData));
  };

  const handleInit = () => {
    log.info('FilePond instance has initialised', pond);
  };

  return (
    <div>
      <div>
        <TextField
          id="title"
          style={{
            width: '80%'
          }}
          // label="Diary title"
          value={title}
          margin="dense"
          placeholder=".."
          onChange={event => setTitle(event.target.value)}
          // onKeyDown={event => {
          //   if (event.which === 13) {
          //     saveTask();
          //   }
          // }}
        />

        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={() => saveDiary()}
        >
          {selectedDiary.has('diaryId') ? 'Save' : 'Create'}
        </Button>
      </div>

      <hr />

      <FilePond
        ref={ref => setPond(ref)}
        files={files}
        oninit={() => handleInit()}
        allowFileEncode={true}
        // onpreparefile={(file, output) => {
        //   console.log('🔥🔥🔥🔥🔥output🔥🔥🔥🔥🔥');
        //   console.log(output);
        // }}
        // onprocessfile={(error, file) => setFiles(files)}
        allowMultiple={false}
        // maxFiles={1}
        // onprocessfile={(error, file) => handleFile(file)}
        name="files"
        labelIdle='Drag&Drop your files or <span class="filepond--label-action">Browse</span>'
      />

      <br />

      <ReactQuill
        className={classes.quill}
        theme="snow"
        value={text}
        server={null}
        onChange={setText}
      />

      {/*
      <ReactQuill
        ref={el => {
          reactQuillRef = el;
        }}
        defaultValue={text}
        key="1"
        id="textDiv1"
        theme="snow"
        modules={modules}
      /> */}
    </div>
  );
};
