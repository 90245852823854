import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { dailyRecordRoutineMetricsSelector } from 'redux/modules/dailyRecords';
import { Card, Badge, Tag } from 'antd';

import moment from 'moment';
import { todayDailyKeyTasksCountSelector } from 'redux/modules/dailyTasks';
import MATERIAL_COLORS from 'views/styles/colors';
import { isImmutable } from 'immutable';

// import { userSettingsMapperSelector } from 'redux/modules/user';
// import { dueMonthlyTasksListSelector } from 'redux/modules/monthlyTasks';
// import { dueWeeklyTasksListSelector } from 'redux/modules/weeklyTasks';

import { selecteDatedTotalPlannedHoursObjectSelector } from 'redux/modules/yearlySubTasks';

import TaskModal from '../../components/organisms/Modal/Task';

import HourblockRightPanelTaskTab from './HourblockRightPanelTaskTab';
import HourblockRightPanelRoutineTab from './HourblockRightPanelRoutineTab';
import HourblockRightPanelHabit from './HourblockRightPanelHabit';
import HourblockRightPanelToday from './HourblockRightPanelToday';

export default () => {
  const [key, setKey] = useState('tab1');

  // const dueMonthlyTasksList = useSelector(state =>
  //   dueMonthlyTasksListSelector(state)
  // );

  // const dueWeeklyTasksList = useSelector(state =>
  //   dueWeeklyTasksListSelector(state)
  // );

  // const currentUserSettings = useSelector(state =>
  //   userSettingsMapperSelector(state)
  // );

  // const uncompletedTaskByCategory = useSelector(state =>
  //   uncompletedTaskByCategorySelector(state)
  // );

  // const selectedCategory = useSelector(state =>
  //   state.ui.getIn(['hourblock', 'selectedCategory'])
  // );

  const todayDailyKeyTasksCount = useSelector(state =>
    todayDailyKeyTasksCountSelector(state)
  );

  const dailyRecordRoutineMetrics = useSelector(state =>
    dailyRecordRoutineMetricsSelector(state)
  );

  const [openTaskModal, setOpenTaskModal] = useState(false);
  const selectedDate = useSelector(state =>
    state.ui.getIn(['hourblock', 'selectedDate'])
  );

  const selecteDatedTotalPlannedHoursObject = useSelector(state =>
    selecteDatedTotalPlannedHoursObjectSelector(state)
  );

  const getSelecteDatedTotalPlannedHours = currentDate => {
    if (selecteDatedTotalPlannedHoursObject[currentDate] !== undefined)
      return selecteDatedTotalPlannedHoursObject[currentDate].totalTaskPomo;

    return null;
  };

  const habits = useSelector(state => state.habits);

  // Note only count as incompleted if already past now
  const incompletedHabitsCount = type =>
    isImmutable(habits)
      ? habits
          .valueSeq()
          .filter(
            h =>
              h.get('type') === type &&
              (h.get('completedDates') == null ||
                h.get('completedDates').indexOf(selectedDate) === -1) &&
              h.get('targetCompletionHour') < moment().hour()
          )
          .count()
      : 0;

  const tabList = [
    {
      key: 'tab1',
      tab: 'Today'
    },
    {
      key: 'tab2',
      tab: (
        <>
          Task
          <Tag
            style={{
              fontSize: 12,
              padding: '0 2px'
            }}
            color={
              getSelecteDatedTotalPlannedHours(selectedDate) >= 8
                ? 'error'
                : getSelecteDatedTotalPlannedHours(selectedDate) >= 6
                ? 'warning'
                : 'default'
            }
          >
            {getSelecteDatedTotalPlannedHours(selectedDate) !== null
              ? getSelecteDatedTotalPlannedHours(selectedDate)
              : '-'}
            /8H
          </Tag>
        </>
      )
    },
    {
      key: 'tab3',
      tab: (
        <span>
          Habit
          {incompletedHabitsCount('daily') > 0 && (
            <Badge count={incompletedHabitsCount('daily')} />
          )}
        </span>
      )
    },
    {
      key: 'tab4',
      tab: (
        <span>
          Routine
          {dailyRecordRoutineMetrics > 0 && (
            <Badge count={dailyRecordRoutineMetrics} />
          )}
        </span>
      )
    }
  ];

  return (
    <>
      <TaskModal
        openTaskModal={openTaskModal}
        setOpenTaskModal={setOpenTaskModal}
      />

      <Card
        style={{ width: '100%' }}
        // title="Card title"
        size="small"
        // extra={<a href="#">More</a>}
        tabList={tabList}
        activeTabKey={key}
        onTabChange={k => {
          setKey(k);
        }}
      >
        {/* {contentList[key]} */}
      </Card>

      {/* FIXME: Remove the card all together, use a normal tab + pro cards */}
      {key === 'tab1' && <HourblockRightPanelToday />}

      {key === 'tab2' && (
        <div
          style={{
            background:
              getSelecteDatedTotalPlannedHours(selectedDate) >= 12
                ? MATERIAL_COLORS.DarkRed
                : getSelecteDatedTotalPlannedHours(selectedDate) >= 8
                ? MATERIAL_COLORS.LightRed
                : 'white'
          }}
        >
          <HourblockRightPanelTaskTab />
        </div>
      )}

      {key === 'tab3' && <HourblockRightPanelHabit />}

      {key === 'tab4' && <HourblockRightPanelRoutineTab />}
    </>
  );
};
