import { Form, Input, Button, Checkbox, Divider } from 'antd';
import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import { AuthActions } from 'redux/modules/auth';

import logo from '../logo.png';

export default () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const dispatch = useDispatch();

  const login = () => {
    dispatch(AuthActions.login(email, password));
  };

  // {/* */}

  // <br />
  // <Button
  //   variant="contained"
  //   color="primary"
  //   onClick={() => login()}
  // >
  //   Login
  // </Button>

  return (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ remember: true }}
      onFinish={login}
      // onFinishFailed={}
      style={{
        width: 300,
        marginLeft: 'calc(50vw - 150px)',
        marginTop: 100
      }}
    >
      <div
        style={{
          width: 300,
          textAlign: 'center'
        }}
      >
        <img src={logo} className="App-logo" alt="logo" />
      </div>

      <Divider plain />
      <Form.Item
        label="Email"
        name="email"
        value={email}
        onChange={event => {
          setEmail(event.target.value);
        }}
        rules={[{ required: true, message: 'Please input your email!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        value={password}
        rules={[{ required: true, message: 'Please input your password!' }]}
        onChange={event => setPassword(event.target.value)}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="remember"
        valuePropName="checked"
        wrapperCol={{ offset: 8, span: 16 }}
      >
        <Checkbox>Remember me</Checkbox>
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};
