import { createSelector } from 'reselect';
import moment from 'moment';
import { isImmutable, fromJS } from 'immutable';

import { actionCreate } from '../_base/actionCreate';
import { reducerCreate } from '../_base/reducerCreate';

export default reducerCreate('anki_stack');
export const { AnkiStackActions } = actionCreate('anki_stack');

const getAnkiStacks = state => state.ankiStacks;

export const ankiStackSortedListSelector = createSelector(
  [getAnkiStacks],
  ankiStacks => {
    return isImmutable(ankiStacks)
      ? ankiStacks
          .valueSeq()
          .sort((a, b) => (a.get('category') > b.get('category') ? -1 : 1))
      : fromJS([]);
  }
);

const getSelectedAnkiStack = state =>
  state.ui.getIn(['anki', 'selectedAnkiStack']);
// const getLearnigCount = state => state.ui.getIn(['anki', 'learningCount']);

export const getSelectedAnkiListSelector = createSelector(
  [getAnkiStacks, getSelectedAnkiStack],
  (ankiStacks, selectedAnkiStack) => {
    const ankis = ankiStacks.getIn([JSON.parse(selectedAnkiStack).id, 'ankis']);

    if (ankis !== undefined) {
      return ankis.valueSeq();
    }

    return fromJS([]);
  }
);

export const getLearningAnkisListSelector = createSelector(
  [getSelectedAnkiListSelector],
  selectedAnkiList => {
    return selectedAnkiList
      .toJS()
      .filter(a => a.isLearning === true)
      .sort((a, b) => {
        // if (a.learningNextDate === undefined) return -1;
        if (a.passingLastDate === undefined) return -1;
        return a.passingLastDate < b.passingLastDate ? -1 : 1;
      });
  }
);

export const getLearnableSelectedAnkiListSelector = createSelector(
  [getSelectedAnkiListSelector],
  selectedAnkiList => {
    const res = [[], []]; // Learnable (order by learnt at), future

    if (selectedAnkiList.size === 0) {
      return res;
    }

    selectedAnkiList.toJS().forEach(rec => {
      if (
        rec.learningNextDate === undefined ||
        rec.learningNextDate <= moment().format('YYYY-MM-DD')
      ) {
        res[0].push(rec);
      } else {
        res[1].push(rec);
      }
      return false;
    });

    res.forEach(resList => {
      resList.sort((a, b) => {
        if (a.learningNextDate === undefined) return -1;
        if (a.passingLastDate === undefined) return -1;
        return a.passingLastDate < b.passingLastDate ? -1 : 1;
      });
    });
    return res;
  }
);
