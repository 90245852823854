import React from 'react';
import Paper from '@material-ui/core/Paper';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';

import TaskRowMainTask from 'views/components/molecules/task/TaskRowMainTask';

export default ({ tasksFilteredArray }) => {
  const convertToTasksByDate = () => {
    const sortedTasks = tasksFilteredArray.sort((a, b) => {
      // Putting key tasks on top
      if (a.get('isKeyTask') !== b.get('isKeyTask')) {
        return a.get('isKeyTask') ? -1 : 1;
      }

      if (a.get('targetCompletionDate') !== b.get('targetCompletionDate')) {
        if (
          a.get('targetCompletionDate') === '' ||
          a.get('targetCompletionDate') === null
        ) {
          return -1;
        }

        if (
          b.get('targetCompletionDate') === '' ||
          b.get('targetCompletionDate') === null
        ) {
          return 1;
        }

        return a.get('targetCompletionDate') > b.get('targetCompletionDate')
          ? -1
          : 1;
      }

      return a.get('projectId') > b.get('projectId') ? 1 : -1;
    });

    const sortedTasksObject = {};
    sortedTasks.forEach(task => {
      if (sortedTasksObject[task.get('targetCompletionDate')] === undefined) {
        sortedTasksObject[task.get('targetCompletionDate')] = [task];
      } else {
        sortedTasksObject[task.get('targetCompletionDate')].push(task);
      }
    });

    return sortedTasksObject;
  };

  return (
    <TableContainer component={Paper}>
      <Table
        size="small"
        style={{
          minWidth: 650
        }}
        aria-label="simple table"
      >
        <TableHead
          style={{
            background: 'whitesmoke'
          }}
        >
          <TableRow>
            <TableCell />
            <TableCell>Category/Project</TableCell>
            <TableCell align="left">Name</TableCell>
            <TableCell
              align="left"
              // style={{
              //   width: 30,
              //   padding: 0
              // }}
            >
              Due
            </TableCell>
            <TableCell align="left">Plan(H)</TableCell>
            <TableCell>-</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {Object.keys(convertToTasksByDate()).map(t => (
            <>
              <div
                style={{
                  height: 20,
                  background: 'whitesmoke',
                  fontSize: 8
                }}
              >
                {t}
              </div>

              {convertToTasksByDate()[t].map((row, index) => (
                <TaskRowMainTask key={index} task={row} type="daily" />
              ))}
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
