import React from 'react';

import { useSelector } from 'react-redux';
import { Select } from 'antd';

import { categoriesByProjectSelector } from 'redux/modules/categories';

const { Option } = Select;

export default ({ defaultValue, onChangeCallBack }) => {
  const yearlyTasks = useSelector(state => state.yearlyTasks);

  const categoriesByProject = useSelector(state =>
    categoriesByProjectSelector(state)
  );

  const getCategory = record => {
    if (
      !record.has('projectId') ||
      categoriesByProject[record.get('projectId')] === undefined
    ) {
      return null;
    }

    const projectId = record.get('projectId');

    const selectedCategory = categoriesByProject[projectId].category;

    return selectedCategory;
  };

  return (
    <Select
      style={{
        width: 160,
        overflow: 'hidden'
      }}
      value={defaultValue}
      onChange={value => onChangeCallBack(value)}
    >
      <Option value="" />
      {yearlyTasks
        .valueSeq()
        .sort((a, b) => {
          if (getCategory(a) !== null && getCategory(b) !== null) {
            return getCategory(a).displayOrder > getCategory(b).displayOrder
              ? 1
              : -1;
          }
          return 0;
        })
        // TODO: adding the project name and index
        // .sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1))
        .map(task => (
          <Option
            style={{
              width: 360
            }}
            value={`${JSON.stringify(task.toJS())}`}
            key={`${getCategory(task) !== null ? task.get('name') : ''}`}
          >
            {getCategory(task) !== null
              ? `${getCategory(task).name} - ${task.get('name')}`
              : '-'}
          </Option>
        ))}
    </Select>
  );
};
