import React from 'react';
import { useSelector } from 'react-redux';

import RoutineTable from 'views/components/molecules/routine/RoutineTable';
import ChartRestTime from 'views/components/organisms/Chart/ChartRestTime';
import ChartWeight from 'views/components/organisms/Chart/ChartWeight';

function DailySummary() {
  const showChart = useSelector(state =>
    state.ui.getIn(['habit', 'showChart'])
  );

  const dailyRecords = useSelector(state => state.dailyRecords);

  return (
    <React.Fragment>
      {showChart && (
        <React.Fragment>
          <ChartRestTime />
          <ChartWeight />
        </React.Fragment>
      )}

      <br />

      <RoutineTable dailyRecords={dailyRecords} />
    </React.Fragment>
  );
}

export default DailySummary;
