import React from 'react';
// import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import { useSelector } from 'react-redux';
import { isImmutable } from 'immutable';
import moment from 'moment';
import { tasksByDateSelector } from 'redux/modules/dailyTasks';

import { Card, Divider } from 'antd';

import TaskByDaySection from './TaskByDaySection';

export default () => {
  const tasksByDate = useSelector(state => tasksByDateSelector(state));

  if (!isImmutable(tasksByDate)) {
    return <React.Fragment />;
  }

  const getUncompletedTasksCount = tasks => {
    let count = 0;
    tasks.toJS().forEach(t => {
      if (!t.isCompleted) {
        count += 1;
      }
    });

    return count;
  };

  return (
    <>
      {tasksByDate
        .keySeq()
        .filter(key => {
          return (
            key >= moment().format('YYYY-MM-DD') ||
            getUncompletedTasksCount(tasksByDate.get(key))
          );
        })
        .sort((a, b) => {
          if (a === '' || a === null) {
            return -1;
          }
          if (b === '' || b == null) {
            return 1;
          }
          return moment(a).valueOf() > moment(b).valueOf() ? 1 : -1;
        })
        .map(key => (
          <Card
            size="small"
            style={{
              marginBottom: 10
            }}
          >
            <TaskByDaySection
              key={key}
              day={key}
              tasks={tasksByDate.get(key)}
            />
          </Card>
        ))}
    </>
  );
};
