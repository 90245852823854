import { createSelector } from 'reselect';

import moment from 'moment';
import _ from 'lodash';
import { actionCreate } from '../_base/actionCreate';
import { reducerCreate } from '../_base/reducerCreate';

export default reducerCreate('daily_record_summary');
export const { DailyRecordSummaryActions } = actionCreate(
  'daily_record_summary'
);

const getDailyRecordSummaries = state => state.dailyRecordSummaries;
const getCategories = state => state.categories;

const getPomoHourCount = (countByCategory, categories) => {
  let pomoHour = 0;

  Object.keys(countByCategory).forEach(k => {
    if (
      categories !== undefined &&
      categories.has(k) &&
      categories.getIn([k, 'isPomo'])
    ) {
      pomoHour += countByCategory[k] / 2;
    }
  });

  return pomoHour;
};

export const last4WeekAveragePomoSelector = createSelector(
  [getDailyRecordSummaries, getCategories],
  (dailyRecordSummaries, categories) => {
    const records = dailyRecordSummaries.toJS();
    let count = 0;

    Object.values(records)
      .filter(rec => {
        const startDate = moment()
          .startOf('isoweek')
          .add(-28, 'days')
          .format('YYYY-MM-DD');

        const endDate = moment()
          .startOf('isoweek')
          .add(-1, 'days')
          .format('YYYY-MM-DD');

        return (
          rec.dailyRecordDate >= startDate && rec.dailyRecordDate <= endDate
        );
      })
      .forEach(rec => {
        count += getPomoHourCount(rec.countByCategory, categories);
      });

    return count / 4;
  }
);

/**
 * Get daily data for graph display on top of the hourblock table
 */
export const getDataForDailyRecordsSelector = createSelector(
  [getDailyRecordSummaries, getCategories],
  (dailyRecordSummaries, categories) => {
    const records = dailyRecordSummaries.toJS();
    const res = []; // pomo, total isPomo,

    Object.values(records)
      .sort((a, b) => (a.dailyRecordDate > b.dailyRecordDate ? -1 : 1))
      .forEach(rec => {
        // const selectedDailyTasks = dailyTasks
        //   .valueSeq()
        //   .filter(a => a.get('targetCompletionDate') === rec.dailyRecordDate);

        //  const score = scoreSelectedDailyRecordSelectorUtil(
        //    dailyRecordMetrics,
        //    selectedDailyTasks
        //  );

        res.push({
          day: rec.dailyRecordDate,
          isUsefulHourblock: getPomoHourCount(rec.countByCategory, categories),
          isProjectFollowPlanCount: rec.isProjectFollowPlanCount / 2,
          totalRecorded: _.sum(Object.values(rec.countByCategory)),
          isCompletedTaskMainHour: rec.isCompletedMainTaskCount / 2
          //  score: score.total
        });
      });

    return res;
  }
);

/**
 * For graph display for the weekly insights on top of hourblock table
 * format is { weeknumber: [_weeklycount_, _followCount_, _mainTaskCompleteCount_, weightedScore]}
 */
export const getWeeklyPomoChartSelector = createSelector(
  [getDailyRecordSummaries, getCategories],
  (dailyRecordSummaries, categories) => {
    const records = dailyRecordSummaries.toJS();
    const res = {};

    Object.values(records).forEach(rec => {
      const weekStart = moment(rec.dailyRecordDate)
        .startOf('isoweek')
        .format('YYYY-MM-DD');
      if (res[weekStart] === undefined) {
        res[weekStart] = [0, 0, 0, 0]; // pomoCount, followedPlanCount
      }

      res[weekStart][0] += getPomoHourCount(rec.countByCategory, categories);
      res[weekStart][1] += rec.isProjectFollowPlanCount / 2;
      res[weekStart][2] += rec.isCompletedMainTaskCount / 2;
      res[weekStart][3] += rec.isPomodoroedCount / 2;
    });

    return res;
  }
);

export const getMonthylPomoChartSelector = createSelector(
  [getDailyRecordSummaries, getCategories],
  (dailyRecordSummaries, categories) => {
    const records = dailyRecordSummaries.toJS();
    const res = {};

    Object.values(records).forEach(rec => {
      const monthStart = moment(rec.dailyRecordDate)
        .startOf('month')
        .format('YYYY-MM-DD');
      if (res[monthStart] === undefined) {
        res[monthStart] = [0, 0, 0, 0]; // pomoCount, followedPlanCount
      }

      res[monthStart][0] += getPomoHourCount(rec.countByCategory, categories);
      res[monthStart][1] += rec.isProjectFollowPlanCount / 2;
      res[monthStart][2] += rec.isCompletedMainTaskCount;
      res[monthStart][3] += rec.isPomodoroedCount / 2;
    });

    return res;
  }
);
