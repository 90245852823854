import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import clsx from 'clsx';

import moment from 'moment';

const useStyles = makeStyles(() => ({
  buildNumber: {
    position: 'fixed',
    left: 0,
    top: 0,
    background: 'whitesmoke',
    fontSize: 12,
    color: 'black',
    padding: '0 5px',
    zIndex: 90000
  }
}));

function BuildNumber() {
  const classes = useStyles();

  // const getElapsedHour = () => {
  //   return `${(
  //     moment().diff(
  //       moment(new Date(process.env.REACT_APP_GIT_TIME).toISOString())
  //     ) /
  //     1000 /
  //     60 /
  //     60
  //   ).toFixed(1)}H`;
  // };

  const getDateTimeString = () => {
    return moment(new Date(process.env.REACT_APP_GIT_TIME)).format(
      'MM-DD HH:mm'
    );
  };

  return (
    <span className={classes.buildNumber}>
      {`${process.env.REACT_APP_GIT_SHA} - ${getDateTimeString()}`}
    </span>
  );
}

BuildNumber.propTypes = {};

export default BuildNumber;
