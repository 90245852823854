import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import clsx from 'clsx';
import { isImmutable } from 'immutable';
import LockIcon from '@material-ui/icons/Lock';
import { Input } from 'antd';

import {
  categoriesByNameSelector,
  categoriesByProjectSelector
} from 'redux/modules/categories';
import {
  DailyPlannedHourblockActions,
  dailyPlannedHourblockBySectionOfWeekSelector
} from 'redux/modules/dailyPlannedHourblocks';

export default ({ dayIndex, plannedPomosIndex, selectedProject }) => {
  const dispatch = useDispatch();

  const dailyPlannedHourblockBySectionOfWeek = useSelector(state =>
    dailyPlannedHourblockBySectionOfWeekSelector(state)
  );

  const categoriesByProject = useSelector(state =>
    categoriesByProjectSelector(state)
  );

  const [updateMinorTask, setUpdateMinorTask] = useState(false);

  const [updatingTaskRecur, setUpdatingTaskRecur] = useState(false);

  let data = ' - ';
  let projectName = '-';

  if (
    isImmutable(dailyPlannedHourblockBySectionOfWeek) &&
    dailyPlannedHourblockBySectionOfWeek.has(
      (dayIndex * 48 + plannedPomosIndex).toString()
    )
  ) {
    const projectId = dailyPlannedHourblockBySectionOfWeek.getIn([
      (dayIndex * 48 + plannedPomosIndex).toString(),
      'projectId'
    ]);
    if (projectId !== null && categoriesByProject[projectId] !== undefined) {
      const project = categoriesByProject[projectId];

      data = project.category.name;
      projectName = project.name;
    }
  }

  const taskRecur = dailyPlannedHourblockBySectionOfWeek.getIn([
    (dayIndex * 48 + plannedPomosIndex).toString(),
    'taskRecur'
  ]);

  const minorTask = dailyPlannedHourblockBySectionOfWeek.getIn([
    (dayIndex * 48 + plannedPomosIndex).toString(),
    'taskRecur'
  ]);

  const categoriesByName = useSelector(state =>
    categoriesByNameSelector(state)
  );

  return (
    <>
      <div
        role="button"
        tabIndex={0}
        style={{
          backgroundColor:
            categoriesByName[data] === undefined
              ? 'gray'
              : `#${categoriesByName[data].color}`,
          color: 'whitesmoke',
          textAlign: 'center',
          float: 'left',
          width: 100,
          minHeight: 20
        }}
        onClick={() => {
          const projectId =
            selectedProject !== null ? selectedProject.id : null;
          if (!updateMinorTask) {
            if (
              dailyPlannedHourblockBySectionOfWeek.getIn([
                (dayIndex * 48 + plannedPomosIndex).toString(),
                'taskMainLocked'
              ])
            ) {
              window.alert('Cannot update locked item');
              return;
            }

            const sectionOfWeek = (
              dayIndex * 48 +
              plannedPomosIndex
            ).toString();

            if (!dailyPlannedHourblockBySectionOfWeek.has(sectionOfWeek)) {
              dispatch(
                DailyPlannedHourblockActions.create({
                  sectionOfWeek,
                  projectId
                })
              );
            } else {
              const selectedPlannedPomo = dailyPlannedHourblockBySectionOfWeek.get(
                sectionOfWeek
              );

              const newRec = selectedPlannedPomo.set('projectId', projectId);

              dispatch(DailyPlannedHourblockActions.update(newRec));
            }
          }
        }}
      >
        {data}
        <span
          style={{
            fontSize: 10,
            display: 'block'
          }}
        >
          {projectName}
        </span>
      </div>
      <LockIcon
        style={{
          width: 20,
          // background: 'red',
          color: dailyPlannedHourblockBySectionOfWeek.getIn([
            (dayIndex * 48 + plannedPomosIndex).toString(),
            'taskMainLocked'
          ])
            ? 'green'
            : 'whitesmoke'
        }}
        onClick={() => {
          const selectedPlannedPomo = dailyPlannedHourblockBySectionOfWeek.get(
            (dayIndex * 48 + plannedPomosIndex).toString()
          );

          const newRec = selectedPlannedPomo.setIn(
            ['taskMainLocked'],
            !selectedPlannedPomo.getIn(['taskMainLocked'])
          );

          dispatch(DailyPlannedHourblockActions.update(newRec));
        }}
      />
      {!updatingTaskRecur ? (
        <div
          style={{
            width: '100%',
            overflow: 'hidden',
            textAlign: 'left',
            fontSize: 12,
            minWidth: 40
          }}
          onClick={() => setUpdatingTaskRecur(true)}
        >
          {taskRecur !== undefined && taskRecur !== null && taskRecur.length > 0
            ? taskRecur
            : '-'}
        </div>
      ) : (
        <Input
          autoFocus
          // style={{
          //   width: '100%',
          //   marginLeft: 5
          // }}
          margin="dense"
          placeholder={minorTask}
          // defaultValue={
          //   selectedPlannedPomo &&
          //   selectedPlannedPomo.getIn([
          //     'plannedPomos',
          //     block.index.toString(),
          //     'tasks',
          //     'main'
          //   ])
          // }
          onKeyDown={event => {
            if (event.which === 13) {
              const rec = dailyPlannedHourblockBySectionOfWeek.get(
                (dayIndex * 48 + plannedPomosIndex).toString()
              );

              const newRec = rec.set('taskRecur', event.target.value);
              //
              dispatch(DailyPlannedHourblockActions.update(newRec)).then(() => {
                setUpdatingTaskRecur(false);
              });
            }
          }}
        />
      )}
    </>
  );
};
