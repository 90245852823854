import React from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import ProCard from '@ant-design/pro-card';
import { Button, Menu, Divider, Space, DatePicker } from 'antd';

import { DailyTaskActions } from 'redux/modules/dailyTasks';
import moment from 'moment';
import YearlyTaskSelect from 'views/components/atoms/YearlyTaskSelect';

export default ({ dailyTask }) => {
  const dispatch = useDispatch();

  const yearlyTasks = useSelector(state => state.yearlyTasks);

  const titleStyle = {
    color: 'gray',
    fontSize: 12
  };

  const customizedDivider = (
    <Divider
      plain
      style={{
        margin: '5px 0'
      }}
    />
  );

  const setTaskToDate = offset => {
    const newTask = dailyTask.set(
      'targetCompletionDate',
      moment()
        .add(offset, 'days')
        .format('YYYY-MM-DD')
    );

    dispatch(DailyTaskActions.create(newTask));
  };

  // Sub tasks etc
  // {
  //   task.hasIn(['subtasks', '0']) &&
  //     task
  //       .get('subtasks')
  //       .toJS()
  //       .map((subtask, index) => (
  //         <TaskRowSubTask
  //           index={index}
  //           task={task}
  //           subtask={subtask}
  //           savingPomo={savingPomo}
  //           setSavingPomo={setSavingPomo}
  //         />
  //       ));
  // }

  return (
    <>
      {dailyTask !== undefined ? (
        <ProCard size="small" bordered>
          {dailyTask.has('name') ? dailyTask.get('name') : '-'}

          {customizedDivider}
          <div>
            <div style={titleStyle}>
              设置日期
              <span style={{ float: 'right' }}>
                {dailyTask.has('targetCompletionDate')
                  ? dailyTask.get('targetCompletionDate')
                  : '-'}
              </span>
            </div>

            <Space>
              <Button size="small" onClick={() => setTaskToDate(0)}>
                Today
              </Button>

              <Button size="small" onClick={() => setTaskToDate(1)}>
                Tomorrow
              </Button>
              <Button size="small" onClick={() => setTaskToDate(7)}>
                +7
              </Button>
              <Button size="small" onClick={() => setTaskToDate(30)}>
                +30
              </Button>

              <DatePicker
                size="small"
                onChange={(date, dateString) => {
                  const newTask = dailyTask.set(
                    'targetCompletionDate',
                    dateString
                  );

                  dispatch(DailyTaskActions.create(newTask));
                }}
              />
            </Space>
          </div>

          {customizedDivider}

          <div>
            <div style={titleStyle}>
              Yearly Task
              <span style={{ float: 'right' }}>
                {dailyTask.has('yearlyTaskId') &&
                dailyTask.get('yearlyTaskId') !== null &&
                yearlyTasks.hasIn([
                  dailyTask.get('yearlyTaskId').toString(),
                  'name'
                ])
                  ? yearlyTasks.getIn([
                      dailyTask.get('yearlyTaskId').toString(),
                      'name'
                    ])
                  : ''}
              </span>
            </div>
            <div>
              <YearlyTaskSelect
                defaultValue=""
                onChangeCallBack={yearlyTaskString => {
                  const yearlyTask = JSON.parse(yearlyTaskString);
                  const newTask = dailyTask.set(
                    'yearlyTaskId',
                    yearlyTask.yearlyTaskId
                  );

                  dispatch(DailyTaskActions.create(newTask));
                }}
              />
            </div>
          </div>

          {/* <div>
        <div style={titleStyle}>优先级</div>
        <div>！</div>
      </div> */}

          {customizedDivider}

          <Menu size="small">
            <Menu.Item
              size="small"
              key="1"
              icon={<DeleteOutlined />}
              onClick={() => {
                if (window.confirm(`Deleting task: ${dailyTask.get('name')}`)) {
                  dispatch(DailyTaskActions.deleteRecord(dailyTask.toJS()));
                }
              }}
            >
              Delete Daily Task
            </Menu.Item>
          </Menu>
        </ProCard>
      ) : (
        '-'
      )}
    </>
  );
};
