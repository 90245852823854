import React from 'react';
// import PropTypes from 'prop-types';
// import { bindActionCreators } from 'redux';

import { useSelector } from 'react-redux';
import ProCard from '@ant-design/pro-card';
import HourblockChart from 'views/components/organisms/Chart/ChartHourblock';
import ChartRestTime from 'views/components/organisms/Chart/ChartRestTime';
import StackBarGraph from 'views/components/molecules/WeeklyPomoStackBarGraph';
import { PageContainer } from '@ant-design/pro-layout';

// import { Timeline, Card } from 'antd';

export default () => {
  const isMobile = useSelector(state => state.ui.getIn(['common', 'isMobile']));

  // const prepareData = () => {
  //   // const { ankiRecords } = this.props;

  //   const data = {
  //     labels: [],
  //     datasets: []
  //   };

  //   const dataSet = (label, dataSetData, backgroundColor) => {
  //     return {
  //       label,
  //       fill: false,
  //       lineTension: 0.1,
  //       backgroundColor,
  //       borderColor: backgroundColor,
  //       borderCapStyle: 'butt',
  //       borderDash: [],
  //       borderDashOffset: 0.0,
  //       borderJoinStyle: 'miter',
  //       pointBorderColor: backgroundColor,
  //       pointBackgroundColor: '#fff',
  //       pointBorderWidth: 1,
  //       pointHoverRadius: 5,
  //       pointHoverBackgroundColor: backgroundColor,
  //       pointHoverBorderColor: backgroundColor,
  //       pointHoverBorderWidth: 2,
  //       pointRadius: 1,
  //       pointHitRadius: 10,
  //       data: dataSetData
  //     };
  //   };

  //   if (isImmutable(ankiRecords)) {
  //     const dataPassing = [];
  //     const dataLearning = [];

  //     data.labels = ankiRecords
  //       .toOrderedMap()
  //       .sort()
  //       .keySeq()
  //       .toJS();

  //     ankiRecords
  //       .toOrderedMap()
  //       .sort()
  //       .valueSeq()
  //       .toJS()
  //       .forEach(rec => {
  //         dataPassing.push(rec.passing === undefined ? 0 : rec.passing);
  //         dataLearning.push(rec.learning === undefined ? 0 : rec.learning);
  //       });
  //     data.datasets.push(dataSet('passing', dataPassing, 'yellow'));
  //     data.datasets.push(dataSet('learning', dataLearning, 'green'));
  //   }

  //   return data;
  // };

  return (
    <PageContainer
      size="small"
      style={{ height: '100%' }}
      className="site-page-header"
      // onBack={() => null}
      title="Dashboard"
      // subTitle={moment(selectedDate).format('ddd')}
      // tags={
      //   moment().format('YYYY-MM-DD') ===
      //     moment(selectedDate).format('YYYY-MM-DD') && (
      //     <Tag color="green">TODAY</Tag>
      //   )
      // }
    >
      <ProCard
        size="small"
        ghost
        // bordered
        bordered="false"
        // headerBordered
        gutter={[0, 8]}
        style={{
          background: 'whitesmoke'
        }}
      >
        <ProCard
          size="small"
          ghost
          style={{
            width: 300
          }}
        >
          <StackBarGraph height={isMobile ? 200 : 300} />
        </ProCard>

        <ProCard
          size="small"
          ghost
          // bordered
          direction="column"
          bordered="false"
          // headerBordered
          gutter={[0, 8]}
        >
          <HourblockChart />
          <ChartRestTime />
        </ProCard>
      </ProCard>
    </PageContainer>
  );
};
