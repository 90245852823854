import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Checkbox, Input, Row, Col, Menu, Dropdown } from 'antd';

import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import PriorityIcon from 'views/components/atoms/PriorityIcon';
import MATERIAL_COLORS from 'views/styles/colors';

import { YearlyTaskActions } from 'redux/modules/yearlyTasks';
import { categoriesByProjectSelector } from 'redux/modules/categories';
import YearlyTaskRightClickBox from 'views/3-Task/calendar/YearlyTaskRightClickBox';

const useStyles = makeStyles({
  checkbox: {
    padding: 0
  },
  category: {
    width: 30,
    height: 20,
    margin: '2px 0',
    background: 'gray',
    marginRight: 5
  },
  pomoCount: {
    width: 20,
    textAlign: 'center',
    backgroundColor: MATERIAL_COLORS.LightBlue
  },
  noPomoCount: {
    width: 20,
    textAlign: 'center',
    backgroundColor: MATERIAL_COLORS.LightGray
  },
  taskName: {
    flex: 1,
    height: 25,
    // lineHeight: 25,
    color: 'black',
    wordBreak: 'break-all',
    overflow: 'hidden'
  }
});

export default ({ yearlyTask, index, type }) => {
  const dispatch = useDispatch();

  const [isEditing, setIsEditing] = useState(false);
  const [savingTask, setSavingTask] = useState(false);

  const selectedProjectFromFilter = useSelector(state =>
    state.ui.getIn(['task', 'selectedProject'])
  );

  const updateRecordIsCompleted = event => {
    const newRec = yearlyTask.set('isCompleted', event.target.checked);

    dispatch(YearlyTaskActions.create(newRec));
  };
  const classes = useStyles();

  const categoriesByProject = useSelector(state =>
    categoriesByProjectSelector(state)
  );

  const getCategory = () => {
    const projectId = yearlyTask.get('projectId');

    if (projectId === null || categoriesByProject[projectId] === undefined) {
      return {};
    }

    const selectedCategory = categoriesByProject[projectId].category;

    return selectedCategory;
  };

  const getBackgroundColor = () => {
    // If future day use light gray
    if (savingTask) return 'orange';

    // if (
    //   yearlyTask.get('targetCompletionDate') > moment().format('YYYY-MM-DD')
    // ) {
    //   return MATERIAL_COLORS.LightGray;
    // }

    // Selected project will be highlighted in blue
    if (selectedProjectFromFilter === yearlyTask.get('projectId')) {
      return MATERIAL_COLORS.LightBlue;
    }

    return 'white';
  };

  const updateTask = (event, field) => {
    if (event.which === 13) {
      const newRec = yearlyTask.setIn([field], event.target.value);
      setSavingTask(true);

      dispatch(YearlyTaskActions.create(newRec)).then(() => {
        setIsEditing(false);
        setSavingTask(false);
      });
    }
  };

  return (
    <Row
      key={index}
      style={{
        backgroundColor: getBackgroundColor(),
        height: 25,
        lineHeight: '25px',
        textDecoration: yearlyTask.get('isCompleted') ? 'line-through' : 'none'
      }}
    >
      {/* <TaskEditDialog
        open={open}
        handleClose={handleClose}
        task={record}
        type={type}
      /> */}
      {type === 'daily' && <PriorityIcon task={yearlyTask} />}

      <Col flex="none">
        <Checkbox
          className={classes.checkbox}
          checked={
            yearlyTask.has('isCompleted') &&
            yearlyTask.get('isCompleted') !== null &&
            yearlyTask.get('isCompleted')
          }
          onChange={updateRecordIsCompleted}
        />
      </Col>
      <Col flex="none">
        <span
          className={classes.category}
          style={{
            background: `#${getCategory().color}`,
            color: 'white',
            width: 40,
            textAlign: 'center'
          }}
        >
          {getCategory().name}
        </span>
      </Col>

      <Col flex="1" style={{ overflow: 'hidden', height: '100%' }}>
        <Dropdown
          overlay={<YearlyTaskRightClickBox task={yearlyTask} />}
          trigger={['contextMenu']}
        >
          {!isEditing ? (
            <span
              style={{
                textDecoration: yearlyTask.get('isCompleted')
                  ? 'line-through'
                  : ''
              }}
              role="button"
              tabIndex={0}
              onClick={() => {
                setIsEditing(true);
              }}
            >
              {yearlyTask.get('name')}
            </span>
          ) : (
            <Input
              style={{
                padding: 0,
                width: '100%',
                borderBottom: '1px black solid'
              }}
              bordered={false}
              id="item"
              defaultValue={yearlyTask.get('name')}
              onKeyDown={event => {
                updateTask(event, 'name');
              }}
            />
          )}
        </Dropdown>
      </Col>

      <Col flex="none">
        {moment(yearlyTask.get('targetCompletionDate')).format('YYYY')}
        {/*
        <IconButton
          variant="contained"
          size="small"
          onClick={() => deleteTask(record)}
        >
          <DeleteIcon color="secondary" fontSize="small" />
        </IconButton> */}
      </Col>
      {/* <span
        className={
          record.get('pomoCount') > 0 ? classes.pomoCount : classes.noPomoCount
        }
      >
        {` ${record.get('pomoCount')}`}
      </span> */}
    </Row>
  );
};
