import { createSelector } from 'reselect';

import { actionCreate } from '../_base/actionCreate';
import { reducerCreate } from '../_base/reducerCreate';

export default reducerCreate('workout');
export const { WorkoutActions } = actionCreate('workout');

const getWorkouts = state => state.workouts;
export const sortedWorkoutsSelector = createSelector(
  [getWorkouts],
  workouts => {
    return workouts.sort((a, b) => {
      if (a.get('focus') < b.get('focus')) {
        return -1;
      }

      if (a.get('focus') > b.get('focus')) {
        return 1;
      }

      if (a.get('isMachine') && !b.get('isMachine')) {
        return -1;
      }

      return a.get('name') < b.get('name');
    });
  }
);
