import React from 'react';

import { useSelector } from 'react-redux';
import { categoriesByProjectSelector } from 'redux/modules/categories';

import YearlyTaskRow from './YearlyTaskRow';
import YearlySubTaskRow from './YearlySubTaskRow';
import TaskRowSimplified from './TaskRowSimplified';

export default () => {
  const yearlyTasks = useSelector(state => state.yearlyTasks);

  const yearlySubTasks = useSelector(state => state.yearlySubTasks);

  const dailyTasks = useSelector(state => state.dailyTasks);

  // const maxYearlyTasks = useSelector(state =>
  //   userSettingsValueSelector(state, userSettingConstants.maxYearlyTasks)
  // );

  const categoriesByProject = useSelector(state =>
    categoriesByProjectSelector(state)
  );

  const getCategory = record => {
    const projectId = record.get('projectId');

    if (projectId === null || categoriesByProject[projectId] === undefined) {
      return {};
    }

    const selectedCategory = categoriesByProject[projectId].category;

    return selectedCategory;
  };

  const filteredYearlySubTasks = yearlyTask =>
    yearlySubTasks.filter(
      r => r.get('yearlyTaskId') === yearlyTask.get('yearlyTaskId')
    );

  const filteredDailyTasks = yearlyTask =>
    dailyTasks.filter(
      r => r.get('yearlyTaskId') === yearlyTask.get('yearlyTaskId')
    );

  return (
    <>
      {yearlyTasks
        .valueSeq()
        .sort((a, b) => {
          return getCategory(a).displayOrder > getCategory(b).displayOrder
            ? 1
            : -1;
          //   if (
          //     a.get('targetCompletionDate') !== b.get('targetCompletionDate')
          //   ) {
          //     return a.get('targetCompletionDate') >
          //       b.get('targetCompletionDate')
          //       ? 1
          //       : -1;
          //   }

          // return a.get('projectId') > b.get('projectId') ? 1 : -1;
        })
        .map((record, index) => (
          <React.Fragment key={index}>
            <span
              style={{
                display: 'block',
                borderBottom: '1px solid whitesmoke'
              }}
            >
              <YearlyTaskRow
                yearlyTask={record}
                index={index}
                type="yearly"
                style={{
                  borderTop: '1px solid black'
                }}
              />
            </span>

            {filteredYearlySubTasks(record)
              .valueSeq()
              .sort((a, b) => {
                if (
                  a.get('targetCompletionDate') !==
                  b.get('targetCompletionDate')
                ) {
                  return a.get('targetCompletionDate') >
                    b.get('targetCompletionDate')
                    ? 1
                    : -1;
                }

                return a.get('projectId') > b.get('projectId') ? 1 : -1;
              })
              .map((yearlySubTask, index) => (
                <YearlySubTaskRow key={index} yearlySubTask={yearlySubTask} />
              ))}

            {/* {filteredDailyTasks(record)
              .valueSeq()
              .sort((a, b) => {
                return a.get('projectId') > b.get('projectId') ? 1 : -1;
              })
              .map((dailyTask, index) => (
                <span
                  key={index}
                  style={{
                    display: 'block',
                    paddingLeft: 10
                  }}
                >
                  <TaskRowSimplified
                    record={dailyTask}
                    index={index}
                    type="daily"
                  />
                </span>
              ))} */}
          </React.Fragment>
        ))}
    </>
  );
};
