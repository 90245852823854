import moment from 'moment';

const { fromJS } = require('immutable');

const initialState = {
  common: {
    showTaskModal: false,
    isLoggedIn: sessionStorage.getItem('accessToken') !== null,
    showPomoFullScreen: false,
    showLeftMenu: true,
    showChartWeight: false,
    isMobile: false
  },
  hourblock: {
    showCalendarView: false,
    showWeeklyPlanned: false,
    showRightBLock: true,
    showChart: false,
    savingPomo: false,
    selectedDate: moment().format('YYYY-MM-DD'),
    showHiddenHabits: false,
    isShowingRecommendedTask: true,
    selectedProjectId: '',
    calendar: {
      selectedStartStr: ''
    }
  },
  planning: {},
  task: {
    selectedFilter: 'All',
    selectedProject: '',
    filterOnlyKeyTask: false
  },
  habit: {
    showChart: false
  },
  location: {
    selectedLocation: null
  },
  anki: {
    selectedAnkiStack: JSON.stringify({
      id: 'Ms8Wo0OUOjfIFf9T3XbM',
      name: 'Japanese'
    }), // Stringify for select value
    learningCount: 1
  },
  workout: {
    showChart: false
  },
  diary: {
    selectedDiaryDate: null
  }
};

export default function UiReducer(state = fromJS(initialState), action) {
  switch (action.type) {
    case 'UPDATE_IN_UI_SUCCESS': {
      return state.setIn(action.path, action.value);
    }

    default:
      return state;
  }
}

// const updateUiSuccess = (key, value) => ({
//   type: 'UPDATE_UI_SUCCESS',
//   key,
//   value,
// });

const updateInUiSuccess = (path, value) => ({
  type: 'UPDATE_IN_UI_SUCCESS',
  path,
  value
});

export const UiActions = {
  // updateUiSuccess,
  updateInUiSuccess,

  // update(key, value) {
  //   return (dispatch, getState) => {
  //     dispatch(updateUiSuccess(key, value));
  //   };
  // },

  updateIn(path, value) {
    return (dispatch, getState) => {
      dispatch(updateInUiSuccess(path, value));
    };
  }
};
