import React from 'react';
import { useSelector } from 'react-redux';

import { selectedDailyRecordSelector } from 'redux/modules/dailyRecords';
import ProCard from '@ant-design/pro-card';

import ChartRestTime from '../../components/organisms/Chart/ChartRestTime';
import ChartWeight from '../../components/organisms/Chart/ChartWeight';
import RoutineBox from '../../components/molecules/routine/RoutineBox';

export default () => {
  const selectedDailyRecord = useSelector(state =>
    selectedDailyRecordSelector(state)
  );

  return (
    <ProCard
      size="small"
      ghost
      // bordered
      bordered="false"
      // headerBordered
      direction="column"
      gutter={[0, 8]}
    >
      <ProCard size="small" title="Routine Records" type="inner" bordered>
        <RoutineBox selectedDailyRecord={selectedDailyRecord} />
      </ProCard>

      <ChartRestTime />

      <ProCard size="small" type="inner" bordered>
        <ChartWeight />
      </ProCard>
    </ProCard>
  );
};
