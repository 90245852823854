import React from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import ProCard from '@ant-design/pro-card';
import { Menu, Divider } from 'antd';

export default ({ task }) => {
  // const dispatch = useDispatch();
  const titleStyle = {
    color: 'gray',
    fontSize: 12
  };

  const customizedDivider = (
    <Divider
      plain
      style={{
        margin: '5px 0'
      }}
    />
  );

  return (
    <>
      {task !== undefined ? (
        <ProCard size="small" bordered>
          {task.has('name') ? task.get('name') : '-'}

          {customizedDivider}
          <div>
            <div style={titleStyle}>
              设置日期
              <span style={{ float: 'right' }}>
                {task.has('targetCompletionDate')
                  ? task.get('targetCompletionDate')
                  : '-'}
              </span>
            </div>
          </div>

          {customizedDivider}

          {/* <Menu size="small">
            <Menu.Item
              size="small"
              key="1"
              icon={<DeleteOutlined />}
              onClick={() => {
                if (window.confirm(`Deleting task: ${task.get('name')}`)) {
                  dispatch(MonthlyTaskActions.deleteRecord(task.toJS()));
                }
              }}
            >
              Delete Weekly Task
            </Menu.Item>
          </Menu> */}
        </ProCard>
      ) : (
        '-'
      )}
    </>
  );
};
