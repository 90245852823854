import React, { useState } from 'react';
// import PropTypes from 'prop-types';
// import { bindActionCreators } from 'redux';

import { useSelector } from 'react-redux';
import { Line } from '@ant-design/charts';
import { PageContainer } from '@ant-design/pro-layout';
import { isImmutable } from 'immutable';
import { withRouter } from 'react-router-dom';
import { Card } from 'antd';
import Workout from './Workout';
import WorkoutRecord from './workoutRecord/WorkoutRecord';
// import Measurements from './measurements';

export default withRouter(props => {
  const workoutRecords = useSelector(state => state.workoutRecords);
  const workoutShowChart = useSelector(state =>
    state.ui.getIn(['workout', 'showChart'])
  );
  const [tab, setTab] = useState('workoutRecord');

  // const selectedTab = props.location.pathname.split('/workout')[1];

  // const selectedTabIndex = selectedTab.indexOf('exercises') > -1 ? 1 : 0; // default first tab

  const prepareData = () => {
    const data = {
      labels: [],
      datasets: []
    };

    const dataSet = (label, dataSetData, backgroundColor) => {
      return {
        label,
        fill: false,
        lineTension: 0.1,
        backgroundColor,
        borderColor: backgroundColor,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: backgroundColor,
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: backgroundColor,
        pointHoverBorderColor: backgroundColor,
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: dataSetData
      };
    };

    if (isImmutable(workoutRecords)) {
      const dataWeight = [];
      const dataScore = [];

      data.labels = workoutRecords
        .toOrderedMap()
        .sort()
        .keySeq()
        .toJS();
      workoutRecords
        .toOrderedMap()
        .sort()
        .valueSeq()
        .toJS()
        .forEach(rec => {
          dataWeight.push(rec.weight === undefined ? 0 : rec.weight);
          dataScore.push(rec.score === undefined ? 0 : rec.score);
        });
      data.datasets.push(dataSet('weight', dataWeight, 'yellow'));
      data.datasets.push(dataSet('score', dataScore, 'green'));
    }

    return data;
  };

  return (
    <PageContainer
      size="small"
      fixedHeader
      header={{
        title: 'Workout'
        // breadcrumb: {
        //   routes: [
        //     {
        //       path: '',
        //       breadcrumbName: 'Task'
        //     }
        // {
        //   path: '',
        //   breadcrumbName: '二级页面'
        // },
        // {
        //   path: '',
        //   breadcrumbName: '当前页面'
        // }
        //   ]
        // }
      }}
      tabList={[
        {
          tab: 'Workout Record',
          key: 'workoutRecord',
          closable: false
        },
        {
          tab: 'Workout',
          key: 'workout',
          closable: false
        }
      ]}
      onTabChange={k => setTab(k)}
      // footer={<span>11</span>}
    >
      {workoutShowChart && (
        <Card size="small">
          <Line data={prepareData()} height={60} />
        </Card>
      )}

      {tab === 'workoutRecord' && <WorkoutRecord />}
      {tab === 'workout' && <Workout />}
    </PageContainer>
  );
});
