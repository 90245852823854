import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from 'antd';
import MonthlyTaskBox from 'views/components/molecules/task/MonthlyTaskBox';
import { tasksFilteredListSelector } from 'redux/modules/dailyTasks';
import TaskNew from 'views/components/molecules/task/TaskNew';
import YearlyTaskBox from 'views/components/molecules/task/YearlyTaskBox';
import WeeklyTaskBox from 'views/components/molecules/task/WeeklyTaskBox';
import ProCard from '@ant-design/pro-card';
import ScrollableTaskBox from 'views/components/molecules/task/ScrollableTaskBox';
import TaskMasterPlanningCalendar from '../../3-Task/calendar/TaskMasterPlanningCalendar';

import { UiActions } from 'redux/modules/ui';

import DailyTaskTable from '../../components/organisms/Table/DailyTaskTable';
import TaskLeftMenu from './TaskLeftMenu';

export default () => {
  const dailyTasksFilteredList = useSelector(state =>
    tasksFilteredListSelector(state)
  );

  const tasksFilteredList = useSelector(state =>
    tasksFilteredListSelector(state)
  );

  const dispatch = useDispatch();

  const filterOnlyKeyTask = useSelector(state =>
    state.ui.getIn(['task', 'filterOnlyKeyTask'])
  );

  return (
    <ProCard
      direction="column"
      ghost
      gutter={[0, 8]}
      style={{ maxHeight: 'calc(100vh - 200px)', overflow: 'auto' }}
    >
      <ProCard bordered size="small">
        <TaskNew />
      </ProCard>

      <ProCard ghost gutter={8}>
        <ProCard colSpan={2} size="small">
          <TaskLeftMenu />
        </ProCard>

        <ProCard ghost direction="column" colSpan={6} gutter={[0, 8]}>
          <ProCard
            title="Yearly Tasks"
            headerBordered
            // extra="...extra..."
            size="small"
          >
            <div
              style={{
                height: 140,
                overflow: 'auto'
              }}
            >
              <YearlyTaskBox />
            </div>
          </ProCard>

          <ProCard
            title="Monthly Tasks"
            headerBordered
            // extra="...extra..."
            size="small"
          >
            <div
              style={{
                height: 200,
                overflow: 'auto'
              }}
            >
              <MonthlyTaskBox />
            </div>
          </ProCard>

          <ProCard
            title="Weekly Tasks"
            headerBordered
            // extra="...extra..."
            size="small"
          >
            <WeeklyTaskBox />
          </ProCard>

          <ProCard title="Recent Daily Tasks" headerBordered size="small">
            <ScrollableTaskBox
              tasksFilteredList={dailyTasksFilteredList}
              taskTypes={['TodayAll']}
              hideCompleted={true}
            />
          </ProCard>
        </ProCard>

        <ProCard colSpan={16} size="small">
          <TaskMasterPlanningCalendar />
        </ProCard>
      </ProCard>

      <ProCard
        colSpan={24}
        title="Daily Tasks"
        headerBordered
        // extra="...extra..."
        size="small"
      >
        <div
          style={{
            background: 'white',
            width: '100%'
          }}
        >
          <Checkbox
            checked={filterOnlyKeyTask}
            onChange={() =>
              dispatch(
                UiActions.updateIn(
                  ['task', 'filterOnlyKeyTask'],
                  !filterOnlyKeyTask
                )
              )
            }
          >
            Only Key{' '}
          </Checkbox>
          <DailyTaskTable
            tasksFilteredArray={tasksFilteredList.get('All').filter(task => {
              if (filterOnlyKeyTask) {
                return task.get('isKeyTask');
              }
              return true;
            })}
          />
        </div>
      </ProCard>
    </ProCard>
  );
};
