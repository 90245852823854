import React from 'react';

import { useSelector } from 'react-redux';
import { Tag, Row, Dropdown } from 'antd';
import { isImmutable } from 'immutable';
import moment from 'moment';
import { tasksByDateSelector } from 'redux/modules/dailyTasks';
import {
  selecteDatedTotalPlannedHoursObjectSelector,
  yearlySubTasksByDateSelector
} from 'redux/modules/yearlySubTasks';

import { categoriesByProjectSelector } from 'redux/modules/categories';
import YearlySubTaskRightClickDialogBox from './YearlySubTaskRightClickBox';

import DailyTaskRightClickDropdown from './DailyTaskRightClickBox';
import MATERIAL_COLORS from 'views/styles/colors';

export default ({ currentDate }) => {
  const filterOnlyKeyTask = useSelector(state =>
    state.ui.getIn(['task', 'filterOnlyKeyTask'])
  );

  const selectedProjectFromFilter = useSelector(state =>
    state.ui.getIn(['task', 'selectedProject'])
  );

  const tasksByDate = useSelector(state => tasksByDateSelector(state));

  const yearlyTasks = useSelector(state => state.yearlyTasks);

  const yearlySubTasksByDate = useSelector(state =>
    yearlySubTasksByDateSelector(state)
  );

  const selecteDatedTotalPlannedHoursObject = useSelector(state =>
    selecteDatedTotalPlannedHoursObjectSelector(state)
  );

  const categoriesByProject = useSelector(state =>
    categoriesByProjectSelector(state)
  );

  const getYearlySubTaskDailyPomo = yearlySubTask => {
    const targetCompletionPomo = yearlySubTask.get('targetCompletionPomo');

    const diffDays =
      moment(yearlySubTask.get('targetCompletionDate')).diff(
        moment(yearlySubTask.get('targetStartDate')),
        'days'
      ) + 1;

    return (targetCompletionPomo / diffDays).toFixed(0);
  };

  const getSelecteDatedTotalPlannedHours = currentD => {
    if (selecteDatedTotalPlannedHoursObject[currentD] !== undefined)
      return selecteDatedTotalPlannedHoursObject[currentD].totalTaskPomo;

    return null;
  };

  const getCategoryForDailyTask = dailyTask => {
    const projectId = dailyTask.get('projectId');

    if (projectId === null || categoriesByProject[projectId] === undefined) {
      return null;
    }

    const selectedCategory = categoriesByProject[projectId].category;

    return selectedCategory; // `#${selectedCategory.color}`;
  };

  const getCategoryForYearlySubTask = yearlySubTask => {
    // first get the yearlyTask
    if (!isImmutable(yearlyTasks)) {
      return null;
    }

    const yearlyTask = yearlyTasks.get(
      yearlySubTask.get('yearlyTaskId').toString()
    );

    if (yearlyTask === undefined) {
      return null;
    }

    const projectId = yearlyTask.get('projectId');

    if (projectId === null || categoriesByProject[projectId] === undefined) {
      return null;
    }

    const selectedCategory = categoriesByProject[projectId].category;

    return `#${selectedCategory.color}`; // `#${selectedCategory.color}`;
  };

  return (
    <>
      <Tag
        style={{
          position: 'absolute',
          left: 5,
          top: 5,
          fontSize: 12,
          padding: '0 2px'
        }}
        color={
          getSelecteDatedTotalPlannedHours(currentDate) >= 8
            ? 'error'
            : getSelecteDatedTotalPlannedHours(currentDate) >= 6
            ? 'warning'
            : 'default'
        }
      >
        {getSelecteDatedTotalPlannedHours(currentDate) !== null
          ? getSelecteDatedTotalPlannedHours(currentDate)
          : '-'}
        /8H
      </Tag>

      {/* {yearlySubTasksByDate.has(currentDate) &&
        yearlySubTasksByDate.get(currentDate).map(yearlySubTask => (
          <Dropdown
            overlay={
              <YearlySubTaskRightClickDialogBox yearlySubTask={yearlySubTask} />
            }
            trigger={['contextMenu']}
          >
            <Row
              key={yearlySubTask.get('yearlySubTaskId')}
              style={{
                fontSize: 12,
                height: 16,
                lineHeight: '16px',
                marginBottom: 2,
                overflow: 'hidden',
                color: 'white',
                background:
                  getCategoryForYearlySubTask(yearlySubTask) === null
                    ? 'white'
                    : getCategoryForYearlySubTask(yearlySubTask),
                opacity: 0.8 // FIXME: rgba wont support hex code so this is a workaround, but this will opaque the text too..
              }}
            >
              <span
                style={{
                  flex: 1
                }}
              >
                {yearlySubTask.get('name')}
              </span>

              <span
                style={{
                  fontWeight: 'bold',
                  color: 'white'
                }}
              >
                {getYearlySubTaskDailyPomo(yearlySubTask)}
              </span>
            </Row>
          </Dropdown>
        ))} */}

      {tasksByDate
        .get(currentDate)
        .filter(t => {
          if (t.get('isCompleted')) {
            return false;
          }

          if (!filterOnlyKeyTask) return true;
          return t.get('isKeyTask');
        })
        .map(dailyTask => (
          <Dropdown
            overlay={<DailyTaskRightClickDropdown dailyTask={dailyTask} />}
            trigger={['contextMenu']}
          >
            <Row
              key={dailyTask.get('dailyTaskId')}
              style={{
                fontSize: 12,
                height: 16,
                lineHeight: '16px',
                marginBottom: 2,
                overflow: 'hidden',
                background:
                  selectedProjectFromFilter === dailyTask.get('projectId')
                    ? MATERIAL_COLORS.LightBlue
                    : 'transparent'
              }}
            >
              {/* {getCategoryForDailyTask(dailyTask) === null
                  ? '-'
                  : getCategoryForDailyTask(dailyTask).name}
           */}

              <span
                style={{
                  // color: 'white',
                  borderLeft: `3px solid ${
                    getCategoryForDailyTask(dailyTask) === null
                      ? 'white'
                      : `#${getCategoryForDailyTask(dailyTask).color}`
                  }`,
                  flex: 1,
                  marginLeft: 2
                }}
              >{`${dailyTask.get('name')}`}</span>

              {/* <Tag
                size="small"
                style={{
                  width: 5
                }}
              >{`${dailyTask.get('targetCompletionPomo')}`}</Tag> */}
            </Row>
          </Dropdown>
        ))}
    </>
  );
};
