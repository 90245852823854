import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Card, Button, Input, Space } from 'antd';
import SimpleSelect from 'views/components/atoms/SimpleSelect';

import { HabitActions } from '../../redux/modules/habits';

export default () => {
  const dispatch = useDispatch();

  const [habitName, setHabitName] = useState('');
  const [goal, setGoal] = useState('');
  const [type, setType] = useState(null);
  const [targetCompletionHour, setTargetCompletionHour] = useState('');
  const [targetCompletionMinute, setTargetCompletionMinute] = useState('');
  const [targetCompletionCount, setTargetCompletionCount] = useState('');

  return (
    <Card size="small">
      <Space>
        <Input
          style={{ width: 200 }}
          // placeholder={record.get('name')}
          value={habitName}
          onChange={event => setHabitName(event.target.value)}
        />

        <Input
          id="targetCompletionHour"
          value={targetCompletionHour}
          type="number"
          style={{ width: 100 }}
          placeholder="target hour"
          onChange={event => setTargetCompletionHour(event.target.value)}
        />

        <Input
          id="targetCompletionMinute"
          value={targetCompletionMinute}
          type="number"
          style={{ width: 100 }}
          placeholder="target minute"
          onChange={event => setTargetCompletionMinute(event.target.value)}
        />

        <Input
          id="targetCompletionCount"
          value={targetCompletionCount}
          type="number"
          style={{ width: 100 }}
          placeholder="target count"
          onChange={event => setTargetCompletionCount(event.target.value)}
        />

        <Input
          id="goal"
          value={goal}
          style={{ width: 200 }}
          placeholder="goal"
          onChange={event => setGoal(event.target.value)}
        />

        <SimpleSelect
          onChangeCallBack={event => setType(event.target.value)}
          currentValue={type}
          values={['daily', 'weekly', 'monthly']}
        />

        <Button
          type="primary"
          onClick={() =>
            dispatch(
              HabitActions.create({
                name: habitName,
                goal,
                targetCompletionCount,
                targetCompletionHour,
                targetCompletionMinute,
                type
              })
            ).then(() => setHabitName(''))
          }
        >
          Add
        </Button>
      </Space>
    </Card>
  );
};
