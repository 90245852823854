import React from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import { getSelectedAnkiListSelector } from '../../redux/modules/ankiStacks';
import AnkiItem from './ankiItem';

const styles = () => ({
  paper: {
    maxWidth: '100vw',
    margin: 'auto',
    overflow: 'hidden'
  },
  card: {
    maxWidth: '100vw',
    margin: 'auto',
    overflow: 'hidden'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  textField: {
    marginLeft: 10,
    marginRight: 10,
    width: 200
  }
});

class AnkiList extends React.Component {
  state = {};

  render() {
    const { classes, selectedAnkiList } = this.props;

    return (
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell align="left">Question/Answer</TableCell>
            <TableCell align="left">Learning/Passing</TableCell>
            <TableCell align="left">Last Passing</TableCell>
            <TableCell align="left">Next Learning</TableCell>
            <TableCell align="left" />
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedAnkiList.map((record, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <AnkiItem record={record} key={index} />
          ))}
        </TableBody>
      </Table>
    );
  }
}

AnkiList.defaultProps = {};

AnkiList.propTypes = {};

function mapStateToProps(state, ownProps) {
  return {
    selectedAnkiList: getSelectedAnkiListSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AnkiList));
