import React from 'react';
import { useSelector } from 'react-redux';

import { userSettingsValueSelector } from 'redux/modules/user';
import userSettingConstants from 'views/constants/userSettingConstants';

import TaskRowSimplified from './TaskRowSimplified';
import moment from 'moment';

import MATERIAL_COLORS from '../../../styles/colors';

export default ({ recentMonthOnly = false }) => {
  const maxMonthlyTasks = useSelector(state =>
    userSettingsValueSelector(state, userSettingConstants.maxMonthlyTasks)
  );

  const monthlyTasks = useSelector(state => {
    return state.monthlyTasks;
  });

  return (
    <div>
      {/* <div
        style={{
          background: MATERIAL_COLORS.Primary,
          width: '100%',
          color: 'white',
          padding: '2px 10px',
          fontWeight: 'bold'
        }}
      >
        Monthly Tasks
        <span
          style={{
            float: 'right',
            paddingRight: 10
          }}
        >
          {monthlyTasks.valueSeq().count()} / {maxMonthlyTasks} Per Month
        </span>
      </div> */}

      {monthlyTasks
        .valueSeq()
        .sort((a, b) => {
          if (
            a.get('targetCompletionMonth') !== b.get('targetCompletionMonth')
          ) {
            return a.get('targetCompletionMonth') >
              b.get('targetCompletionMonth')
              ? 1
              : -1;
          }

          return a.get('projectId') > b.get('projectId') ? 1 : -1;
        })
        .filter(record => {
          if (!recentMonthOnly) {
            return true;
          }

          return (
            moment(record.get('targetCompletionMonth')).diff(
              moment(),
              'month'
            ) < 2
          );
        })
        .map((record, index) => (
          <TaskRowSimplified
            key={index}
            record={record}
            index={index}
            type="monthly"
          />
        ))}
    </div>
  );
};
