import React, { useState } from 'react';

import { withRouter } from 'react-router-dom';

import { PageContainer } from '@ant-design/pro-layout';
import CategoriesTable from './CategoriesTable';
import Gamification from './Gamification';
import UserSetting from './UserSetting';

export default withRouter(props => {
  const selectedTabName = props.location.pathname.split('/setting')[1];

  const selectedTabIndex =
    selectedTabName.length === 0 || selectedTabName.indexOf('categories') > -1
      ? 0
      : 1; // default first tab

  const [tab, setTab] = useState('categoriesTable');

  return (
    <PageContainer
      fixedHeader
      tabActiveKey={tab}
      size="small"
      header={{
        title: 'Settings',
        ghost: true
      }}
      tabList={[
        {
          tab: 'Categories Table',
          key: 'categoriesTable',
          closable: false
        },
        {
          tab: 'User  Settings',
          key: 'userSetting',
          closable: false
        },
        {
          tab: 'Gamification',
          key: 'gamification',
          closable: false
        }
      ]}
      onTabChange={k => setTab(k)}
    >
      {tab === 'categoriesTable' && <CategoriesTable />}
      {tab === 'userSetting' && <UserSetting />}
      {tab === 'gamification' && <Gamification />}
    </PageContainer>
  );
});
