import React from 'react';

import { useSelector } from 'react-redux';

import { Select } from 'antd';

import { orderedCategoriesSelector } from 'redux/modules/categories';

const { Option } = Select;

export default ({ selectedProject, onChangeCallBack, disabled }) => {
  const selectedCategory = () =>
    selectedProject !== null &&
    selectedProject !== '' &&
    selectedProject !== undefined
      ? selectedProject.category
      : null;

  const orderedCategories = useSelector(state =>
    orderedCategoriesSelector(state)
  );

  // From weekly planning default value is null
  const defaultValue =
    selectedCategory() === null ||
    selectedProject === undefined ||
    selectedProject === null
      ? ''
      : `${selectedCategory().name},${selectedProject.name}`;

  return (
    <Select
      size="small"
      bordered={false}
      // native
      disabled={disabled}
      style={{
        minWidth: 120,
        color: 'white',
        backgroundColor:
          selectedCategory() === null || selectedCategory().color === undefined
            ? 'gray'
            : `#${selectedCategory().color}`
      }}
      value={defaultValue}
      onChange={value => {
        const [cat, pro] = value.split(',');

        onChangeCallBack(cat, pro);
      }}
    >
      <Option value="" />
      {Object.values(orderedCategories.toJS()).map(cat =>
        Object.values(cat.projects)
          .sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1))
          .map(pro => (
            <Option
              value={`${cat.name},${pro.name}`}
              key={`${cat.name},${pro.name}`}
            >
              {`${cat.displayOrder}.${cat.name}: ${pro.name}`}
            </Option>
          ))
      )}
    </Select>
  );
};
