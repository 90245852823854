import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { orderedCategoriesSelector } from 'redux/modules/categories';
import {
  DailyTaskActions,
  todayDailyKeyTasksCountSelector
} from 'redux/modules/dailyTasks';
import { Button, Space, Checkbox, Input, Select, DatePicker } from 'antd';
import { MonthlyTaskActions } from 'redux/modules/monthlyTasks';
import { YearlyTaskActions } from 'redux/modules/yearlyTasks';
import {
  YearlySubTaskActions,
  selecteDatedTotalPlannedHoursObjectSelector
} from 'redux/modules/yearlySubTasks';

import { userSettingsMapperSelector } from 'redux/modules/user';
import userSettingConstants from 'views/constants/userSettingConstants';
import { WeeklyTaskActions } from 'redux/modules/weeklyTasks';
import YearlyTaskSelect from 'views/components/atoms/YearlyTaskSelect';

const { Option } = Select;

export default () => {
  const dispatch = useDispatch();

  const categories = useSelector(state => orderedCategoriesSelector(state));

  const selectedDate = useSelector(state =>
    state.ui.getIn(['hourblock', 'selectedDate'])
  );

  const currentUserSettings = useSelector(state =>
    userSettingsMapperSelector(state)
  );

  const todayDailyKeyTasksCount = useSelector(state =>
    todayDailyKeyTasksCountSelector(state)
  );

  const [name, setName] = useState('');

  const [taskType, setTaskType] = useState('daily');
  const [projectId, setProjectId] = useState('');
  const [targetCompletionDate, setTargetCompletionDate] = useState(
    selectedDate
  );

  const [targetStartDate, setTargetStartDate] = useState(selectedDate);
  const [targetCompletionPomo, setTargetCompletionPomo] = useState(0);

  const [isKeyTask, setIsKeyTask] = useState(false);
  const [yearlyTask, setYearlyTask] = useState(null);

  const isKeyTaskCountExceedingLimit = () => {
    return (
      todayDailyKeyTasksCount >=
      currentUserSettings[userSettingConstants.maxDailyKeyTasks]
    );
  };

  const selecteDatedTotalPlannedHoursObject = useSelector(state =>
    selecteDatedTotalPlannedHoursObjectSelector(state)
  );

  const getSelecteDatedTotalPlannedHours = currentD => {
    if (selecteDatedTotalPlannedHoursObject[currentD] !== undefined)
      return selecteDatedTotalPlannedHoursObject[currentD].totalTaskPomo;

    return null;
  };

  const getSelectedDateTotalPlanned = () => {
    const totalPlannedHourForTargetDate = getSelecteDatedTotalPlannedHours(
      targetCompletionDate
    );

    return totalPlannedHourForTargetDate + targetCompletionPomo / 2;
  };

  const saveTask = () => {
    if (projectId === null || projectId === '') {
      window.alert('Project cannot be null');
      return;
    }

    if (
      isKeyTaskCountExceedingLimit() &&
      targetCompletionDate === moment().format('YYYY-MM-DD') &&
      isKeyTask
    ) {
      alert(`Exceeding daily limit for creating key tasks`);
      return;
    }

    if (taskType === 'daily') {
      // If the new task will overshoot the max plannable time (12H) then it will not be added

      if (getSelectedDateTotalPlanned() > 12) {
        alert(
          `Cannot create this task as total hour will be ${getSelectedDateTotalPlanned()}`
        );
        return;
      }

      const recordData = {
        name,
        yearlyTaskId: yearlyTask === null ? null : yearlyTask.yearlyTaskId,
        targetCompletionDate,
        projectId: projectId === null ? null : parseInt(projectId, 10),
        isKeyTask,
        isCompleted: false,
        targetCompletionPomo
      };

      dispatch(DailyTaskActions.create(recordData));
    }

    if (taskType === 'yearly') {
      const recordData = {
        name,
        targetCompletionDate,
        projectId: projectId === null ? null : parseInt(projectId, 10),
        isCompleted: false
      };

      dispatch(YearlyTaskActions.create(recordData));
    }

    if (taskType === 'yearlySub') {
      const recordData = {
        name,
        targetStartDate,
        targetCompletionDate,
        targetCompletionPomo,
        yearlyTaskId: yearlyTask.yearlyTaskId,
        projectId: projectId === null ? null : parseInt(projectId, 10),
        isCompleted: false
      };

      dispatch(YearlySubTaskActions.create(recordData));
    }

    if (taskType === 'weekly') {
      const recordData = {
        name,
        projectId: projectId === null ? null : parseInt(projectId, 10),
        targetCompletionWeek: `${moment(targetCompletionDate).year()}-W${moment(
          targetCompletionDate
        ).isoWeek()}`,
        isCompleted: false
      };

      dispatch(WeeklyTaskActions.create(recordData));
    }

    if (taskType === 'monthly') {
      const recordData = {
        name,
        projectId: projectId === null ? null : parseInt(projectId, 10),
        targetCompletionMonth: moment(targetCompletionDate).format('YYYY-MM'),
        isCompleted: false
      };

      dispatch(MonthlyTaskActions.create(recordData));
    }

    setName('');
  };

  useEffect(() => {
    setTargetCompletionDate(selectedDate);
  }, [selectedDate]);

  return (
    <Space wrap>
      <Select
        id="taskType"
        label="TaskType"
        value={taskType}
        onChange={value => {
          setTaskType(value);
        }}
      >
        <Option value="daily">Daily</Option>
        <Option value="weekly">Weekly</Option>
        <Option value="monthly">Monthly</Option>
        <Option value="yearly">Yearly</Option>
        {/* <Option value="yearlySub">Yearly Sub</Option> */}
      </Select>

      {taskType !== 'yearly' && (
        <YearlyTaskSelect
          defaultValue={yearlyTask !== null ? yearlyTask.name : ''}
          onChangeCallBack={yearlyTaskString => {
            const task = JSON.parse(yearlyTaskString);
            setYearlyTask(task);
            setProjectId(task.projectId);
          }}
        />
      )}

      {taskType !== 'yearlySub' && (
        <Select
          value={projectId}
          onChange={value => setProjectId(value)}
          style={{
            minWidth: 140
          }}
        >
          <Option value="" />
          {categories
            .valueSeq()
            .toJS()
            .map(cat =>
              Object.values(cat.projects)
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map(pro => (
                  <Option value={pro.id} key={`${cat.name},${pro.name}`}>
                    {`${cat.displayOrder}.${cat.name}: ${pro.name}`}
                  </Option>
                ))
            )}
        </Select>
      )}

      <Input
        id="name"
        style={{
          width: 240
        }}
        value={name}
        onChange={event => setName(event.target.value)}
      />

      {taskType !== 'yearly' && (
        <Input
          id="targetCompletionPomo"
          style={{
            width: 50
          }}
          label="Target Pomo"
          value={targetCompletionPomo}
          margin="dense"
          type="number"
          onChange={event => setTargetCompletionPomo(event.target.value)}
        />
      )}

      {(taskType === 'daily' || taskType === 'weekly') && (
        <DatePicker
          defaultValue={moment(targetCompletionDate)}
          onChange={(date, dateString) => {
            setTargetCompletionDate(dateString);
          }}
        />
      )}

      {taskType === 'monthly' && (
        <DatePicker
          onChange={(date, dateString) => {
            setTargetCompletionDate(dateString);
          }}
          picker="month"
        />
      )}

      {taskType === 'daily' && (
        <Checkbox checked={isKeyTask} onChange={() => setIsKeyTask(!isKeyTask)}>
          Imp
        </Checkbox>
      )}

      {/* <span>({getSelectedDateTotalPlanned()}H/12H)</span> */}
      <Button
        variant="outlined"
        onClick={() => saveTask()}
        style={{
          marginTop: 20,
          marginLeft: 20
        }}
      >
        Save
      </Button>
    </Space>
  );
};
