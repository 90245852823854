import React, { useState } from 'react';

import { PageContainer } from '@ant-design/pro-layout';

import { withRouter } from 'react-router-dom';

import WeeklyPlanning from './WeeklyPlanning';
import TodayPlanning from './TodayPlanning';

export default withRouter(props => {
  const selectedTabName = props.location.pathname.split('/planning')[1];

  const selectedTabIndex =
    selectedTabName === '' || selectedTabName.indexOf('weekly') > -1 ? 1 : 0; // default first tab

  const [tab, setTab] = useState('weeklyPlanning');

  return (
    <PageContainer
      size="small"
      header={{
        title: 'Planning',
        ghost: true
      }}
      onTabChange={k => setTab(k)}
      tabList={[
        {
          tab: 'Weekly Planning',
          key: 'weeklyPlanning',
          closable: false
        },
        {
          tab: 'Today Planning',
          key: 'todayPlanning',
          closable: false
        }
      ]}
    >
      {tab === 'todayPlanning' && <TodayPlanning />}
      {tab === 'weeklyPlanning' && <WeeklyPlanning />}
    </PageContainer>
  );
});
