import React from 'react';
import { useSelector } from 'react-redux';

import ProCard from '@ant-design/pro-card';
import HabitList from 'views/components/organisms/List/HabitList';

import { selectedDailyRecordSelector } from 'redux/modules/dailyRecords';

import { tasksFilteredListSelector } from 'redux/modules/dailyTasks';

import ChartHabit from 'views/components/organisms/Chart/ChartHabit';
import WeeklyTaskBox from 'views/components/molecules/task/WeeklyTaskBox';
import MonthlyTaskBox from 'views/components/molecules/task/MonthlyTaskBox';
import CalendarCard from 'views/components/organisms/Card/CalendarCard';

import RoutineBox from '../../components/molecules/routine/RoutineBox';
import ScrollableTaskBox from '../../components/molecules/task/ScrollableTaskBox';
import ChartHourblock from '../../components/organisms/Chart/ChartHourblock';
import NotePad from './NotePad';
import ChartRestTime from '../../components/organisms/Chart/ChartRestTime';
import { Divider } from 'antd';

export default () => {
  const selectedDailyRecord = useSelector(state =>
    selectedDailyRecordSelector(state)
  );

  const tasksFilteredList = useSelector(state =>
    tasksFilteredListSelector(state)
  );

  const weeklyTasks = useSelector(state => {
    return state.weeklyTasks;
  });

  return (
    <>
      {weeklyTasks.valueSeq().size > 0 && (
        <ProCard size="small" type="inner" bordered>
          <WeeklyTaskBox />
        </ProCard>
      )}

      <ProCard
        size="small"
        type="inner"
        bordered
        style={{
          maxHeight: 360,
          overflow: 'auto'
        }}
      >
        <ScrollableTaskBox
          tasksFilteredList={tasksFilteredList}
          taskTypes={['TodayAll']}
          hideCompleted={true}
        />
      </ProCard>

      <ProCard size="small" type="inner" bordered>
        <HabitList habitType="daily" />
      </ProCard>

      <ProCard size="small" type="inner" bordered>
        <RoutineBox selectedDailyRecord={selectedDailyRecord} />
      </ProCard>

      <ChartHourblock showHourblockOnly />

      <ChartRestTime />

      <ProCard size="small" type="inner" bordered>
        <MonthlyTaskBox recentMonthOnly={true} />
      </ProCard>

      <ProCard
        size="small"
        type="inner"
        bordered
        style={{ marginTop: 10, marginBottom: 10 }}
      >
        <ChartHabit />
      </ProCard>

      <CalendarCard />

      <ProCard
        size="small"
        type="inner"
        bordered
        style={{ marginTop: 10, marginBottom: 10 }}
      >
        <NotePad />
      </ProCard>
    </>
  );
};
