import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import clsx from 'clsx';

import { Modal } from 'antd';
import { UiActions } from 'redux/modules/ui';
import HourblockChart from './ChartHourblock';

// const useStyles = makeStyles(() => ({}));

export default () => {
  const dispatch = useDispatch();

  const showChart = useSelector(state =>
    state.ui.getIn(['hourblock', 'showChart'])
  );

  // const [isModalVisible, setIsModalVisible] = useState(false);

  // const showModal = () => {
  //   setIsModalVisible(true);
  // };

  const handleOk = () => {
    dispatch(UiActions.updateIn(['hourblock', 'showChart'], !showChart));
  };

  const handleCancel = () => {
    dispatch(UiActions.updateIn(['hourblock', 'showChart'], !showChart));
  };

  return (
    <Modal
      title="Hourblock Charts"
      visible={showChart}
      onOk={handleOk}
      onCancel={handleCancel}
      style={{
        width: '80%'
      }}
    >
      <HourblockChart />
    </Modal>
  );
};
