import React from 'react';
import { Grid } from '@material-ui/core';
import DiaryLeftList from './DiaryLeftList';
import DiaryDetails from './DiaryDetails';

// import clsx from 'clsx';

export default () => {
  // const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item xs={2}>
        <DiaryLeftList />
      </Grid>

      <Grid item xs={10}>
        <DiaryDetails />
      </Grid>
    </Grid>
  );
};
