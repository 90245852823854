const hourblockDividerConstants = [
  // {
  //   index: 11,
  //   message: '- - - - - - - - ☀  早起吃早餐  ☀️ - - - - - - - -',
  //   reminder: '喝三口水 | 眼保健餐 | 20开合跳 | 早餐'
  // },
  // {
  //   index: 15,
  //   message: '- - - - - - - - 📚  清晨知识时间  📚  - - - - - - - -',
  //   reminder: '10Anki'
  // },
  // {
  //   index: 19,
  //   message: '- - - - - - - - 📚  上午工作  📚  - - - - - - - -',
  //   reminder: '眼保健餐'
  // },
  // {
  //   index: 23,
  //   message: '- - - - - - - - 🍲  吃午餐啦  🍲  - - - - - - - -',
  //   reminder: '20开合跳 | 午餐 | 午休'
  // },
  // {
  //   index: 27,
  //   message: '- - - - - - - - 📚  下午的工作 1/2  📚  - - - - - - - -',
  //   reminder: '计划下午 | 眼保健餐'
  // },
  // {
  //   index: 31,
  //   message: '- - - - - - - - 📚  下午的工作 2/2  📚  - - - - - - - -',
  //   reminder: '10Anki'
  // },
  // {
  //   index: 35,
  //   message: '- - - - - - - - 🥘  晚餐吃少吃好  🥘  - - - - - - - -',
  //   reminder: '10Anki'
  // },
  // {
  //   index: 39,
  //   message: '- - - - - - - - 💻  开发时间  💻  - - - - - - - -',
  //   reminder: '10Anki'
  // },
  // {
  //   index: 43,
  //   message: '- - - - - - - - 🎹  恢复与更新  🎹  - - - - - - - -',
  //   reminder: '照片日记 | 明3任务'
  // },
  // {
  //   index: 47,
  //   message: '- - - - - - - - 💤  准备睡觉把  💤   - - - - - - - -',
  //   reminder: '1230前关电脑'
  // }
];

export { hourblockDividerConstants };
