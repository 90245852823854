import React from 'react';
import Chart from 'views/components/organisms/Chart/Chart';
import { isImmutable } from 'immutable';
import { useSelector } from 'react-redux';
import { dailyRecordsDataForChartSelector } from 'redux/modules/dailyRecords';

export default () => {
  const dailyRecords = useSelector(state => state.dailyRecords);

  const dailyRecordsDataForChart = useSelector(state =>
    dailyRecordsDataForChartSelector(state)
  );

  const dailyData = [];

  if (isImmutable(dailyRecords)) {
    dailyRecordsDataForChart[0].forEach((day, index) => {
      dailyData.push({
        date: day,
        value: dailyRecordsDataForChart[1][index],
        category: 'Weight'
      });
    });
  }

  return (
    <Chart
      chartName="Weight"
      defaultTab="daily"
      // labels={isImmutable(dailyRecords) ? dailyRecordsDataForChart[0] : []}
      dailyData={dailyData}
    />
  );
};
