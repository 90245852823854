import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import clsx from 'clsx';
import { isImmutable } from 'immutable';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import ProjectSelect from 'views/components/atoms/ProjectSelect';
// import ProjectSelect from 'views/componfents/atoms/ProjectSelect';
import { categoriesByProjectSelector } from 'redux/modules/categories';

import {
  DailyPlannedHourblockActions,
  selectedSectionSelector
} from 'redux/modules/dailyPlannedHourblocks';

const itemWidth = 80;

const useStyles = makeStyles(() => ({
  time: {
    display: 'block',
    width: itemWidth,
    lineHeight: '30px',
    height: 30,
    fontSize: 20,
    textAlign: 'center',
    padding: '0 0px'
  },
  category: props => ({
    textAlign: 'center',
    display: 'block',
    width: itemWidth,
    height: 35,
    color: 'white',
    backgroundColor:
      props.category === undefined || props.category.color === undefined
        ? 'gray'
        : `#${props.category.color}`
  }),
  project: {
    height: 15,
    fontSize: 10,
    lineHeight: '15px',
    zIndex: 99
  }
}));

export default ({ block, selectedDailyRecord, selectedPlannedPomo }) => {
  const isMobile = useSelector(state => state.ui.getIn(['common', 'isMobile']));

  const [editMode, setEditMode] = useState(false);

  const dispatch = useDispatch();

  const categoriesByProject = useSelector(state =>
    categoriesByProjectSelector(state)
  );

  const sectionOfWeek = useSelector(state =>
    selectedSectionSelector(state, block)
  );

  const selectedProject =
    isImmutable(selectedPlannedPomo) &&
    categoriesByProject[selectedPlannedPomo.get('projectId')] !== undefined
      ? categoriesByProject[selectedPlannedPomo.get('projectId')]
      : { category: {} };

  const classes = useStyles({
    category: selectedProject.category
  });

  // TODO: not sure what is this
  const getProjectRef = () => {
    return isImmutable(selectedDailyRecord) &&
      selectedDailyRecord.hasIn([
        'pomos',
        block.index.toString(),
        'projectsRef'
      ])
      ? selectedDailyRecord.getIn(['pomos', block.index.toString()]).toJS()
      : {};
  };

  const updateDailyRecordHourblock = (categoryName, projectName) => {
    const selectedProjectToUpdate = _.find(
      Object.values(categoriesByProject),
      rec => {
        return rec.name === projectName;
      }
    );

    if (selectedProjectToUpdate === undefined) {
      alert('No selectedProjectId');
    }

    let newRec = {};

    if (selectedPlannedPomo !== undefined) {
      newRec = selectedPlannedPomo.set('projectId', selectedProjectToUpdate.id);

      dispatch(DailyPlannedHourblockActions.update(newRec)).then(() =>
        setEditMode(false)
      );
    } else {
      newRec = {
        projectId: selectedProjectToUpdate.id,
        sectionOfWeek
      };

      dispatch(DailyPlannedHourblockActions.create(newRec)).then(() =>
        setEditMode(false)
      );
    }
  };

  return (
    <>
      <div
        className={classes.time}
        style={{
          backgroundColor:
            getProjectRef().projectsRef !== undefined &&
            getProjectRef().isProjectFollowPlanCount
              ? 'green'
              : 'whitesmoke'
        }}
      >
        {block.time}
      </div>

      {!isMobile && (
        <div
          style={{
            border:
              selectedPlannedPomo !== undefined &&
              selectedPlannedPomo.get('taskMainLocked')
                ? '1px solid black'
                : '1px solid transparent'
          }}
        >
          {editMode ? (
            <ProjectSelect
              selectedProject={selectedProject}
              onChangeCallBack={updateDailyRecordHourblock}
            />
          ) : (
            <div
              className={classes.category}
              onClick={() => {
                if (
                  selectedPlannedPomo !== undefined &&
                  !selectedPlannedPomo.get('taskMainLocked')
                )
                  setEditMode(true);
              }}
            >
              {selectedPlannedPomo !== undefined &&
              selectedPlannedPomo.get('taskMainLocked')
                ? '🔒 '
                : ''}
              {selectedProject.category.name}
              <div className={classes.project}>{selectedProject.name}</div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
