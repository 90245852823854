import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { currentWeekHourblockSelector } from 'redux/modules/dailyRecords';

import {
  categoriesByProjectSelector,
  weeklyPlannedHourSelector
} from 'redux/modules/categories';
import MATERIAL_COLORS from 'views/styles/colors';
import ProCard from '@ant-design/pro-card';
import { Divider } from 'antd';

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 'bold',
    fontSize: 16,
    textAlign: 'center',
    margin: '5px 0'
  },
  paper: {
    background: 'white',
    padding: 2
  },
  block: props => ({
    marginBottom: 10,
    border: '1px solid black',
    width: props.BASIC_BLOCK_WIDTH,
    height: 20,
    boxSizing: 'border-box',
    display: 'inline-block',
    backgroundSize: '30px 1px',
    marginRight: 10,
    color: 'black',
    position: 'relative',
    backgroundColor: 'whitesmoke'
  }),
  // blockProgressBar: {
  //   position: 'absolute',
  //   left: 0,
  //   top: 0,
  //   height: 'inherit',
  //   display: 'inline-block',
  //   zIndex: 0
  // },
  blockText: {
    display: 'inline-block',
    zIndex: 1,
    position: 'relative',
    width: 'inherit'
  }
}));

export default () => {
  const BASIC_BLOCK_WIDTH = 150;
  const classes = useStyles({ BASIC_BLOCK_WIDTH });

  const currentWeekHourblock = useSelector(state =>
    currentWeekHourblockSelector(state)
  );

  const weeklyPlannedHour = useSelector(state =>
    weeklyPlannedHourSelector(state)
  );

  // const dailyPlannedHourblocksMetrics = useSelector(state =>
  //   dailyPlannedHourblocksMetricsSelector(state)
  // );

  const categoriesByProject = useSelector(state =>
    categoriesByProjectSelector(state)
  );

  // const totalPlannedHourblock = () => {
  //   let res = 0;
  //   Object.values(dailyPlannedHourblocksMetrics[2]).forEach(rec => {
  //     res += rec.value;
  //   });

  //   return res;
  // };

  // const totalCurrentWeekHourblock = () => {
  //   let res = 0;
  //   Object.values(currentWeekHourblock.byProject).forEach(rec => {
  //     res += rec.value;
  //   });

  //   return res;
  // };

  const isWeeklyPlannedCompleted = project => {
    const projectName = project.name;

    const actualProjectHourblocks =
      currentWeekHourblock.byProject[projectName] === undefined
        ? 0
        : currentWeekHourblock.byProject[projectName].value;

    return actualProjectHourblocks >= project.estimatedHourWeek;
  };

  const totalCompletedPlannedHours = () => {
    let res = 0;

    Object.values(categoriesByProject)
      .filter(project => !isWeeklyPlannedCompleted(project))
      .forEach(proj => {
        res +=
          currentWeekHourblock.byProject[proj.name] === undefined
            ? 0
            : currentWeekHourblock.byProject[proj.name].value;
      });

    return res;
  };

  const renderProjectBlock = project => {
    const projectName = project.name;

    // const plannedProjectHourblocks =
    //   dailyPlannedHourblocksMetrics[2][projectName] === undefined
    //     ? 0
    //     : dailyPlannedHourblocksMetrics[2][projectName].value;

    const actualProjectHourblocks =
      currentWeekHourblock.byProject[projectName] === undefined
        ? 0
        : currentWeekHourblock.byProject[projectName].value;

    const category = project.category;

    return (
      <React.Fragment>
        <span
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            height: 'inherit',
            display: 'inline-block',
            zIndex: 0,
            width:
              actualProjectHourblocks < project.estimatedHourWeek
                ? (BASIC_BLOCK_WIDTH * actualProjectHourblocks) /
                  project.estimatedHourWeek
                : BASIC_BLOCK_WIDTH,
            borderLeft: `5px solid #${category.color}`,
            boxSizing: 'border-box',
            backgroundColor:
              actualProjectHourblocks >= project.estimatedHourWeek * 0.8 &&
              !project.category.isPomo
                ? MATERIAL_COLORS.LightRed
                : MATERIAL_COLORS.Primary // `#${category.color}`
          }}
        />

        <span className={classes.blockText}>
          {`${category.displayOrder}.${category.name} - ${projectName}`}

          <span
            style={{
              float: 'right',
              position: 'absolute',
              right: 0
            }}
          >
            {actualProjectHourblocks}/ {project.estimatedHourWeek}
            {/* {plannedProjectHourblocks} */}
          </span>
        </span>
      </React.Fragment>
    );
  };

  return (
    <ProCard
      size="small"
      bordered
      type="inner"
      title="Weekly Actuals"
      extra={`${totalCompletedPlannedHours()} / ${weeklyPlannedHour}`}
    >
      {Object.values(categoriesByProject)
        .filter(project => !isWeeklyPlannedCompleted(project))
        .sort((a, b) => a.category.displayOrder - b.category.displayOrder)
        .map(project => (
          <div key={project.name} className={classes.block}>
            <React.Fragment>{renderProjectBlock(project)}</React.Fragment>
          </div>
        ))}

      <Divider plain />

      {Object.values(categoriesByProject)
        .filter(project => isWeeklyPlannedCompleted(project))
        .sort((a, b) => a.category.displayOrder - b.category.displayOrder)
        .map(project => (
          <div key={project.name} className={classes.block}>
            {renderProjectBlock(project)}
          </div>
        ))}
    </ProCard>
  );
};
