import React from 'react';

import { Row, Dropdown } from 'antd';
import { useSelector } from 'react-redux';
import moment from 'moment';

import ProCard from '@ant-design/pro-card';
import { yearlySubTasksByDateSelector } from 'redux/modules/yearlySubTasks';
import { tasksFilteredListSelector } from 'redux/modules/dailyTasks';
import { weeklyTasksListSelector } from 'redux/modules/weeklyTasks';
import YearlySubTaskRightClickDialogBox from 'views/3-Task/calendar/YearlySubTaskRightClickBox';

import DailyTaskRightClickDropdown from 'views/3-Task/calendar/DailyTaskRightClickBox';
import WeeklyPomoStackBarGraph from 'views/components/molecules/WeeklyPomoStackBarGraph';

import TaskNew from '../../components/molecules/task/TaskNew';
import ScrollableTaskBox from '../../components/molecules/task/ScrollableTaskBox';
import WeeklyTaskBox from 'views/components/molecules/task/WeeklyTaskBox';

export default () => {
  const weeklyTasksList = useSelector(state => weeklyTasksListSelector(state));

  const yearlySubTasksByDate = useSelector(state =>
    yearlySubTasksByDateSelector(state)
  );

  const selectedDate = useSelector(state =>
    state.ui.getIn(['hourblock', 'selectedDate'])
  );

  const getYearlySubTaskDailyPomo = yearlySubTask => {
    const targetCompletionPomo = yearlySubTask.get('targetCompletionPomo');

    const diffDays =
      moment(yearlySubTask.get('targetCompletionDate')).diff(
        moment(yearlySubTask.get('targetStartDate')),
        'days'
      ) + 1;

    return (targetCompletionPomo / diffDays).toFixed(0);
  };

  const tasksFilteredList = useSelector(state =>
    tasksFilteredListSelector(state)
  );

  const cardStyle = {
    maxHeight: 300,
    overflow: 'auto'
  };

  const getCurrentDayYearlySubTasks = () => (
    <>
      {yearlySubTasksByDate.has(selectedDate) &&
        yearlySubTasksByDate.get(selectedDate).map(yearlySubTask => (
          <Dropdown
            key={yearlySubTask.get('yearlySubTaskId')}
            overlay={
              <YearlySubTaskRightClickDialogBox yearlySubTask={yearlySubTask} />
            }
            trigger={['contextMenu']}
          >
            <Row
              key={yearlySubTask.get('name')}
              style={{
                fontSize: 12,
                height: 16,
                lineHeight: '16px',
                marginBottom: 2,
                overflow: 'hidden'
              }}
            >
              <span
                style={{
                  flex: 1
                }}
              >
                {yearlySubTask.get('name')}
              </span>

              <span
                style={{
                  fontWeight: 'bold',
                  color: 'black'
                }}
              >
                {getYearlySubTaskDailyPomo(yearlySubTask)}
              </span>
            </Row>
          </Dropdown>
        ))}
    </>
  );

  return (
    <ProCard
      size="small"
      ghost
      // bordered
      bordered="false"
      // headerBordered
      direction="column"
      gutter={[0, 8]}
      style={{
        background: 'whitesmoke'
      }}
    >
      {/* FOR TESTING ONLY */}
      {/* <DailyTaskRightClickDropdown
        dailyTask={tasksFilteredList.get('TodayAll').get('0')}
      /> */}

      {/* <ProCard size="small" title="Yearly Sub Tasks" type="inner" bordered>
        {getCurrentDayYearlySubTasks()}
      </ProCard> */}

      <ProCard size="small" title="All Daily" type="inner" bordered>
        <ScrollableTaskBox
          tasksFilteredList={tasksFilteredList}
          taskTypes={['All']}
        />
      </ProCard>

      <ProCard
        size="small"
        title="Weekly"
        style={cardStyle}
        type="inner"
        bordered
      >
        <WeeklyTaskBox />
      </ProCard>

      {/* <ProCard size="small" title="Upcoming Week" type="inner" bordered>
        <ScrollableTaskBox
          tasksFilteredList={tasksFilteredList}
          taskTypes={['UpcomingWeek']}
        />
      </ProCard> */}

      <ProCard size="small" title="New Tasks" type="inner" bordered>
        <TaskNew />
      </ProCard>

      <ProCard size="small" title="Trend" type="inner" bordered>
        <WeeklyPomoStackBarGraph />
      </ProCard>
    </ProCard>
  );
};
