import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import { isImmutable, fromJS } from 'immutable';
import { useSelector } from 'react-redux';

import HabitRowSimplified from './HabitRowSimplified';

// const useStyles = makeStyles(() => ({}));

export default ({ habitType }) => {
  // const classes = useStyles();

  const habits = useSelector(state => state.habits);

  const showHiddenHabits = useSelector(state =>
    state.ui.getIn(['hourblock', 'showHiddenHabits'])
  );

  const getHabitsByType = () => {
    return isImmutable(habits)
      ? habits
          .valueSeq()
          .filter(h => h.has('type') && h.get('type') === habitType)
      : fromJS([]);
  };

  const selectedDate = useSelector(state =>
    state.ui.getIn(['hourblock', 'selectedDate'])
  );

  const isCompleted = habit => {
    return (
      habit.get('completedDates') !== undefined &&
      habit.get('completedDates') !== null &&
      habit.get('completedDates').indexOf(selectedDate) > -1
    );
  };

  return (
    <>
      {getHabitsByType()
        .sort((a, b) => {
          // if (a.get('targetCompletionHour') !== b.get('targetCompletionHour')) {
          //   return a.get('targetCompletionHour') > b.get('targetCompletionHour')
          //     ? 1
          //     : -1;
          // }

          // return a.get('targetCompletionMinute') >
          //   b.get('targetCompletionMinute')
          //   ? 1
          //   : -1;

          return isCompleted(a) ? 1 : -1;
        })
        // .filter(habit => (showHiddenHabits ? 1 : !isCompleted(habit)))
        .map((habit, index) => (
          <HabitRowSimplified key={index} habit={habit} />
        ))}
    </>
  );
};
