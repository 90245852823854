import React from 'react';
// import clsx from 'clsx';
import LinearProgress from '@material-ui/core/LinearProgress';

import { useSelector } from 'react-redux';

import {
  dailyRecordMetricsSelector,
  weeklyRecordMetricsSelector
} from 'redux/modules/dailyRecords';

import { last4WeekAveragePomoSelector } from 'redux/modules/dailyRecordSummaries';
import { selectedDailyPlannedHourblockMetricsSelector } from 'redux/modules/dailyPlannedHourblocks';

export default () => {
  const renderProgress = (
    title,
    current,
    reference,
    colorType,
    showText = true
  ) => {
    return (
      <React.Fragment>
        {showText && (
          <div
            style={{
              color: 'white',
              textAlign: 'center',
              marginTop: 3,
              fontWeight: 'bold',
              fontSize: 10
            }}
          >
            {title}: {current} / {reference}
          </div>
        )}

        <LinearProgress
          style={{
            marginBottom: 2
            // backgroundColor: current > reference ? 'green' : 'blue'
          }}
          color={colorType}
          variant="determinate"
          value={current > reference ? 100 : (current * 100) / reference}
        />
      </React.Fragment>
    );
  };

  const dailyRecordMetrics = useSelector(state =>
    dailyRecordMetricsSelector(state)
  );

  const selectedDailyPlannedHourblockMetrics = useSelector(state =>
    selectedDailyPlannedHourblockMetricsSelector(state)
  );

  const last4WeekAveragePomo = useSelector(state =>
    last4WeekAveragePomoSelector(state)
  );

  const weeklyRecordMetrics = useSelector(state =>
    weeklyRecordMetricsSelector(state)
  );

  return (
    <div
      style={{
        position: 'fixed',
        bottom: 10,
        width: 200,
        backgroundColor: 'black',
        padding: '5px 0'
      }}
    >
      {renderProgress(
        'Daily',
        dailyRecordMetrics.isPomoHour,
        // Use a fixed number for now
        // 8,
        (last4WeekAveragePomo / 7).toFixed(1),
        'primary'
      )}

      {renderProgress(
        'Weekly Pomo',
        weeklyRecordMetrics.currentWeekIsPomoHour,
        // Use a fixed number for now
        // 65,
        last4WeekAveragePomo.toFixed(1),
        'primary'
      )}

      {renderProgress(
        'Weekly Followed',
        weeklyRecordMetrics.currentWeekIsProjectFollowPlan,
        // Use a fixed number for now
        // 30,
        last4WeekAveragePomo.toFixed(1),
        'secondary'
      )}

      {renderProgress(
        'Followed',
        // dailyRecordMetrics.isPomodoroed,
        dailyRecordMetrics.isProjectFollowPlan,
        // Use a fixed number for now
        5,
        'secondary',
        false
      )}

      {/* {renderProgress(
          '',
          dailyRecordMetrics.isCompletedTaskMainHour,
          12,
          'primary',
          false
        )} */}

      {/* {renderProgress(
          'Key Tasks Completed',
          todayDailyKeyTasksCompletedCount,
          todayDailyKeyTasksCount,
          'primary'
        )} */}

      {/* {this.renderProgress(
            'Daily Task',
            dailyRecordMetrics.taskFinishedHour,
            24,
            'primary',
            false
          )} */}

      {/* {this.renderProgress(
            'Daily Reucr Task',
            dailyRecordMetrics.recurTaskFinishedHour,
            24,
            'primary',
            false
          )} */}

      {/* {renderProgress(
          'Daily Habit',
          lastWeekHabitMetrics[0],
          // lastWeekHabitMetrics[1],
          30,
          'secondary'
        )} */}

      {/* {renderProgress(
        'Minihabit',
        dailyRecordMetrics.isCompletedTaskRecurHour,
        // dailyRecordMetrics.isProjectFollowPlan,
        // Use a fixed number for now
        selectedDailyPlannedHourblockMetrics,
        'secondary'
      )} */}
    </div>
  );
};
