import axios from 'axios';
import * as Promise from 'bluebird';
import { isImmutable } from 'immutable';
import BASE_URL from 'views/constants/urlConstants';
import { message } from 'antd';

// const BASE_URL =
//   'https://asia-northeast1-hourblock-6c781.cloudfunctions.net/webApi/api/v1';

// let env = '';
// if (process.env.REACT_APP_ENV !== undefined) {
//   env = process.env.REACT_APP_ENV;
// } else {
//   env =
//     window.document.location.href.indexOf('localhost') > 0 ? 'local' : 'prod';
// }

// axios.defaults.host = 'http://localhost:8080';
// axios.defaults.adapter = require('axios/lib/adapters/xhr');
window.Promise = Promise; // overwrite native Promise implementation with Bluebird's (for axios)

function wrapRequest(req) {
  const accessToken = sessionStorage.getItem('accessToken');

  req.headers = {
    Authorization: `Bearer ${accessToken}`,
    Accept: 'application/json'
  };

  return req;
}

// eslint-disable-next-line import/prefer-default-export
export const actionCreate = apiNameSnake => {
  const {
    apiNameCamel,
    capitalizedNameCamel,
    capitalizedNameSnakePlural,
    capitalizedNameSnake,
    apiNameCamelPlural
  } = require('../_utility/getVariousNames')(apiNameSnake);

  // const idFieldName = `${apiNameCamel}Id`;
  const idFieldName = `id`;

  const getAllResourcesSuccess = data => ({
    type: `LOAD_${capitalizedNameSnakePlural}_SUCCESS`,
    [`${apiNameCamelPlural}`]: data
  });

  const getResourceSuccess = data => ({
    type: `LOAD_${capitalizedNameSnake}_SUCCESS`,
    [`${apiNameCamel}`]: data
  });

  const createResourceSuccess = data => ({
    type: `CREATE_${capitalizedNameSnake}_SUCCESS`,
    [`${apiNameCamel}`]: data
  });

  const createNestedResourceSuccess = (data, childName, parentId, childId) => ({
    type: `CREATE_${capitalizedNameSnake}_NESTED_SUCCESS`,
    [`${apiNameCamel}`]: {
      data,
      childName,
      parentId,
      childId
    }
  });

  const updateResourceSuccess = data => ({
    type: `UPDATE_${capitalizedNameSnake}_SUCCESS`,
    [`${apiNameCamel}`]: data
  });

  const updateNestedResourceSuccess = (data, childName, parentId, childId) => ({
    type: `UPDATE_${capitalizedNameSnake}_NESTED_SUCCESS`,
    [`${apiNameCamel}`]: {
      data,
      childName,
      parentId,
      childId
    }
  });

  const deleteResourceSuccess = data => ({
    type: `DELETE_${capitalizedNameSnake}_SUCCESS`,
    [`${apiNameCamel}`]: data
  });

  const deleteNestedResourceSuccess = (data, childName, parentId, childId) => ({
    type: `DELETE_${capitalizedNameSnake}_NESTED_SUCCESS`,
    [`${apiNameCamel}`]: {
      data,
      childName,
      parentId,
      childId
    }
  });

  const deleteResourcesSuccess = query => ({
    type: `DELETE_${capitalizedNameSnakePlural}_SUCCESS`,
    query
  });

  return {
    [`${capitalizedNameCamel}Actions`]: {
      getResourceSuccess,
      getAllResourcesSuccess,
      createResourceSuccess,
      updateResourceSuccess,
      updateNestedResourceSuccess,
      deleteResourceSuccess,
      deleteResourcesSuccess,

      getAll(params) {
        const newReq = wrapRequest({
          method: 'GET',
          url: `${BASE_URL}/${apiNameCamelPlural}`,
          params
        });

        return dispatch =>
          new axios(newReq)
            .then(res => {
              dispatch(getAllResourcesSuccess(res.data)); // Need fromJS()
              return res.data;
            })
            .catch((error, res) => {
              message.error(error);
              // throw error;
            });

        // if (acceptHeader === 'application/octet-stream') {
        //   axiosGetAllParams.responseType = 'arraybuffer';
        // }

        // if (params.limit > 0) {
        //   axiosGetAllParams.params.limit = params.limit;
        // }

        // ['orderBy', 'orderByOrder', 'ref'].forEach(i => {
        //   if (params[i] !== undefined) {
        //     axiosGetAllParams.params[i] = params[i];
        //   }
        // });
      },

      get(record, params = {}) {
        console.log('🔥🔥idFieldName🔥🔥');
        console.log(idFieldName);
        console.log('🔥🔥record🔥🔥');
        console.log(record.get(idFieldName));
        const dataId = isImmutable(record)
          ? record.get(idFieldName)
          : record[idFieldName] !== undefined
          ? record[idFieldName]
          : record.id;

        const newReq = wrapRequest({
          method: 'GET',
          url: `${BASE_URL}/${apiNameCamelPlural}/${dataId}`,
          params
        });

        return (dispatch, getState) =>
          new axios(newReq)
            .then(res => {
              dispatch(getResourceSuccess(res.data)); // Need fromJS()
              message.success(`${apiNameCamel} loaded successfully`);
              return res.data;
            })
            .catch(error => {
              message.error('Error', error);
              // throw error;
            });
      },

      create(record) {
        const newReq = wrapRequest({
          method: 'post',
          url: `${BASE_URL}/${apiNameCamelPlural}`,
          data: record
        });

        return (dispatch, getState) =>
          new axios(newReq)
            .then(res => {
              dispatch(createResourceSuccess(res.data));
              message.success(`${apiNameCamel} created successfully`);
              return res.data;
            })
            .catch(error => {
              message.error(error);
              // throw error;
            });
      },

      createNested(childName, parentId, record, childIdKey) {
        const newReq = wrapRequest({
          method: 'post',
          url: `${BASE_URL}/${apiNameCamelPlural}/${parentId}/${childName}`,
          data: record
        });

        return (dispatch, getState) =>
          new axios(newReq)
            .then(res => {
              dispatch(
                createNestedResourceSuccess(
                  res.data,
                  childName,
                  parentId,
                  res.data[childIdKey]
                )
              );
              message.success(`${apiNameCamel} updated successfully`);
              return res.data;
            })
            .catch(error => {
              message.error('Error', error);
              // throw error;
            });
      },

      update(record, params = {}) {
        const dataId = isImmutable(record)
          ? record.get(idFieldName)
          : record[idFieldName] !== undefined
          ? record[idFieldName]
          : record.id;

        const newReq = wrapRequest({
          method: 'put',
          url: `${BASE_URL}/${apiNameCamelPlural}/${dataId}`,
          data: record,
          params
        });

        return (dispatch, getState) =>
          new axios(newReq)
            .then(res => {
              // if (UPDATE_VIA_SOCKET.indexOf(apiNameCamel) === -1) {
              //   // FIXME: the device the started will be updated twice
              //   // But if you only wait for socketio it will be slow
              dispatch(updateResourceSuccess(res.data));
              message.success(`${apiNameCamel} updated successfully`);
              // }
              return res.data;
            })
            .catch(error => {
              message.error('Error', error);
              // throw error;
            });
      },

      updateNested(childName, parentId, record, childId, params = {}) {
        const newReq = wrapRequest({
          method: 'put',
          url: `${BASE_URL}/${apiNameCamelPlural}/${parentId}/${childName}/${childId}`,
          data: record,
          dataId: childId,
          params
        });

        return (dispatch, getState) =>
          new axios(newReq)
            .then(res => {
              // Res is subdocument
              dispatch(
                updateNestedResourceSuccess(
                  res.data,
                  childName,
                  parentId,
                  childId
                )
              );
              message.success(
                `${apiNameCamel} updated successfully for child ${childName} id ${record.id}`
              );
              // }
              return res.data;
            })
            .catch(error => {
              message.error('Error', error);
              // throw error;
            });
      },

      deleteRecord(record) {
        // delete is reserved
        const dataId = isImmutable(record)
          ? record.get(idFieldName)
          : record[idFieldName] !== undefined
          ? record[idFieldName]
          : record.id;

        const newReq = wrapRequest({
          method: 'delete',
          url: `${BASE_URL}/${apiNameCamelPlural}/${dataId}`,
          data: record
        });

        return (dispatch, getState) =>
          new axios(newReq)
            .then(() => {
              dispatch(deleteResourceSuccess(record));
              message.success(`${apiNameCamel} deleted successfully`);
              return record;
            })
            .catch(error => {
              message.error('Error', error);
              // throw error;
            });
      },

      deleteNestedRecord(childName, parentId, record, childId) {
        const newReq = wrapRequest({
          method: 'delete',
          url: `${BASE_URL}/${apiNameCamelPlural}/${parentId}/${childName}/${childId}`,
          data: record
        });

        return (dispatch, getState) =>
          new axios(newReq)
            .then(res => {
              // Res is subdocument
              dispatch(
                deleteNestedResourceSuccess(
                  res.data,
                  childName,
                  parentId,
                  childId
                )
              );

              message.success(
                `${apiNameCamel} deleted successfully for child ${childName} id ${record.id}`
              );
              // }
              return res.data;
            })
            .catch(error => {
              message.error('Error', error);
              // throw error;
            });
      }

      // deleteRecords(query) {
      //   return (dispatch, getState) =>
      //     new BaseApi()
      //       .deleteMany(query)
      //       .then(() => {
      //         dispatch(deleteResourcesSuccess(query));
      //         message.success(`${apiNameCamel}s deleted successfully`);
      //         // No return data
      //       })
      //       .catch(error => {
      //         toastr.error('Error', error);
      //         // throw error;
      //       });
      // }
    }
  };
};
