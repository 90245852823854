import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, List } from 'antd';

import moment from 'moment';

import { selectedDailyRecordSelector } from 'redux/modules/dailyRecords';
import { hourblockDividerConstants } from 'views/constants/hourblockDividerConstants.js';
import HourblockItem from './item/HourblockItem';

export default ({ selectedDate }) => {
  const [hourblocks, setHourblocks] = useState([]);
  const [curentSectionOfDay, setCurentSectionOfDay] = useState(-1);

  const updateCurrentSection = () => {
    const newCurentSectionOfDay =
      moment().hour() * 2 + parseInt(moment().minute() / 30, 10); // 1-indexed

    setCurentSectionOfDay(newCurentSectionOfDay);

    // Return the value for componentDidMount to use as it is not in the state yet
    return curentSectionOfDay;
  };

  const selectedDailyRecord = useSelector(state =>
    selectedDailyRecordSelector(state)
  );

  useEffect(() => {
    const newHourblocks = [];
    for (let i = 0; i < 48; i += 1) {
      newHourblocks.push({
        index: i,
        time: `${i >= 20 ? '' : '0'}${parseInt(i / 2, 10)}:${
          i % 2 === 0 ? '00' : '30'
        }`
      });
    }

    const updateTimeRunner = setInterval(_ => {
      updateCurrentSection();
    }, 1000 * 60 * 2); // 2 minutes the ui update for

    setHourblocks(newHourblocks);

    updateCurrentSection();
    //   this.scrollToCurrent(curentSectionOfDay);
  }, []);

  // componentWillUnmount() {
  //   clearInterval(this.updateTimeRunner);
  // }

  return (
    <Card
      size="small"
      id="hourblockList"
      style={{
        width: '100%'
      }}
      bodyStyle={{
        padding: 0
      }}
    >
      {selectedDailyRecord !== undefined ? (
        <List
          size="small"
          style={{
            overflow: 'hidden'
          }}
        >
          {hourblocks.map(block => (
            <List.Item key={block.index}>
              <HourblockItem
                block={block}
                curentSectionOfDay={curentSectionOfDay}
                selectedDailyRecord={selectedDailyRecord}
              />

              {/* Space out for important sectors of day */}
              {hourblockDividerConstants
                .map(a => a.index)
                .indexOf(block.index) > -1 && (
                <React.Fragment>
                  <div
                    style={{
                      height: 100,
                      paddingTop: '25px',
                      textAlign: 'center',
                      background: 'whitesmoke'
                    }}
                  >
                    <div
                      style={{
                        height: 30,
                        lineHeight: '30px',
                        fontSize: 18
                      }}
                    >
                      {
                        hourblockDividerConstants.find(
                          a => a.index === block.index
                        ).message
                      }
                    </div>
                    <div
                      style={{
                        height: 20,
                        lineHeight: '20px',
                        fontSize: 14
                      }}
                    >
                      {
                        hourblockDividerConstants.find(
                          a => a.index === block.index
                        ).reminder
                      }
                    </div>
                  </div>
                </React.Fragment>
              )}
            </List.Item>
          ))}
        </List>
      ) : (
        <h2>
          {selectedDate > moment().format('YYYY-MM-DD')
            ? 'Please create new record'
            : 'Unable to show old record'}
        </h2>
      )}
    </Card>
  );
};
