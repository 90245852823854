import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { UiActions } from 'redux/modules/ui';
import MATERIAL_COLORS from 'views/styles/colors';
import { selectedDiarySelector } from 'redux/modules/diaries';

const useStyles = makeStyles(() => ({
  box: {},
  item: {
    height: 20,
    borderBottom: `1px solid ${MATERIAL_COLORS.LighterGray}`,
    display: 'block',
    overflow: 'hidden'
  },
  active: {
    background: MATERIAL_COLORS.Primary
  },
  image: {
    width: '100%',
    minHeight: 200,
    marginBottom: 20,
    background: 'whitesmoke',
    border: '1px solid black'
  }
}));

export default () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const diaries = useSelector(state => state.diaries);
  const selectedDiaryDate = useSelector(state =>
    state.ui.getIn(['diary', 'selectedDiaryDate'])
  );

  const selectedDiary = useSelector(state => selectedDiarySelector(state));

  const [past30Days, setPast30Days] = useState([]);

  useEffect(() => {
    let past30Days = [];
    for (var i = 0; i <= 30; i++) {
      past30Days.push(
        moment()
          .add(-i, 'days')
          .format('YYYY-MM-DD')
      );
    }

    setPast30Days(past30Days);

    // Default set today as the
    dispatch(
      UiActions.updateIn(
        ['diary', 'selectedDiaryDate'],
        moment().format('YYYY-MM-DD')
      )
    );
  }, []);

  return (
    <Box display="flex" flexWrap="wrap" alignContent="flex-start">
      <img className={classes.image} src={selectedDiary.get('imageUrl')} />

      <Box
        flexGrow={1}
        p={1}
        style={{
          backgroundColor: 'whitesmoke'
        }}
      >
        {past30Days.map((date, index) => (
          <div
            key={index}
            className={clsx(
              selectedDiaryDate === date ? classes.active : '',
              classes.item
            )}
            onClick={() =>
              dispatch(UiActions.updateIn(['diary', 'selectedDiaryDate'], date))
            }
          >
            <span>{date} - </span>

            <span
              style={{
                maxWidth: '60%',
                height: 20,
                overflow: 'hidden'
              }}
            >
              {diaries
                .valueSeq()
                .filter(rec => rec.get('date') === date)
                .count()
                ? diaries
                    .valueSeq()
                    .find(rec => rec.get('date') === date)
                    .get('title')
                : '-'}
            </span>
          </div>
        ))}
      </Box>
    </Box>
  );
};
