import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

// import GoogleMapReact from 'google-map-react';

// const AnyReactComponent = ({ text }) => <div>{text}</div>;
// const { google } = window;

const styles = theme => ({});

class LocationMap extends Component {
  state = {
    center: {
      lat: 59.95,
      lng: 30.33
    },
    zoom: 10
  };

  componentDidMount() {
    // this.getLocation();
  }

  getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.setState({
          center: {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          }
        });
      });
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  };

  render() {
    const { center, zoom } = this.state;

    return (
      <div style={{ height: 360, width: '100%' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => this.getLocation()}
        >
          Update Position
        </Button>

        <span>
          lat {center.lat}, lng: {center.lng}, zoom {zoom}
        </span>

        {/* <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyDB0J39hZOdgTjeR9JNtWsJr7kb9U0VFLY' }}
          center={center}
          zoom={zoom}
          defaultCenter={center}
          defaultZoom={zoom}
          // defaultZoom={zoom}
          yesIWantToUseGoogleMapApiInternals
          onZoomChanged={event => {

            // this.setState({
            //   zoom: event.target.value
            // });
          }}
        >
          <AnyReactComponent lat={center.lat} lng={center.lng} text="Marker" />
        </GoogleMapReact> */}
        <div
          id="map"
          style={{
            height: 300
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(LocationMap));
