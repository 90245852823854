import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
// import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import AnkiList from './ankiList';
import AnkiNew from './ankiNew';
import AnkiCard from './ankiCard';

import {
  AnkiStackActions,
  getLearnableSelectedAnkiListSelector,
  getLearningAnkisListSelector
} from '../../redux/modules/ankiStacks';

// function ListItemLink(props) {
// 	return <ListItem button component="a" {...props} />;
// }

const styles = () => ({
  paper: {
    maxWidth: '100vw',
    margin: 'auto',
    overflow: 'hidden'
  },
  card: {
    maxWidth: '100vw',
    margin: 'auto',
    overflow: 'hidden'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  textField: {
    marginLeft: 10,
    marginRight: 10,
    width: 200
  }
});

class Anki extends React.Component {
  state = {
    selectedAnkiFilter: '0'
  };

  render() {
    const { classes, learnableSelectedAnkisList } = this.props;

    // const handleChange = name => event => {
    //   this.setState({
    //     [name]: event.target.value
    //   });
    // };

    const { selectedAnkiFilter } = this.state;
    // let filteredlearnableAnkis = [];
    // if (learnableSelectedAnkisList.length) {
    //   filteredlearnableAnkis =
    //     learnableSelectedAnkisList[parseInt(selectedAnkiFilter, 10)];
    // }

    return (
      <React.Fragment>
        <ToggleButtonGroup
          value={selectedAnkiFilter}
          onChange={(event, value) => {
            // value = [old value, new value]
            if (value.length) {
              this.setState({
                selectedAnkiFilter: value[1]
              });
            }
          }}
        >
          <ToggleButton value="0">
            Unlearnt
            <Chip
              color={
                learnableSelectedAnkisList[0].length > 50
                  ? 'secondary'
                  : 'primary'
              }
              size="small"
              label={learnableSelectedAnkisList[0].length}
            />
          </ToggleButton>

          <ToggleButton value="1">
            Future
            <Chip
              color={
                learnableSelectedAnkisList[1].length > 50
                  ? 'secondary'
                  : 'primary'
              }
              size="small"
              label={learnableSelectedAnkisList[1].length}
            />
          </ToggleButton>
        </ToggleButtonGroup>
        <br />
        <br />
        <AnkiNew />
        <br />
        <AnkiCard />
        <br />
        <Paper
          className={classes.paper}
          style={{
            maxHeight: 'calc(100vh - 500px)',
            overflow: 'auto',
            marginBottom: 20
          }}
        >
          <AnkiList />
        </Paper>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    selectedAnkiStack: state.ui.getIn(['anki', 'selectedAnkiStack']),
    learnableSelectedAnkisList: getLearnableSelectedAnkiListSelector(state),
    learningAnkisList: getLearningAnkisListSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    AnkiStackActions: bindActionCreators(AnkiStackActions, dispatch)
  };
}

Anki.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Anki));
