import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

import { isImmutable } from 'immutable';

import { UiActions } from 'redux/modules/ui';

import { Tag, Input } from 'antd';

export default ({
  block,
  selectedPlannedPomo,
  updateDailyPlannedHourblock
}) => {
  // const categoriesByProject = useSelector(state =>
  //   categoriesByProjectSelector(state)
  // );
  const isMobile = useSelector(state => state.ui.getIn(['common', 'isMobile']));

  const useStyles = makeStyles(() => ({
    row: props => ({
      borderBottom: '1px solid #ecf0f1',
      display: 'flex',
      height: isMobile ? 60 : 30,
      background: props.isMainTaskLocked ? '#95a5a6' : 'white'
    }),
    mainTaskLabel: {
      background: 'whitesmoke',
      borderRadius: 2,
      height: 25,
      lineHeight: '25px',
      textAlign: 'center',
      width: 60
    },
    mainTaskContent: {
      marginLeft: 5,
      flex: 1,
      height: 25,
      color: 'black',
      width: 'calc(100%)',
      display: 'inline-block'
    },
    select: {
      width: 100,
      fontSize: 12
    }
  }));

  const isShowingRecommendedTask = useSelector(state =>
    state.ui.getIn(['hourblock', 'isShowingRecommendedTask'])
  );

  const getPlannedPomoProjectId = () => {
    return isImmutable(selectedPlannedPomo) &&
      selectedPlannedPomo.has('projectId')
      ? selectedPlannedPomo.get('projectId')
      : '';
  };

  const getPlannedPomo = () => {
    return isImmutable(selectedPlannedPomo) &&
      selectedPlannedPomo.hasIn(['category'])
      ? selectedPlannedPomo.getIn(['category'])
      : '';
  };

  const isMainTaskLocked =
    (isImmutable(selectedPlannedPomo) &&
      selectedPlannedPomo.has('isMainTaskLocked') &&
      selectedPlannedPomo.get('isMainTaskLocked')) ||
    false;

  const classes = useStyles({ isMainTaskLocked });
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);

  return (
    <div className={classes.row}>
      {/* <span className={classes.mainTaskLabel}>Main</span> */}

      {editMode && !isMainTaskLocked ? (
        <Input
          style={{
            margin: '0 0 0 5px',
            flex: 1,
            height: 25
          }}
          // placeholder={selectedPlannedPomo.getIn(['tasks', 'main'])}
          defaultValue={
            selectedPlannedPomo && selectedPlannedPomo.get('taskMain')
          }
          onKeyDown={event => {
            if (event.which === 13) {
              const newRec = selectedPlannedPomo.set(
                'taskMain',
                event.target.value
              );

              updateDailyPlannedHourblock('isMainTaskLocked', true, newRec);
              setEditMode(false);
            }
          }}
        />
      ) : (
        <>
          <div
            role="button"
            tabIndex={0}
            className={classes.mainTaskContent}
            onClick={() => {
              setEditMode(true);

              if (isShowingRecommendedTask) {
                // Also update the current
                dispatch(
                  UiActions.updateIn(
                    ['hourblock', 'selectedProjectId'],
                    getPlannedPomoProjectId()
                  )
                );
              }

              // Also update the current
              dispatch(
                UiActions.updateIn(
                  ['hourblock', 'selectedCategory'],
                  getPlannedPomo(block)
                )
              );
            }}
          >
            {`${
              isImmutable(selectedPlannedPomo) &&
              selectedPlannedPomo.has('taskMain') &&
              selectedPlannedPomo.get('taskMain') !== null
                ? `${selectedPlannedPomo.get('taskMain')}`
                : ''
            }`}
          </div>
          <br />

          {isImmutable(selectedPlannedPomo) &&
          selectedPlannedPomo.has('taskRecur') &&
          selectedPlannedPomo.get('taskRecur') !== null &&
          selectedPlannedPomo.get('taskRecur').length > 0 ? (
            <Tag>{selectedPlannedPomo.get('taskRecur')} </Tag>
          ) : (
            ''
          )}
        </>
      )}
    </div>
  );
};
