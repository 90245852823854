import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { DailyTaskActions } from 'redux/modules/dailyTasks';
import PriorityIcon from 'views/components/atoms/PriorityIcon';
import { MonthlyTaskActions } from 'redux/modules/monthlyTasks';
import MATERIAL_COLORS from 'views/styles/colors';

import DailyTaskRightClickBox from 'views/3-Task/calendar/DailyTaskRightClickBox';

import { Tag, Dropdown, Checkbox, Input } from 'antd';
import { YearlyTaskActions } from 'redux/modules/yearlyTasks';
import { WeeklyTaskActions } from 'redux/modules/weeklyTasks';
import { categoriesByProjectSelector } from 'redux/modules/categories';
import WeeklyTaskRightClickBox from 'views/3-Task/calendar/WeeklyTaskRightClickBox';
import MonthlyTaskRightClickBox from 'views/3-Task/calendar/MonthlyTaskRightClickBox';
import YearlyTaskRightClickBox from 'views/3-Task/calendar/YearlyTaskRightClickBox';

const useStyles = makeStyles({
  row: props => ({
    paddingLeft: 3,
    height: 25,
    lineHeight: '25px',
    display: 'flex',
    flex: 1,
    margin: '2px 0',
    textDecoration: props.record.get('isCompleted') ? 'line-through' : 'none'
  }),
  category: {
    width: 30,
    height: 20,
    margin: '2px 0',
    background: 'gray',
    marginRight: 5
  },
  pomoCount: {
    width: 20,
    textAlign: 'center',
    backgroundColor: MATERIAL_COLORS.LightBlue
  },
  noPomoCount: {
    width: 20,
    textAlign: 'center',
    backgroundColor: MATERIAL_COLORS.LightGray
  },
  taskName: props => ({
    flex: 1,
    height: 25,
    // lineHeight: 25,
    color: 'black',
    wordBreak: 'break-all',
    overflow: 'hidden'
  })
});

export default ({ record, index, type }) => {
  const dispatch = useDispatch();

  const [isEditing, setIsEditing] = useState(false);

  const TaskActions =
    type === 'daily'
      ? DailyTaskActions
      : type === 'yearly'
      ? YearlyTaskActions
      : type === 'weekly'
      ? WeeklyTaskActions
      : MonthlyTaskActions;

  const classes = useStyles({ record });

  const selectedProjectFromFilter = useSelector(state =>
    state.ui.getIn(['task', 'selectedProject'])
  );

  const isShowingRecommendedTask = useSelector(state =>
    state.ui.getIn(['hourblock', 'isShowingRecommendedTask'])
  );

  const hourblockSelectedProjectId = useSelector(state =>
    state.ui.getIn(['hourblock', 'selectedProjectId'])
  );

  const updateRecordIsCompleted = event => {
    const newRec = record.set('isCompleted', event.target.checked);

    dispatch(TaskActions.create(newRec));
  };

  const updateRecordTask = value => {
    const newRec = record.set('name', value);

    dispatch(TaskActions.create(newRec)).then(() => {
      setIsEditing(false);
    });
  };

  const categoriesByProject = useSelector(state =>
    categoriesByProjectSelector(state)
  );

  // const projects = useSelector(state => state.projects);

  // const deleteTask = task => {
  //   if (window.confirm(`Deleting task: ${task.name}`)) {
  //     if (usingHook) {
  //       dispatch(taskActions.deleteRecord(task));
  //     } else {
  //       taskActions.deleteRecord(task);
  //     }
  //   }
  // };

  // const getProject = () => {
  //   const projectId = record.get('projectId');

  //   if (projectId === null || categoriesByProject[projectId] === undefined) {
  //     return {};
  //   }

  //   const selectedProject = categoriesByProject[projectId];

  //   return selectedProject;
  // };

  const getCategory = () => {
    const projectId = record.get('projectId');

    if (projectId === null || categoriesByProject[projectId] === undefined) {
      return {};
    }

    const selectedCategory = categoriesByProject[projectId].category;

    return selectedCategory;
  };

  const getBackgroundColor = () => {
    // If future day use light gray
    // if (record.get('targetCompletionDate') > moment().format('YYYY-MM-DD')) {
    //   return MATERIAL_COLORS.LightGray;
    // }

    if (
      isShowingRecommendedTask &&
      hourblockSelectedProjectId === record.get('projectId')
    ) {
      return MATERIAL_COLORS.LightBlue;
    }

    // Selected project will be highlighted in blue
    if (selectedProjectFromFilter === record.get('projectId')) {
      return MATERIAL_COLORS.LightBlue;
    }

    if (
      record.get('targetCompletionDate') === moment().format('YYYY-MM-DD') &&
      record.get('isKeyTask')
    ) {
      return MATERIAL_COLORS.PrimaryLight;
    }

    // if (record.get('isKeyTask')) {
    //   return MATERIAL_COLORS.Primary;
    // }

    // let isCurrentRecord = false;

    // switch (type) {
    //   case 'daily':
    //     isCurrentRecord =
    //       record.get('targetCompletionDate') === moment().format('YYYY-MM-DD');
    //     break;

    //   case 'weekly':
    //     isCurrentRecord =
    //       parseInt(record.get('targetCompletionWeek').split('W')[1], 10) ===
    //       moment().isoWeek();
    //     break;

    //   case 'monthly':
    //     isCurrentRecord =
    //       record.get('targetCompletionMonth') === moment().format('YYYY-MM');
    //     break;

    //   case 'yearly':
    //     isCurrentRecord =
    //       record.get('targetCompletionYear') === moment().year();
    //     break;

    //   default:
    //     break;
    // }

    // return isCurrentRecord ? MATERIAL_COLORS.Primary : 'white';
    return 'white';
  };

  const getDisplayCompletionDate = () => {
    if (record.get('targetCompletionDate') === null) {
      return '';
    }

    switch (type) {
      case 'daily':
        return moment(record.get('targetCompletionDate')).format('MM-DD');

      case 'weekly':
        return record.get('targetCompletionWeek');

      case 'monthly':
        return moment(record.get('targetCompletionMonth')).format('YYYY-MM');

      default:
        return '';
    }
  };

  const getRightClickBox = () => {
    if (type === 'daily') return <DailyTaskRightClickBox dailyTask={record} />;
    if (type === 'weekly') return <WeeklyTaskRightClickBox task={record} />;
    if (type === 'monthly') return <MonthlyTaskRightClickBox task={record} />;
    if (type === 'yearly') return <YearlyTaskRightClickBox task={record} />;
  };

  return (
    <Dropdown overlay={getRightClickBox()} trigger={['contextMenu']}>
      <div
        className={classes.row}
        style={{
          backgroundColor: getBackgroundColor()
        }}
      >
        {type === 'daily' && <PriorityIcon task={record} />}

        <Checkbox
          // className={classes.checkbox}
          checked={
            record.has('isCompleted') &&
            record.get('isCompleted') !== null &&
            record.get('isCompleted')
          }
          onChange={updateRecordIsCompleted}
          color="primary"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />

        <span
          className={classes.category}
          style={{
            background: `#${getCategory().color}`,
            color: 'white',
            width: 40,
            overflow: 'hidden',
            fontSize: 12,
            textAlign: 'center'
          }}
        >
          {/* {getProject().category.name} */}
          {getCategory().name}
        </span>

        {!isEditing ? (
          <span
            role="button"
            tabIndex={0}
            className={classes.taskName}
            onClick={() => {
              setIsEditing(true);
            }}
          >
            {record.get('name')}
          </span>
        ) : (
          <Input
            style={{
              padding: 0,
              width: '100%',
              borderBottom: '1px black solid'
            }}
            bordered={false}
            id="item"
            defaultValue={record.get('name')}
            onKeyDown={event => {
              if (event.which === 13) {
                updateRecordTask(event.target.value);
              }
            }}
          />
        )}
        {/*
        <span>
          {getDisplayCompletionDate()}

        <IconButton
          variant="contained"
          size="small"
          onClick={() => deleteTask(record)}
        >
          <DeleteIcon color="secondary" fontSize="small" />
        </IconButton>
        </span> */}

        {/* <span
        className={
          record.get('pomoCount') > 0 ? classes.pomoCount : classes.noPomoCount
        }
      >
        {` ${record.get('pomoCount')}`}
      </span> */}

        <Tag style={{ right: '-10px', position: 'relative' }}>
          {getDisplayCompletionDate()}
          {/* {record.get('targetCompletionPomo')} */}
        </Tag>
      </div>
    </Dropdown>
  );
};
