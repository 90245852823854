import moment from 'moment';
import { createSelector } from 'reselect';
import { actionCreate } from '../_base/actionCreate';
import { reducerCreate } from '../_base/reducerCreate';

export default reducerCreate('weekly_task');
export const { WeeklyTaskActions } = actionCreate('weekly_task');

const getWeeklyTasks = state => state.weeklyTasks;
export const weeklyTasksListSelector = createSelector(
  [getWeeklyTasks],
  weeklyTasks => {
    return weeklyTasks;
  }
);

export const dueWeeklyTasksListSelector = createSelector(
  [getWeeklyTasks],
  weeklyTasks => {
    return weeklyTasks
      .valueSeq()
      .sort((a, b) => {
        if (a.get('targetCompletionWeek') !== b.get('targetCompletionWeek')) {
          return a.get('targetCompletionWeek') > b.get('targetCompletionWeek')
            ? 1
            : -1;
        }

        return a.get('projectId') > b.get('projectId') ? 1 : -1;
      })
      .filter(rec => {
        const yearNumber = parseInt(
          rec.get('targetCompletionWeek').split('W')[0],
          10
        );
        const weekNumber = parseInt(
          rec.get('targetCompletionWeek').split('W')[1],
          10
        );
        return (
          yearNumber < moment().year() ||
          (yearNumber === moment().year() && weekNumber <= moment().isoWeek())
        );
      });
  }
);
