import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import IconButton from '@material-ui/core/IconButton';
import { DailyTaskActions } from 'redux/modules/dailyTasks';
import { userSettingsMapperSelector } from 'redux/modules/user';
import userSettingConstants from 'views/constants/userSettingConstants';

const useStyles = makeStyles(() => ({
  button: {
    width: 10,
    padding: 0
  },
  icon: props => ({
    color: `${props.isKeyTask ? 'red' : 'gray'}`
  })
}));

export default ({ task }) => {
  const dispatch = useDispatch();
  const classes = useStyles({ isKeyTask: task.get('isKeyTask') });

  const dailyTasks = useSelector(state => state.dailyTasks);

  const getKeyDailyTasksOfDayCount = () => {
    const res = dailyTasks
      .valueSeq()
      .filter(
        a =>
          a.get('targetCompletionDate') === task.get('targetCompletionDate') &&
          a.get('isKeyTask')
      )
      .count();

    return res;
  };

  const currentUserSettings = useSelector(state =>
    userSettingsMapperSelector(state)
  );

  const updatePriority = () => {
    // If you are setting key task, you should not exceed user defined limits
    if (
      !task.get('isKeyTask') &&
      getKeyDailyTasksOfDayCount() >=
        currentUserSettings[userSettingConstants.maxDailyKeyTasks]
    ) {
      alert(
        `Exceeding max task count for the day ${task.get(
          'targetCompletionDate'
        )}`
      );
      return;
    }

    const newSubtasks = task.set('isKeyTask', !task.get('isKeyTask'));

    dispatch(DailyTaskActions.create(newSubtasks));
  };

  return (
    <IconButton
      variant="contained"
      size="small"
      onClick={() => updatePriority()}
      className={classes.button}
    >
      <PriorityHighIcon className={classes.icon} />
    </IconButton>
  );
};
