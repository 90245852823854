import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import DateFnsUtils from '@date-io/date-fns';

import { CategoryActions } from 'redux/modules/categories';

import { DailyTaskActions } from 'redux/modules/dailyTasks';
import { MonthlyTaskActions } from 'redux/modules/monthlyTasks';
import { YearlyTaskActions } from 'redux/modules/yearlyTasks';
import { YearlySubTaskActions } from 'redux/modules/yearlySubTasks';

import { DailyRecordActions } from 'redux/modules/dailyRecords';
import { DailyRecordSummaryActions } from 'redux/modules/dailyRecordSummaries';
import { WeeklyTaskActions } from 'redux/modules/weeklyTasks';
import { DailyPlannedHourblockActions } from 'redux/modules/dailyPlannedHourblocks';
import { WorkoutActions } from 'redux/modules/workouts';
import { WorkoutRecordActions } from 'redux/modules/workoutRecords';
import { HabitActions } from 'redux/modules/habits';
import { DiaryActions } from 'redux/modules/diaries';
import { UiActions } from 'redux/modules/ui';
import { UserActions } from 'redux/modules/user';

import Login from 'views/Login';
import TaskModal from 'views/TaskModal';

// import '@ant-design/pro-form/dist/form.css';
// import '@ant-design/pro-table/dist/table.css';
import 'antd-mobile/dist/antd-mobile.css';
import '@ant-design/pro-card/dist/card.css';
import '@ant-design/pro-layout/dist/layout.css';

import './App.css';
import MainApp from './views';

export default () => {
  const dispatch = useDispatch();

  const isLoggedIn = useSelector(state =>
    state.ui.getIn(['common', 'isLoggedIn'])
  );

  const showTaskModal = useSelector(state =>
    state.ui.getIn(['common', 'showTaskModal'])
  );
  // const [selectedTab, setSelectedTab] = useState(history);

  useEffect(() => {
    const newIsMobile =
      Math.min(document.documentElement.clientWidth, window.screen.width) <=
      650;

    // P9 is 424
    console.info(
      `Document clientWidth is ${document.documentElement.clientWidth}, screen width is ${window.screen.width}, isMobile is ${newIsMobile}`
    );

    dispatch(UiActions.updateIn(['common', 'isMobile'], newIsMobile));

    if (isLoggedIn) {
      dispatch(CategoryActions.getAll());
      dispatch(DailyTaskActions.getAll());
      dispatch(MonthlyTaskActions.getAll());
      dispatch(WeeklyTaskActions.getAll());
      dispatch(YearlyTaskActions.getAll());
      // dispatch(YearlySubTaskActions.getAll());
      dispatch(DailyRecordActions.getAll());
      dispatch(DailyRecordSummaryActions.getAll());
      dispatch(DailyPlannedHourblockActions.getAll());
      // dispatch(HabitActions.getAll());
      // dispatch(DiaryActions.getAll());

      dispatch(WorkoutActions.getAll());
      dispatch(WorkoutRecordActions.getAll());

      dispatch(UserActions.getCurrentUser());
    }
  }, [dispatch, isLoggedIn]);

  return (
    <>
      {showTaskModal && <TaskModal />}
      {sessionStorage.getItem('accessToken') === null ? <Login /> : <MainApp />}
    </>
  );
};
