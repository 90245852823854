import { fromJS, isImmutable } from 'immutable';
import moment from 'moment';
import axios from 'axios';
import BASE_URL from 'views/constants/urlConstants';
import { createSelector } from 'reselect';
import { message } from 'antd';

import { actionCreate } from '../_base/actionCreate';
import { reducerCreate } from '../_base/reducerCreate';
import { categoriesByProjectSelector } from './categories';

export default reducerCreate('daily_planned_hourblock');
export const { DailyPlannedHourblockActions } = actionCreate(
  'daily_planned_hourblock'
);

DailyPlannedHourblockActions.updateCalendarFile = () => {
  const accessToken = sessionStorage.getItem('accessToken');

  return dispatch =>
    axios({
      method: 'POST',
      url: `${BASE_URL}/internal/dailyPlannedHourblocks/calendar`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json'
      }
    })
      .then(res => {
        message.success(`Successful updating calendar, result is ${res.data}`);
      })
      .catch(error => {
        console.log('Error is', error);
        message.error('Unable to login user');
      });
};

const getDailyPlannedHourblocks = state => state.dailyPlannedHourblocks;
const getSelectedDate = state => state.ui.getIn(['hourblock', 'selectedDate']);

const currentBlock = {
  // Default to current
  index: moment().hour() * 2 + moment().minute() >= 30 ? 1 : 0
};
const getTotalBlockIndex = (state, block = currentBlock) => {
  return block.index;
};

export const dailyPlannedHourblockBySectionOfWeekSelector = createSelector(
  [getDailyPlannedHourblocks],
  dailyPlannedHourblocks => {
    const res = {};

    if (isImmutable(dailyPlannedHourblocks)) {
      const recsOriginal = dailyPlannedHourblocks.toJS();
      Object.values(recsOriginal).forEach(rec => {
        res[rec.sectionOfWeek] = rec;
      });
    }

    return fromJS(res);
  }
);

export const selectedSectionSelector = createSelector(
  [getSelectedDate, getTotalBlockIndex],
  (selectedDate, ind) => {
    return (moment(selectedDate).isoWeekday() - 1) * 48 + ind;
  }
);

export const selectedDailyPlannedHourblockSelector = createSelector(
  [
    dailyPlannedHourblockBySectionOfWeekSelector,
    getSelectedDate,
    getTotalBlockIndex
  ],
  (dailyPlannedHourblocksBySectionOfWeek, selectedDate, ind) => {
    const blockIndex = (moment(selectedDate).isoWeekday() - 1) * 48 + ind;

    const res = dailyPlannedHourblocksBySectionOfWeek.get(
      blockIndex.toString()
    ); // Sunday = 7 for isoWeekday
    return res;
  }
);

/**
 * Metrics for current planned hourblock
 * Value is: [numberOfTasksCount]
 */
export const selectedDailyPlannedHourblockMetricsSelector = createSelector(
  [dailyPlannedHourblockBySectionOfWeekSelector, getSelectedDate],
  (dailyPlannedHourblocksBySectionOfWeek, selectedDate) => {
    const blockIndexMin = (moment(selectedDate).isoWeekday() - 1) * 48;
    const blockIndexMax = moment(selectedDate).isoWeekday() * 48;

    let recurTaskHour = 0;

    for (let i = blockIndexMin; i < blockIndexMax; i += 1) {
      if (
        dailyPlannedHourblocksBySectionOfWeek.hasIn([
          i.toString(),
          'taskRecur'
        ]) &&
        dailyPlannedHourblocksBySectionOfWeek.getIn([
          i.toString(),
          'taskRecur'
        ]) !== null &&
        dailyPlannedHourblocksBySectionOfWeek.getIn([i.toString(), 'taskRecur'])
          .length > 0
      ) {
        recurTaskHour += 0.5;
      }
    }

    return recurTaskHour;
  }
);

export const dailyPlannedHourblocksValueSortedSelector = createSelector(
  [getDailyPlannedHourblocks],
  dailyPlannedHourblocks => {
    return dailyPlannedHourblocks
      .valueSeq()
      .sort((a, b) => a.get('dayOfWeek') - b.get('dayOfWeek'));
  }
);

/**
 * Daily planned pomo metrics: get total planned pomo count by category
 * Format {weeklyPlannedByCategory: {categoryName: value}, todayPlannedByCategory: {categoryName: value},{projectName: {category: {}, value: _number_}}}
 */
export const dailyPlannedHourblocksMetricsSelector = createSelector(
  [getDailyPlannedHourblocks, categoriesByProjectSelector],
  (dailyPlannedHourblocks, categoriesByProject) => {
    const day = moment().isoWeekday() - 1;

    const res = {
      weeklyPlannedByCategory: {},
      todayPlannedByCategory: {},
      weeklyPlannedByProject: {}
    };

    dailyPlannedHourblocks
      .valueSeq()
      .toJS()
      .forEach(plannedHourblock => {
        if (
          plannedHourblock.projectId !== null &&
          categoriesByProject[plannedHourblock.projectId] !== undefined
        ) {
          const categoryName =
            categoriesByProject[plannedHourblock.projectId].category.name;

          if (res.weeklyPlannedByCategory[categoryName] === undefined) {
            res.weeklyPlannedByCategory[categoryName] = 0;
          }

          res.weeklyPlannedByCategory[categoryName] += 0.5;

          if (
            parseInt(plannedHourblock.id, 10) >= day * 48 &&
            parseInt(plannedHourblock.id, 10) < (day + 1) * 48
          ) {
            if (
              res.todayPlannedByCategory[plannedHourblock.category] ===
              undefined
            ) {
              res.todayPlannedByCategory[categoryName] = 0;
            }
            res.todayPlannedByCategory[categoryName] += 0.5;
          }

          // Get planned hourblock by each project
          const projectName =
            categoriesByProject[plannedHourblock.projectId].name;

          if (res.weeklyPlannedByProject[projectName] === undefined) {
            res.weeklyPlannedByProject[projectName] = {
              category:
                categoriesByProject[plannedHourblock.projectId].category,
              value: 0
            };
          }
          res.weeklyPlannedByProject[projectName].value += 0.5;
        }
      });

    return res;
  }
);
