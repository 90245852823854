import React, { useState } from 'react';
import moment from 'moment';

import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { Card } from 'antd';

import HabitRow from './HabitRow';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    overflowX: 'auto'
  },
  table: {
    minWidth: 650
  }
}));

export default () => {
  const initDays = () => {
    const days = [];
    for (let i = 0; i < 31; i += 1) {
      days.push(
        moment()
          .add('days', -i)
          .format('YYYY-MM-DD')
      );
    }

    return days;
  };

  const habits = useSelector(state => state.habits);

  const classes = useStyles();

  const [days] = useState(initDays());

  return (
    <Card className={classes.paper} size="small">
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell>Habit Name</TableCell>
            {days.map(day => (
              <TableCell
                key={day}
                align="right"
                style={{
                  backgroundColor:
                    day === moment().format('YYYY-MM-DD') ? '#2ed573' : 'white'
                }}
              >
                {day}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {habits
            .valueSeq()
            .sort((a, b) => {
              if (
                a.get('targetCompletionHour') !== b.get('targetCompletionHour')
              ) {
                return a.get('targetCompletionHour') >
                  b.get('targetCompletionHour')
                  ? 1
                  : -1;
              }

              return a.get('targetCompletionMinute') >
                b.get('targetCompletionMinute')
                ? 1
                : -1;
            })
            .map((habit, index) => (
              <HabitRow key={index.toString()} habit={habit} days={days} />
            ))}
        </TableBody>
      </Table>
    </Card>
  );
};
