import { fromJS } from 'immutable';

// eslint-disable-next-line import/prefer-default-export
export const reducerCreate = apiNameSnake => {
  const {
    apiNameCamel,
    apiNameCamelPlural,
    capitalizedNameSnakePlural,
    capitalizedNameSnake
  } = require('../_utility/getVariousNames')(apiNameSnake, '');

  const primaryId = `id`;

  return (state = fromJS({}), action) => {
    const getIdValue = data => data.id;

    switch (action.type) {
      case `LOAD_${capitalizedNameSnakePlural}_SUCCESS`: {
        const newInitState = {};

        action[`${apiNameCamelPlural}`].forEach(c => {
          const primaryIdValue =
            c[primaryId] === 'undefined' || c[primaryId] === undefined
              ? c.id
              : c[primaryId];
          newInitState[primaryIdValue] = c;
        });
        return fromJS(newInitState);
      }

      case `LOAD_${capitalizedNameSnake}_SUCCESS`: {
        return state.set(
          getIdValue(action[apiNameCamel]).toString(),
          fromJS(action[apiNameCamel])
        );
      }

      case `CREATE_${capitalizedNameSnake}_SUCCESS`: {
        return state.set(
          getIdValue(action[apiNameCamel]).toString(),
          fromJS(action[apiNameCamel])
        );
      }

      case `CREATE_${capitalizedNameSnake}_NESTED_SUCCESS`: {
        return state.setIn(
          [
            action[apiNameCamel].parentId.toString(),
            action[apiNameCamel].childName,
            action[apiNameCamel].childId.toString()
          ],
          fromJS(action[apiNameCamel].data)
        );
      }

      case `UPDATE_${capitalizedNameSnake}_SUCCESS`: {
        return state.set(
          getIdValue(action[apiNameCamel]).toString(),
          fromJS(action[apiNameCamel])
        );
      }

      case `UPDATE_${capitalizedNameSnake}_NESTED_SUCCESS`: {
        return state.setIn(
          [
            action[apiNameCamel].parentId.toString(),
            action[apiNameCamel].childName,
            action[apiNameCamel].childId.toString()
          ],
          fromJS(action[apiNameCamel].data)
        );
      }

      case `DELETE_${capitalizedNameSnake}_SUCCESS`: {
        // Note that the item here is immutable
        return state.delete(getIdValue(action[apiNameCamel]).toString());
      }

      case `DELETE_${capitalizedNameSnake}_NESTED_SUCCESS`: {
        return state.deleteIn([
          action[apiNameCamel].parentId.toString(),
          action[apiNameCamel].childName,
          action[apiNameCamel].childId.toString()
        ]);
      }

      case `DELETE_${capitalizedNameSnakePlural}_SUCCESS`: {
        // Note that the item here is immutable
        const newState = state.filter(rec => {
          let isFiltered = false;
          Object.keys(action.query).forEach(key => {
            if (rec.get(key) !== action.query[key]) {
              isFiltered = true;
            }
          });
          return isFiltered;
        });

        return newState;
      }

      // case `SOCKET_${capitalizedNameSnake}`: {
      //   if (JSON.stringify(state.get(action[apiNameCamel].id).toJS()) !== JSON.stringify(action[apiNameCamel])) {
      //     const res = state.set(action[apiNameCamel].id, fromJS(action[apiNameCamel]));
      //     return res;
      //   }
      //
      //   return state;
      // }

      default:
        return state;
    }
  };
};
