import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { AppContainer } from 'react-hot-loader';

import 'antd/dist/antd.css';

import './index.css';
import AppConnected from './App';
import * as serviceWorker from './serviceWorker';

import store from './redux/store';

const renderAppDev = Component => {
  render(
    <AppContainer>
      <Provider store={store}>
        <Component />
      </Provider>
    </AppContainer>,
    document.getElementById('root')
  );
};

const renderAppProd = Component => {
  render(
    <Provider store={store}>
      <Component />
    </Provider>,
    document.getElementById('root')
  );
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// TODO: prevent service worker for now as it intorduces more issue
if (process.env.NODE_ENV !== 'production') {
  serviceWorker.unregister();
} else {
  serviceWorker.register();
}

const renderApp =
  process.env.NODE_ENV === 'production' ? renderAppProd : renderAppDev;

renderApp(AppConnected);

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./App', () => {
    renderApp(AppConnected);
  });
}
