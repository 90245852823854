import React from 'react';
import moment from 'moment';
import { Select, Divider, Button, Space } from 'antd';
import ProCard from '@ant-design/pro-card';
import { useSelector, useDispatch } from 'react-redux';

import { UiActions } from 'redux/modules/ui';

import { isImmutable } from 'immutable';

import { categoriesByProjectSelector } from 'redux/modules/categories';
import {
  DailyTaskActions,
  tasksFilteredListSelector
} from 'redux/modules/dailyTasks';
import { DailyPlannedHourblockActions } from 'redux/modules/dailyPlannedHourblocks';

const { Option } = Select;
export default ({ selectedPlannedPomo }) => {
  const dispatch = useDispatch();

  const dailyTasks = useSelector(state => state.dailyTasks);

  const categoriesByProject = useSelector(state =>
    categoriesByProjectSelector(state)
  );

  const todayAllTasks = useSelector(state =>
    tasksFilteredListSelector(state).has('TodayAll')
      ? tasksFilteredListSelector(state)
          .get('TodayAll')
          .toJS()
      : []
  );

  const taskFullName = task => {
    const categoryName =
      task.projectId !== null &&
      categoriesByProject[task.projectId] !== undefined
        ? categoriesByProject[task.projectId].category.name
        : '';
    return `${task.isKeyTask ? '!! ' : ''}${task.targetCompletionDate} - [${
      categoryName.length > 0 ? categoryName : '  '
    }]: ${task.name}`;
  };

  const moveTaskMainToTask = () => {
    dispatch(UiActions.updateIn(['hourblock', 'savingPomo'], true));

    dispatch(
      DailyTaskActions.create({
        name: selectedPlannedPomo.get('taskMain'),
        projectId: 17, // FIXME: hard code the project id
        isCompleted: false,
        targetCompletionDate: moment().format('YYYY-MM-DD')
      })
    );
    // Also update the task
    const newRec = selectedPlannedPomo.set('taskMain', '');

    dispatch(DailyPlannedHourblockActions.update(newRec)).then(() => {
      dispatch(UiActions.updateIn(['hourblock', 'savingPomo'], false));
    });
  };

  return (
    <ProCard
      size="small"
      bordered
      style={{
        width: 400
      }}
    >
      {selectedPlannedPomo.get('taskMain')}
      <Divider plain />

      <Space>
        <Button
          onClick={() => moveTaskMainToTask()}
          disabled={selectedPlannedPomo.get(['taskMain']) === ''}
        >
          Move to task
        </Button>
      </Space>
      <Divider plain />

      <Select
        style={{
          width: '100%'
        }}
        value={
          isImmutable(selectedPlannedPomo) &&
          selectedPlannedPomo.get('dailyTaskId') !== null
            ? selectedPlannedPomo.get('dailyTaskId')
            : ''
        }
        disabled={!isImmutable(selectedPlannedPomo)}
        // value={`${currentValue}`}
        onChange={event => {
          // Set the current task to be the task selected
          const newRec = selectedPlannedPomo.set(
            'taskMain',
            dailyTasks.get(event.target.value).get('name')
          );

          dispatch(UiActions.updateIn(['hourblock', 'savingPomo'], true));

          dispatch(DailyPlannedHourblockActions.update(newRec)).then(() => {
            dispatch(UiActions.updateIn(['hourblock', 'savingPomo'], false));
          });

          // updateDailyPlannedHourblock(
          //   'dailyTaskId',
          //   parseInt(event.target.value, 10),
          //   selectedPlannedPomo
          // );

          // dispatch(
          //   DailyTaskActions.create(
          //     selectedTasks.set('pomoCount', selectedTasks.get('pomoCount') + 1)
          //   )
          // );

          // if (selectedPlannedPomo.get('dailyTaskId') !== null) {
          //   const oldSelectedTask = dailyTasks.get(
          //     JSON.stringify(selectedPlannedPomo.get('dailyTaskId'))
          //   );

          //   dispatch(
          //     DailyTaskActions.create(
          //       oldSelectedTask.set(
          //         'pomoCount',
          //         oldSelectedTask.get('pomoCount') - 1
          //       )
          //     )
          //   );
          // }
        }}
      >
        <Option value="" />
        {todayAllTasks
          // .filter(
          //   t =>
          //     selectedPlannedPomo !== undefined &&
          //     selectedPlannedPomo.get('projectId') === t['projectId']
          // )
          .map((task, index) => (
            <Option value={`${task.dailyTaskId}`} key={index}>
              {`${taskFullName(task)}`}
            </Option>
          ))}
      </Select>
    </ProCard>
  );
};
