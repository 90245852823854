import { createSelector } from 'reselect';
import { isImmutable, fromJS } from 'immutable';
import moment from 'moment';

import { actionCreate } from '../_base/actionCreate';
import { reducerCreate } from '../_base/reducerCreate';

import { tasksByDateSelector } from './dailyTasks';
export default reducerCreate('yearly_sub_task');

export const { YearlySubTaskActions } = actionCreate('yearly_sub_task');

const getYearlySubTasks = state => state.yearlySubTasks;

// Adding tasks to dates that it covers through
export const yearlySubTasksByDateSelector = createSelector(
  [getYearlySubTasks],
  tasks => {
    const results = {};

    if (isImmutable(tasks)) {
      Object.values(tasks.toJS()).forEach(task => {
        const diffDays =
          moment(task.targetCompletionDate).diff(
            moment(task.targetStartDate),
            'days'
          ) + 1;

        for (let i = 0; i < diffDays; i += 1) {
          const currentDate = moment(task.targetStartDate)
            .add(i, 'days')
            .format('YYYY-MM-DD');
          if (results[currentDate] === undefined) {
            results[currentDate] = [];
          }

          results[currentDate].push(task);
        }
      });
    }

    return fromJS(results);
  }
);

// Get total # of planned pomo hours for a day

const getYearlySubTaskDailyPomo = yearlySubTask => {
  const targetCompletionPomo = yearlySubTask.get('targetCompletionPomo');

  const diffDays =
    moment(yearlySubTask.get('targetCompletionDate')).diff(
      moment(yearlySubTask.get('targetStartDate')),
      'days'
    ) + 1;

  return (targetCompletionPomo / diffDays).toFixed(0);
};

/**
 * Return {key-value} = {2021-07-20: {totalTaskPomo: 0, totalDailyTaskPomo: 0, totalYearlySubTaskPomo: 0}}
 * Get recent +-3 months (6 months window)
 */
export const selecteDatedTotalPlannedHoursObjectSelector = createSelector(
  [yearlySubTasksByDateSelector, tasksByDateSelector],
  (yearlySubTasksByDate, tasksByDate) => {
    const res = {};

    for (let i = -90; i < 90; i += 1) {
      const currentDate = moment()
        .add(i, 'days')
        .format('YYYY-MM-DD');

      const currentPlannedHoursObject = {
        totalTaskPomo: 0,
        totalDailyTaskPomo: 0,
        totalYearlySubTaskPomo: 0
      };

      if (tasksByDate.has(currentDate)) {
        tasksByDate.get(currentDate).forEach(a => {
          currentPlannedHoursObject.totalDailyTaskPomo +=
            a.get('targetCompletionPomo') / 2;
        });
      }

      if (yearlySubTasksByDate.has(currentDate)) {
        yearlySubTasksByDate.get(currentDate).forEach(yearlySubTask => {
          currentPlannedHoursObject.totalYearlySubTaskPomo +=
            getYearlySubTaskDailyPomo(yearlySubTask) / 2;
        });
      }

      currentPlannedHoursObject.totalTaskPomo =
        currentPlannedHoursObject.totalDailyTaskPomo +
        currentPlannedHoursObject.totalYearlySubTaskPomo;

      res[currentDate] = currentPlannedHoursObject;
    }

    return res;
  }
);
