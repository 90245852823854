// import moment from 'moment';
import { createSelector } from 'reselect';
// import { isImmutable } from 'immutable';
import moment from 'moment';
import { actionCreate } from '../_base/actionCreate';
import { reducerCreate } from '../_base/reducerCreate';

export default reducerCreate('monthly_task');
export const { MonthlyTaskActions } = actionCreate('monthly_task');

const getMonthlyTasks = state => state.monthlyTasks;

export const monthlyTasksByMonthSelector = createSelector(
  [getMonthlyTasks],
  monthlyTasks => {
    const res = {};

    monthlyTasks
      .valueSeq()
      .sort((a, b) => {
        if (a.get('targetCompletionMonth') !== b.get('targetCompletionMonth')) {
          return a.get('targetCompletionMonth') > b.get('targetCompletionMonth')
            ? 1
            : -1;
        }
        return a.get('projectId') > b.get('projectId') ? 1 : -1;
      })
      .forEach(rec => {
        if (res[rec.get('targetCompletionMonth')] === undefined) {
          res[rec.get('targetCompletionMonth')] = [rec.toJS()];
        } else {
          res[rec.get('targetCompletionMonth')].push(rec.toJS());
        }
      });

    return res;
  }
);

export const dueMonthlyTasksListSelector = createSelector(
  [getMonthlyTasks],
  monthlyTasks => {
    return monthlyTasks
      .valueSeq()
      .sort((a, b) => {
        if (a.get('targetCompletionMonth') !== b.get('targetCompletionMonth')) {
          return a.get('targetCompletionMonth') > b.get('targetCompletionMonth')
            ? 1
            : -1;
        }
        return a.get('projectId') > b.get('projectId') ? 1 : -1;
      })
      .filter(
        rec => rec.get('targetCompletionMonth') <= moment().format('YYYY-MM')
      );
  }
);

// import { createSlice } from '@reduxjs/toolkit';
// import BaseApi from '../_base/api';

// export const initialState = { loading: false, data: [], error: null };

// const weeklyTasksModule = createSlice({
//   name: 'constantInfo',
//   initialState,
//   reducers: {
//     getWeeklyTasksStart(state) {
//       state.loading = true;
//       state.error = null;
//     },
//     getWeeklyTasksSuccess(state, action) {
//       state.data = action.payload;
//       state.loading = false;
//       state.error = null;
//     },
//     getWeeklyTasksFailure(state, action) {
//       state.loading = false;
//       state.error = action.payload;
//     }
//   }
// });

// export const {
//   getWeeklyTasksStart,
//   getWeeklyTasksSuccess,
//   getWeeklyTasksFailure
// } = weeklyTasksModule.actions;

// class WeeklyTasksClassApi extends BaseApi {
//   constructor() {
//     super('weekly_task');
//   }
// }

// export class WeeklyTask {
//   name: string = '';
//   priority: number = 0;
//   targetCompletionDate: string = '';

//   static default = new WeeklyTask();

//   constructor(name = '', priority = 0, targetCompletionDate = '') {
//     this.name = name;
//     this.priority = priority;
//     this.targetCompletionDate = targetCompletionDate;
//   }

//   get asJSON() {
//     return {
//       name: this.name,
//       prority: this.priority,
//       targetCompletionDate: this.targetCompletionDate
//     };
//   }
// }

// export const fetchWeeklyTasks = () => async dispatch => {
//   try {
//     dispatch(getWeeklyTasksStart());
//     const response = await new WeeklyTasksClassApi().getAll();
//     dispatch(getWeeklyTasksSuccess(response.data));
//   } catch (e) {
//     dispatch(getWeeklyTasksFailure(e));
//   }
// };

// export const createWeeklyTask = weeklyTask => async dispatch => {
//   try {
//     dispatch(getWeeklyTasksStart());
//     const response = await new WeeklyTasksClassApi().create(weeklyTask);
//     dispatch(getWeeklyTasksSuccess(response.data));
//   } catch (e) {
//     dispatch(getWeeklyTasksFailure(e));
//   }
// };

// export const deleteWeeklyTask = weeklyTask => async dispatch => {
//   try {
//     dispatch(getWeeklyTasksStart());
//     const response = await new WeeklyTasksClassApi().delete(weeklyTask);
//     dispatch(getWeeklyTasksSuccess(response.data));
//   } catch (e) {
//     dispatch(getWeeklyTasksFailure(e));
//   }
// };

// export const weeklyTasksActions = {
//   getAll: fetchWeeklyTasks,
//   create: createWeeklyTask,
//   deleteRecord: deleteWeeklyTask
// };

// export default weeklyTasksModule;
