import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Calendar, Divider } from 'antd';
import { tasksByDateSelector } from 'redux/modules/dailyTasks';

import { monthlyTasksByMonthSelector } from 'redux/modules/monthlyTasks';
import { categoriesByProjectSelector } from 'redux/modules/categories';
import MATERIAL_COLORS from 'views/styles/colors';
import ProCard from '@ant-design/pro-card';

import TaskMasterPlanningCalendarBlock from './TaskMasterPlanningCalendarBlock';
import Task from 'views/components/organisms/Modal/Task';
import TasksByDay from '../TasksByDay';

export default () => {
  const [tab, setTab] = useState('tab1');

  const tasksByDate = useSelector(state => tasksByDateSelector(state));
  const monthlyTasksByMonth = useSelector(state =>
    monthlyTasksByMonthSelector(state)
  );

  const selectedProjectFromFilter = useSelector(state =>
    state.ui.getIn(['task', 'selectedProject'])
  );

  const categoriesByProject = useSelector(state =>
    categoriesByProjectSelector(state)
  );

  // const getTotalPlannedHours = currentDate => {
  //   let res = 0;

  //   if (yearlySubTasksByDate.has(currentDate)) {
  //     yearlySubTasksByDate.get(currentDate).forEach(yearlySubTask => {
  //       res += getYearlySubTaskDailyPomo(yearlySubTask) / 2;
  //     });
  //   }

  //   if (tasksByDate.has(currentDate)) {
  //     tasksByDate.get(currentDate).forEach(a => {
  //       res += a.get('targetCompletionPomo') / 2;
  //     });
  //   }

  //   return res;
  // };

  const dateCellRender = value => {
    const currentDate = value.format('YYYY-MM-DD');

    if (tasksByDate.has(currentDate)) {
      return <TaskMasterPlanningCalendarBlock currentDate={currentDate} />;
    }
  };

  const getCategory = projectId => {
    if (projectId === null || categoriesByProject[projectId] === undefined) {
      return {};
    }

    const selectedCategory = categoriesByProject[projectId].category;

    return selectedCategory;
  };

  const monthCellRender = value => {
    let month = value.month() + 1;
    const padMonth = month < 10 ? '0' + month : month;
    const key = `${value.year()}-${padMonth}`;

    return (
      <>
        <span
          style={{
            position: 'absolute',
            top: 0,
            fontSize: 14,
            fontWeight: 'bold',
            background: 'whitesmoke',
            padding: '0 10px'
          }}
        >
          {monthlyTasksByMonth[key] !== undefined
            ? monthlyTasksByMonth[key].length
            : 0}
        </span>

        {monthlyTasksByMonth[key] !== undefined &&
          monthlyTasksByMonth[key].map(t => (
            <div
              style={{
                background:
                  selectedProjectFromFilter === t.projectId
                    ? MATERIAL_COLORS.LightBlue
                    : 'transparent'
              }}
            >
              <span
                style={{
                  background: `#${getCategory(t.projectId).color}`,
                  color: 'white',
                  width: 40,
                  textAlign: 'center',
                  marginRight: 5
                }}
              >
                {getCategory(t.projectId).name}
              </span>
              {t.name}
            </div>
          ))}
      </>
    );
  };

  return (
    <>
      <ProCard
        size="small"
        tabs={{
          tabPosition: 'top',
          activeKey: tab,
          onChange: key => {
            setTab(key);
          }
        }}
      >
        <ProCard.TabPane key="tab1" tab="Daily Task">
          <div
            style={{
              maxHeight: '80vh',
              overflow: 'auto'
            }}
          >
            <TasksByDay />
          </div>
        </ProCard.TabPane>

        <ProCard.TabPane key="tab2" tab="Monthly View">
          <Calendar
            mode="year"
            defaultValue={moment()}
            validRange={[moment().add(-1, 'year'), moment().add(2, 'year')]}
            dateCellRender={dateCellRender}
            monthCellRender={monthCellRender}
          />
        </ProCard.TabPane>
        <ProCard.TabPane key="tab3" tab="Daily View">
          <Calendar
            mode="month"
            defaultValue={moment()}
            validRange={[moment().add(-1, 'year'), moment().add(2, 'year')]}
            dateCellRender={dateCellRender}
            monthCellRender={monthCellRender}
          />
        </ProCard.TabPane>
      </ProCard>
    </>
  );
};
