import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import _ from 'lodash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Row, Col, Tag, Divider } from 'antd';

import ProCard from '@ant-design/pro-card';

import { isImmutable } from 'immutable';
import { WorkoutRecordActions } from 'redux/modules/workoutRecords';

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import WorkoutRecordNew from './WorkoutRecordNew';
import { setRef } from '@material-ui/core';
import CalendarCard from 'views/components/organisms/Card/CalendarCard';
import ChartWeight from 'views/components/organisms/Chart/ChartWeight';

export default () => {
  const isMobile = useSelector(state => state.ui.getIn(['common', 'isMobile']));

  // const deleteRecord = record => {
  //   // const { selectedWorkoutRecord } = this.props;
  //   // const newWorkoutsRecords = selectedWorkoutRecord
  //   //   .get('records')
  //   //   .filter(function(item) {
  //   //     return item.get('workoutsRef') !== record.get('workoutsRef');
  //   //   });
  //   // const newRec = selectedWorkoutRecord.set('records', newWorkoutsRecords);
  //   // this.props.WorkoutRecordActions.update(newRec);
  // };

  // updateWorkoutRecord() {
  //   // Update those daily records
  //   // const { selectedWorkoutRecord } = this.props;
  //   // const newState = selectedWorkoutRecord.merge({
  //   //   score: parseInt(this.dailyScoreInputRef.value, 10),
  //   //   weight: parseFloat(this.dailyWeightInputRef.value)
  //   // });
  //   // this.props.WorkoutRecordActions.update(newState);
  // }

  const focuses = [
    'CHEST',
    'SHOULDER',
    'ARM',
    'LEG',
    'ABS',
    'WAIST',
    'BACK',
    'AEROBIC'
  ];

  const [tab, setTab] = useState(focuses[0]);

  const workoutRecords = useSelector(state => state.workoutRecords);

  const [selectedRecordName, setSelectedRecordName] = useState('');
  const [selectedSet, setSelectedSet] = useState('');
  const [selectedRep, setSelectedRep] = useState('');
  const [selectedWeight, setselectedWeight] = useState('');

  const dispatch = useDispatch();

  const workouts = useSelector(state => state.workouts);

  const getWorkoutObject = rec => {
    if (
      rec.has('workoutId') &&
      isImmutable(workouts) &&
      workouts.has(rec.get('workoutId').toString())
    ) {
      return workouts.get(rec.get('workoutId').toString()).toJS();
    }

    return {};
  };

  const getAerobicRow = rec => (
    <TableRow>
      <TableCell align="left">{rec.get('workoutDate')}</TableCell>
      <TableCell align="left">
        <Tag size="small">
          {`${rec.getIn(['runDistanceKm'])} km, ${rec.getIn([
            'runTimeMinutes'
          ])} min`}
        </Tag>
      </TableCell>
      <TableCell>
        <IconButton
          edge="end"
          aria-label="Delete"
          onClick={() => {
            // eslint-disable-next-line no-restricted-globals
            if (window.confirm(`Deleting workout record`)) {
              dispatch(WorkoutRecordActions.deleteRecord(rec.toJS()));
            }
          }}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );

  const getRow = rec => (
    <TableRow>
      <TableCell align="left">{rec.get('workoutDate')}</TableCell>
      {/* <TableCell align="left">{getWorkoutObject(rec).focus}</TableCell> */}
      <TableCell align="left">
        {`${getWorkoutObject(rec).name} - `}
        <Tag size="small">
          {`${rec.get('resistanceSets')} x ${rec.get(
            'resistanceRepetitions'
          )} x ${rec.get('resistanceWeightsKg')}`}
        </Tag>
      </TableCell>
      <TableCell>
        <IconButton
          edge="end"
          aria-label="Delete"
          onClick={() => {
            // eslint-disable-next-line no-restricted-globals
            if (window.confirm(`Deleting workout record`)) {
              dispatch(WorkoutRecordActions.deleteRecord(rec.toJS()));
            }
          }}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );

  const getLatestRecord = rec =>
    `${rec.get('resistanceSets')} x ${rec.get(
      'resistanceRepetitions'
    )} x ${rec.get('resistanceWeightsKg')}`;

  const getFilteredRecordsForSelected = focus =>
    workoutRecords
      .valueSeq()
      .sort((a, b) => (a.get('workoutDate') < b.get('workoutDate') ? 1 : -1))
      .filter(a => {
        if (focus === 'AEROBIC') {
          return a.get('runDistanceKm') !== null;
        }

        if (selectedRecordName !== '') {
          return getWorkoutObject(a).name === selectedRecordName;
        }

        return getWorkoutObject(a).focus === focus;
      });

  const getFilteredRecordsByName = (focus, selectedRecord) =>
    workoutRecords
      .valueSeq()
      .sort((a, b) => (a.get('workoutDate') < b.get('workoutDate') ? 1 : -1))
      .filter(a => {
        if (focus === 'AEROBIC') {
          return 1;
        }

        return getWorkoutObject(a).name === selectedRecord;
      });

  const getRepeatedRecord = (f, record) => {
    let count = 0;
    const rec = getFilteredRecordsByName(f, record.name).toJS();

    let oldRecString = '';
    rec.reverse().forEach(r => {
      let recString = `${r['resistanceSets']} x ${r['resistanceRepetitions']} x ${r['resistanceWeightsKg']}`;

      if (oldRecString === '') {
        oldRecString = recString;
        count += 1;
      } else if (recString == oldRecString) {
        count += 1;
      } else {
        return count;
      }
    });
    return count;
  };

  return (
    <>
      <WorkoutRecordNew
        selectedRecordName={selectedRecordName}
        selectedSet={selectedSet}
        selectedRep={selectedRep}
        selectedWeight={selectedWeight}
      />
      <br />

      <ProCard direction="row" ghost gutter={[0, 8]}>
        <ProCard
          colSpan={20}
          size="small"
          bordered
          split="horizontal"
          tabs={{
            tabPosition: 'top',
            activeKey: tab,
            onChange: key => {
              setTab(key);
            }
          }}
        >
          {focuses.map(f => (
            <ProCard.TabPane key={f} tab={f} gutter={8}>
              <Row gutter={16}>
                {f !== 'AEROBIC' && (
                  <Col
                    span={isMobile ? 24 : 8}
                    style={{
                      borderRight: '1px solid whitesmoke'
                    }}
                  >
                    {_.uniqBy(
                      workoutRecords
                        .valueSeq()
                        .sort((a, b) =>
                          a.get('workoutDate') < b.get('workoutDate') ? 1 : -1
                        )
                        .filter(a => {
                          if (f === 'AEROBIC') {
                            return 1;
                          }

                          return getWorkoutObject(a).focus === f;
                        })
                        .map(a => getWorkoutObject(a))
                        .toJS(),
                      'id'
                    ).map(record => (
                      <div
                        role="button"
                        tabIndex={0}
                        style={{
                          height: 30,
                          margin: '5px 0',
                          background:
                            selectedRecordName === record.name
                              ? '#03A9F4'
                              : 'white',
                          borderBottom: '1px solid #bdc3c7'
                        }}
                        onClick={() => {
                          const lastRec =
                            getFilteredRecordsByName(f, record.name).count() > 0
                              ? getFilteredRecordsByName(f, record.name).get(
                                  '0'
                                )
                              : '';

                          if (lastRec !== '') {
                            setSelectedRep(
                              lastRec.get('resistanceRepetitions')
                            );
                            setSelectedSet(lastRec.get('resistanceSets'));
                            setselectedWeight(
                              lastRec.get('resistanceWeightsKg')
                            );
                          }

                          setSelectedRecordName(record.name);
                        }}
                      >
                        {record.isMachine && <span>[M]</span>}
                        <span style={{ flex: 1 }}>
                          {record.name} -{' '}
                          {getFilteredRecordsByName(f, record.name).count()} Rec
                        </span>

                        <span style={{ float: 'right' }}>
                          {getFilteredRecordsByName(f, record.name).count() > 0
                            ? getFilteredRecordsByName(f, record.name).getIn([
                                '0',
                                'workoutDate'
                              ])
                            : ''}
                          :
                          <Tag size="small">
                            {getFilteredRecordsByName(f, record.name).count() >
                            0
                              ? getLatestRecord(
                                  getFilteredRecordsByName(f, record.name).get(
                                    '0'
                                  )
                                )
                              : ''}
                          </Tag>
                          <Tag>{getRepeatedRecord(f, record)}</Tag>
                        </span>
                      </div>
                    ))}
                  </Col>
                )}

                {isMobile && <Divider />}

                <Col span={f !== 'AEROBIC' && !isMobile ? 16 : 24}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell lign="left">Date</TableCell>
                        <TableCell lign="left">
                          {f === 'AEROBIC'
                            ? 'AEROBIC'
                            : `Resistance(Set - Reps - Weight) x Aerobic`}
                        </TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {getFilteredRecordsForSelected(f).map((record, index) => {
                        return f === 'AEROBIC'
                          ? getAerobicRow(record)
                          : getRow(record);
                      })}
                    </TableBody>
                  </Table>
                </Col>
              </Row>
            </ProCard.TabPane>
          ))}
        </ProCard>

        <ProCard
          colSpan={4}
          layout="default"
          bordered
          size="small"
          split="horizontal"
        >
          <ProCard
            size="small"
            bordered
            style={{
              height: 300
            }}
          >
            <ChartWeight />
          </ProCard>

          <br />
          <br />
          <ProCard size="small" type="inner" bordered>
            <CalendarCard type="workout" />
          </ProCard>
        </ProCard>
      </ProCard>
    </>
  );
};
