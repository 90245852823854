import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Dropdown, Menu } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { PageContainer } from '@ant-design/pro-layout';

import { tasksFilteredListSelector } from 'redux/modules/dailyTasks';

import TasksAll from '../2-2-Calendar/TasksAll';
import TasksByDay from './TasksByDay';

export default () => {
  // const selectedTab = props.location.pathname.split('/task/')[1];

  // const tabs = ['all', 'today'];

  // const selectedTabIndex =
  //   tabs.indexOf(selectedTab) > -1 ? tabs.indexOf(selectedTab) : 0; // default first tab

  const [tab, setTab] = useState('master');

  return (
    <PageContainer
      size="small"
      header={{
        title: 'Tasks Planning',
        ghost: true
        // breadcrumb: {
        //   routes: [
        //     {
        //       path: '',
        //       breadcrumbName: 'Task'
        //     }
        //     // {
        //     //   path: '',
        //     //   breadcrumbName: '二级页面'
        //     // },
        //     // {
        //     //   path: '',
        //     //   breadcrumbName: '当前页面'
        //     // }
        //   ]
        // },
        // extra: [
        //   <Button key="1">次要按钮</Button>,
        //   <Button key="2" type="primary">
        //     主要按钮
        //   </Button>,
        //   <Dropdown
        //     key="dropdown"
        //     trigger={['click']}
        //     overlay={
        //       <Menu>
        //         <Menu.Item key="1">下拉菜单</Menu.Item>
        //         <Menu.Item key="2">下拉菜单2</Menu.Item>
        //         <Menu.Item key="3">下拉菜单3</Menu.Item>
        //       </Menu>
        //     }
        //   >
        //     <Button key="4" style={{ padding: '0 8px' }}>
        //       <EllipsisOutlined />
        //     </Button>
        //   </Dropdown>
        // ]
      }}
      // tabList={[
      //   {
      //     tab: 'Task Planning (Master View)',
      //     key: 'master',
      //     closable: false
      //   },
      //   {
      //     tab: 'Task by day',
      //     key: 'tasksByDay',
      //     closable: false
      //   }
      // ]}
      // // tabProps={{
      // //   type: 'editable-card',
      // //   hideAdd: true,
      // //   onEdit: (e, action) => console.log(e, action)
      // // }}
      // // footer={[
      // //   <Button key="3">XXX</Button>,
      // //   <Button key="2" type="primary">
      // //     XX
      // //   </Button>
      // // ]}
      // onTabChange={k => setTab(k)}
    >
      {/* <ProCard style={{ height: 200 }} />
            <br />
            <ProCard gutter={16} ghost style={{ height: 200 }}>
              <ProCard colSpan={16} />
              <ProCard colSpan={8} />
            </ProCard> */}
      {/* <ProCard direction="column" ghost gutter={[0, 16]}> */}
      <TasksByDay />
      {/* </ProCard> */}
    </PageContainer>
  );
};
