import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { makeStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { isImmutable } from 'immutable';
import moment from 'moment';
import _ from 'lodash';
// import { Col, Row } from 'antd';
import { DailyTaskActions, SubTask } from 'redux/modules/dailyTasks';
import PriorityIcon from 'views/components/atoms/PriorityIcon';
import ProjectSelect from 'views/components/atoms/ProjectSelect';
import { categoriesByProjectSelector } from 'redux/modules/categories';
import { MonthlyTaskActions } from 'redux/modules/monthlyTasks';
import MATERIAL_COLORS from 'views/styles/colors';
import TodayIcon from '@material-ui/icons/Today';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import { KeyboardDatePicker } from '@material-ui/pickers';

import { userSettingsMapperSelector } from 'redux/modules/user';
import userSettingConstants from 'views/constants/userSettingConstants';
import { WeeklyTaskActions } from 'redux/modules/weeklyTasks';

export default ({ task, savingPomo, type, handleDelete = () => {} }) => {
  // const classes = useStyles();

  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();

  let TaskActions = null;
  let targetCompletionField = '';

  switch (type) {
    case 'daily':
      targetCompletionField = 'targetCompletionDate';
      TaskActions = DailyTaskActions;
      break;
    case 'weekly':
      targetCompletionField = 'targetCompletionWeek';
      TaskActions = WeeklyTaskActions;
      break;
    default:
      targetCompletionField = 'targetCompletionMonth';
      TaskActions = MonthlyTaskActions;
      break;
  }

  const selectedProjectFromFilter = useSelector(state =>
    state.ui.getIn(['task', 'selectedProject'])
  );

  const deleteTask = () => {
    if (window.confirm(`Deleting task: ${task.get('name')}`)) {
      dispatch(TaskActions.deleteRecord(task.toJS()));
      handleDelete();
    }
  };

  const taskIsToday = () =>
    task.get('targetCompletionDate') === moment().format('YYYY-MM-DD');

  const dailyTasks = useSelector(state => state.dailyTasks);
  const monthlyTasks = useSelector(state => state.monthlyTasks);

  const currentUserSettings = useSelector(state =>
    userSettingsMapperSelector(state)
  );

  const getKeyDailyTasksOfTargetDayCount = targetDate => {
    return dailyTasks
      .valueSeq()
      .filter(
        a => a.get('targetCompletionDate') === targetDate && a.get('isKeyTask')
      )
      .count();
  };

  const getMonthlyTasksOfTargetMonthCount = targetMonth => {
    return monthlyTasks
      .valueSeq()
      .filter(a => a.get('targetCompletionMonth') === targetMonth)
      .count();
  };

  const isExceedingKeyTaskLimit = targetDate => {
    if (type === 'daily') {
      return (
        task.get('isKeyTask') &&
        currentUserSettings[userSettingConstants.maxDailyKeyTasks] <=
          getKeyDailyTasksOfTargetDayCount(targetDate)
      );
    }

    if (type === 'monthly') {
      return (
        currentUserSettings[userSettingConstants.maxMonthlyTasks] <=
        getMonthlyTasksOfTargetMonthCount(targetDate)
      );
    }

    // TODO: filtering for year too
    return false;
  };

  const dispatchUpdateAction = newRec => {
    // Tasks are all upsert, thus post call
    dispatch(TaskActions.create(newRec)).then(() => {
      setIsEditing(false);
    });
  };

  const addSubtask = () => {
    const newSubtasks = task.update('subtasks', arr =>
      arr.push(SubTask.default.asJSON)
    );

    dispatchUpdateAction(newSubtasks);
  };

  const updateTargetCompletion = data => {
    // Make sure that your target date will not exceed the limit tasks
    if (isExceedingKeyTaskLimit(data)) {
      alert(`Exceeding limit of key {type} tasks`);
      return;
    }

    let targetCompletion = '';
    let field = '';

    switch (type) {
      case 'daily':
        targetCompletion = moment(data).format('YYYY-MM-DD');
        field = 'targetCompletionDate';
        break;

      case 'weekly':
        targetCompletion = `${moment(data).year()}-W${moment(data).isoWeek()}`;
        field = 'targetCompletionWeek';
        break;

      case 'monthly':
        targetCompletion = moment(data).format('YYYY-MM');
        field = 'targetCompletionMonth';
        break;

      default:
        break;
    }

    const newRec = task.setIn([field], targetCompletion);

    // TODO: merging backend code together
    dispatchUpdateAction(newRec);
  };

  const updateTask = (event, field, isOnChange) => {
    if (isOnChange || event.which === 13) {
      const newRec = task.setIn([field], event.target.value);
      dispatchUpdateAction(newRec);
    }
  };

  const setTaskToDate = targetDate => {
    if (isExceedingKeyTaskLimit(targetDate)) {
      alert(`Exceeding limit of key {type} tasks`);
      return;
    }
    const newTask = task.set(targetCompletionField, targetDate);

    dispatchUpdateAction(newTask);
  };

  const categoriesByProject = useSelector(state =>
    categoriesByProjectSelector(state)
  );

  const updateProject = (categoryName, projectName) => {
    const selectedProjectToUpdate = _.find(
      Object.values(categoriesByProject),
      rec => {
        return rec.name === projectName;
      }
    );

    if (selectedProjectToUpdate === undefined) {
      alert('No selectedProjectId');
    }

    const newRec = task.set('projectId', selectedProjectToUpdate.id);

    dispatchUpdateAction(newRec);
  };

  const selectedProject =
    isImmutable(task) &&
    categoriesByProject[task.get('projectId')] !== undefined
      ? categoriesByProject[task.get('projectId')]
      : { category: {} };

  const getBackgroundColor = () => {
    if (selectedProjectFromFilter === task.get('projectId')) {
      return MATERIAL_COLORS.LightBlue;
    }

    if (savingPomo) return 'red';

    return moment().format('YYYY-MM-DD') === task.get('targetCompletionDate') &&
      !task.get('isCompleted')
      ? MATERIAL_COLORS.PrimaryLight
      : 'white';
  };

  return (
    <TableRow
      key={task.get('id')}
      style={{
        backgroundColor: getBackgroundColor()
      }}
    >
      <TableCell
        style={{
          width: 60,
          padding: 0
        }}
      >
        <PriorityIcon task={task} />

        <Checkbox
          style={{
            padding: 0
          }}
          checked={
            isImmutable(task) &&
            task.get('isCompleted') !== null &&
            task.get('isCompleted') !== false
          }
          onChange={event => {
            const newSubtasks = task.set('isCompleted', event.target.checked);
            dispatchUpdateAction(newSubtasks);
          }}
        />
      </TableCell>

      <TableCell
        component="th"
        scope="row"
        style={{
          padding: 0,
          width: 150
        }}
      >
        <div
          style={{
            width: 140,
            overflow: 'hidden'
          }}
        >
          <ProjectSelect
            selectedProject={selectedProject}
            onChangeCallBack={updateProject}
          />
        </div>
      </TableCell>

      <TableCell
        style={{
          padding: 0
        }}
        onClick={() => {
          setIsEditing(true);
        }}
      >
        {!isEditing ? (
          <span
            style={{
              textDecoration: task.get('isCompleted') ? 'line-through' : ''
            }}
          >
            {task.get('name')}
          </span>
        ) : (
          <TextField
            style={{
              padding: 0,
              width: '100%'
            }}
            id="item"
            margin="dense"
            defaultValue={task.get('name')}
            onKeyDown={event => {
              updateTask(event, 'name');
            }}
          />
        )}
      </TableCell>

      <TableCell
        align="left"
        style={{
          width: 120,
          padding: 0
        }}
        onClick={() => {
          setIsEditing(true);
        }}
      >
        {!isEditing ? (
          <span
            style={{
              textDecoration: task.get('isCompleted') ? 'line-through' : ''
            }}
          >
            {task.get('targetCompletionDate')}
          </span>
        ) : (
          <span>Fix me with ant picker </span>
          // <KeyboardDatePicker
          //   disableToolbar
          //   variant="inline"
          //   format="yyyy-MM-dd"
          //   margin="none"
          //   id="date-picker-inline"
          //   // value={selectedDate}
          //   value={task.get('targetCompletionDate')}
          //   onChange={data => updateTargetCompletion(data)}
          //   KeyboardButtonProps={{
          //     'aria-label': 'change date'
          //   }}
          // />
        )}
      </TableCell>

      <TableCell
        style={{ padding: 0, width: 10 }}
        onClick={() => {
          setIsEditing(true);
        }}
      >
        {!isEditing ? (
          <span
            style={{
              textDecoration: task.get('isCompleted') ? 'line-through' : ''
            }}
          >
            {task.get('targetCompletionPomo')}
          </span>
        ) : (
          <TextField
            style={{
              padding: 0,
              width: '50px'
            }}
            id="item"
            margin="dense"
            defaultValue={task.get('targetCompletionPomo')}
            onKeyDown={event => {
              updateTask(event, 'targetCompletionPomo');
            }}
          />
        )}
      </TableCell>

      <TableCell
        align="left"
        style={{
          width: 120,
          padding: 0
        }}
      >
        <IconButton
          variant="contained"
          size="small"
          onClick={() =>
            setTaskToDate(
              type === 'daily'
                ? moment().format('YYYY-MM-DD')
                : type === 'weekly'
                ? `${moment().year()}-W${moment().isoWeek()}`
                : moment().format('YYYY-MM')
            )
          }
          disabled={taskIsToday(task)}
        >
          <TodayIcon
            color={taskIsToday(task) ? 'disabled' : 'primary'}
            fontSize="small"
          />
        </IconButton>

        <IconButton
          variant="contained"
          size="small"
          onClick={() => setTaskToDate(null)}
        >
          <EventBusyIcon fontSize="small" />
        </IconButton>

        <IconButton
          variant="contained"
          color="secondary"
          size="small"
          onClick={() => deleteTask()}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>

        <IconButton
          variant="contained"
          color="primary"
          size="small"
          onClick={() => addSubtask()}
        >
          <AddIcon fontSize="small" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};
