import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import dailyRecords from './modules/dailyRecords';
import dailyRecordSummaries from './modules/dailyRecordSummaries';
import categories from './modules/categories';
import projects from './modules/projects';
import dailyPlannedHourblocks from './modules/dailyPlannedHourblocks';
import workouts from './modules/workouts';
import workoutRecords from './modules/workoutRecords';
import ankiStacks from './modules/ankiStacks';
import locations from './modules/locations';
import habits from './modules/habits';
import housingRecords from './modules/housingRecords';
import ui from './modules/ui';
import diaries from './modules/diaries';
import yearlyTasks from './modules/yearlyTasks';
import yearlySubTasks from './modules/yearlySubTasks';
import dailyTasks from './modules/dailyTasks';
import weeklyTasks from './modules/weeklyTasks';
import monthlyTasks from './modules/monthlyTasks';
import { UserReducers } from './modules/user';

const rootReducer = {
  categories,
  projects,

  dailyRecords,
  dailyRecordSummaries,
  dailyPlannedHourblocks,

  dailyTasks,
  weeklyTasks,
  monthlyTasks,
  yearlySubTasks,
  yearlyTasks,

  workouts,
  workoutRecords,
  ankiStacks,
  habits,

  locations,
  housingRecords,
  diaries,

  ui,
  user: UserReducers
};

const env = process.env.NODE_ENV;

const middleWares = [thunk];

let store = null; // eslint-disable-line

if (env === 'development') {
  store = createStore(
    combineReducers(rootReducer),
    composeWithDevTools(applyMiddleware(...middleWares))
  );

  // if (module.hot) {
  // 	module.hot.accept('./redux/reducers', () => {
  // 		store.replaceReducer(rootReducer);
  // 	});
  // }
} else {
  store = createStore(
    combineReducers(rootReducer),
    composeWithDevTools(applyMiddleware(...middleWares))
  );
}

export default store;
