import React, { Component } from 'react';
import { connect } from 'react-redux';

import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { fromJS } from 'immutable';
import { bindActionCreators } from 'redux';
import { AnkiStackActions } from '../../redux/modules/ankiStacks';

const styles = () => ({
  paper: {
    maxWidth: '100vw',
    margin: 'auto',
    overflow: 'hidden'
  },
  card: {
    maxWidth: '100vw',
    margin: 'auto',
    overflow: 'hidden'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  textField: {
    marginLeft: 10,
    marginRight: 10,
    width: 200
  }
});

class AnkiNew extends Component {
  state = {
    question: '',
    answer: ''
  };

  saveAnki = () => {
    const { question, answer } = this.state;
    const { selectedAnkiStack } = this.props;

    const newAnki = {
      learningTimes: 0,
      passingTimes: 0,
      question,
      answer,
      ankiStackId: JSON.parse(selectedAnkiStack).id
    };

    this.props.AnkiStackActions.createNested(
      'ankis',
      JSON.parse(selectedAnkiStack).id,
      fromJS(newAnki).toJS()
    );

    this.setState({
      question: '',
      answer: ''
    });
  };

  render() {
    const { classes } = this.props;

    const handleChange = name => event => {
      this.setState({
        [name]: event.target.value
      });
    };

    return (
      <Paper className={classes.paper}>
        <TextField
          id="standard-name"
          label="Question"
          className={classes.textField}
          value={this.state.question}
          margin="dense"
          multiline
          onChange={handleChange('question')}
          variant="outlined"
        />
        <TextField
          id="standard-name"
          label="Answer"
          className={classes.textField}
          value={this.state.answer}
          margin="dense"
          multiline
          onChange={handleChange('answer')}
          variant="outlined"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => this.saveAnki()}
        >
          Save
        </Button>
      </Paper>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    selectedAnkiStack: state.ui.getIn(['anki', 'selectedAnkiStack'])
  };
}

function mapDispatchToProps(dispatch) {
  return {
    AnkiStackActions: bindActionCreators(AnkiStackActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AnkiNew));
