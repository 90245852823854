import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';

import moment from 'moment';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Checkbox from '@material-ui/core/Checkbox';
import { HabitActions } from 'redux/modules/habits';

import MATERIAL_COLORS from 'views/styles/colors';

import { Tag, Dropdown, Space, Button } from 'antd';
import HabitRightClickBox from './HabitRightClickBox';

const useStyles = makeStyles(() => ({
  row: {
    height: 25,
    flex: 1,
    display: 'flex'
  },
  habitName: {
    wordBreak: 'break-all',
    display: 'inline-block',
    overflow: 'hidden',
    flex: 1
  },
  checkBox: {
    float: 'right',
    padding: 0
  },
  taskCompleted: {
    textDecoration: 'line-through',
    color: 'gray'
  }
}));

export default ({ habit }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const selectedDate = useSelector(state =>
    state.ui.getIn(['hourblock', 'selectedDate'])
  );

  const getDate = i =>
    moment(selectedDate)
      .startOf('isoweek')
      .add(i, 'days')
      .format('YYYY-MM-DD');

  const isChecked = dateToCheck => {
    return (
      habit.get('completedDates') !== null &&
      habit.get('completedDates').indexOf(dateToCheck) > -1
    );
  };

  const updateCompletedCount = offset => {
    let completedCounts = {};
    if (habit.get('completedCounts') !== null) {
      completedCounts = habit.get('completedCounts').toJS();
    }

    if (completedCounts[selectedDate] === undefined) {
      completedCounts[selectedDate] = offset;
    } else {
      completedCounts[selectedDate] += offset;
    }

    const newHabit = habit.set('completedCounts', completedCounts);

    dispatch(HabitActions.update(newHabit));
  };

  const getConsecutiveCompletion = habit => {
    let res = 0;

    if (!habit.has('completedDates') || habit.get('completedDates') === '') {
      return res;
    }

    let arr = habit
      .get('completedDates')
      .replace(/\s+/g, ' ')
      .split(' ')
      .reverse();

    arr.forEach((t, index) => {
      if (
        moment(arr[0])
          .add(-index, 'days')
          .format('YYYY-MM-DD') === moment(t).format('YYYY-MM-DD')
      ) {
        res += 1;
      }
    });

    return res;
  };

  const handleChange = _ => {
    let completedDates =
      habit.has('completedDates') && habit.get('completedDates') !== null
        ? habit.get('completedDates')
        : '';

    if (isChecked(selectedDate)) {
      completedDates = completedDates.replaceAll(selectedDate, '');
    } else {
      completedDates =
        completedDates !== null && completedDates.length >= 0
          ? completedDates.concat(` ${selectedDate}`)
          : selectedDate;
    }

    const newHabit = habit.set('completedDates', completedDates);

    dispatch(HabitActions.update(newHabit));
  };

  return (
    <>
      {/* <HabitRightClickBox habit={habit} /> */}
      <Dropdown
        overlay={<HabitRightClickBox habit={habit} />}
        trigger={['contextMenu']}
      >
        <div
          className={clsx(classes.row, isChecked() && classes.taskCompleted)}
        >
          <Checkbox
            // classes={classes.checkbox}
            style={{
              float: 'right',
              padding: 0
            }}
            checked={isChecked(selectedDate)}
            onChange={handleChange}
            color="primary"
            // inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          {/*
          <Tag
            style={{
              width: 40,
              textAlign: 'center',
              padding: 0
            }}
          >
            {padZero(habit.get('targetCompletionHour'))}:
            {padZero(habit.get('targetCompletionMinute'))}
          </Tag> */}

          <span
            style={{
              flex: 1,
              overflow: 'hidden',
              color: isChecked(selectedDate) ? 'gray' : 'black',
              textDecoration: isChecked(selectedDate) ? 'line-throught' : ''
            }}
          >
            {habit.get('name')}
          </span>

          <span>
            {[0, 1, 2, 3, 4, 5, 6].map(i => (
              <span key={i}>
                <FiberManualRecordIcon
                  style={{
                    color: isChecked(getDate(i))
                      ? MATERIAL_COLORS.Primary
                      : getDate(i) === selectedDate
                      ? MATERIAL_COLORS.LightRed
                      : MATERIAL_COLORS.LightGray
                  }}
                  fontSize="small"
                />
              </span>
            ))}
          </span>

          {getConsecutiveCompletion(habit)}

          {/* {habit.get('targetCompletionCount') !== null && (
            <Space
              style={{
                float: 'right'
              }}
            >
              <Tag
                style={{
                  background: 'whitesmoke'
                }}
              >
                {habit.getIn([
                  'completedCounts',
                  moment().format('YYYY-MM-DD')
                ])}
                /{habit.get('targetCompletionCount')}
              </Tag>
              <Button size="small" onClick={() => updateCompletedCount(1)}>
                +1
              </Button>
              <Button size="small" onClick={() => updateCompletedCount(-1)}>
                -1
              </Button>
            </Space>
          )} */}
        </div>
      </Dropdown>
    </>
  );
};
