import React from 'react';
import moment from 'moment';
import { Row, Col, Tag } from 'antd';

import { useSelector } from 'react-redux';

import { PageContainer } from '@ant-design/pro-layout';

import HourblockTable from 'views/1-Hourblock/HourblockTable';

import HourblockTableHeaderButtons from './HourblockTableHeaderButtons';
import HourblockRightPanel from './rightPanel/HourblockRightPanel';
import HourblockChartModal from '../components/organisms/Chart/ChartHourblockModal';
import HourblockCalendar from './HourblockCalendar';

const RIGHT_COL_WIDTH = 380;

export default () => {
  const showCalendarView = useSelector(state =>
    state.ui.getIn(['hourblock', 'showCalendarView'])
  );

  const selectedDate = useSelector(state =>
    state.ui.getIn(['hourblock', 'selectedDate'])
  );

  return (
    <PageContainer
      size="small"
      style={{ height: '100%' }}
      className="site-page-header"
      // onBack={() => null}
      title={moment(selectedDate).format('YYYY-MM-DD')}
      subTitle={moment(selectedDate).format('ddd')}
      tags={
        moment().format('YYYY-MM-DD') ===
          moment(selectedDate).format('YYYY-MM-DD') && (
          <Tag color="green">TODAY</Tag>
        )
      }
      extra={[<HourblockTableHeaderButtons selectedDate={selectedDate} />]}
    >
      <HourblockChartModal />
      <Row
        gutter={8}
        style={{
          height: 'inherit'
        }}
      >
        <Col
          style={{
            flex: 1,
            maxHeight: 'calc(100vh - 80px)',
            overflow: 'auto'
          }}
        >
          {!showCalendarView ? (
            <HourblockTable selectedDate={selectedDate} />
          ) : (
            <HourblockCalendar />
          )}
        </Col>

        <Col
          style={{
            maxHeight: 'calc(100vh - 80px)',
            width: RIGHT_COL_WIDTH,
            overflow: 'auto'
          }}
        >
          <HourblockRightPanel />
        </Col>
      </Row>
    </PageContainer>
  );
};
