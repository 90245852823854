import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { PageContainer } from '@ant-design/pro-layout';

import DailySummary from './DailySummary';
import Habit from './Habit';

export default () => {
  const history = useHistory();
  const selectedTabName = history.location.pathname.split('/routine')[1];

  // const selectedTabIndex =
  //   selectedTabName === '' || selectedTabName.indexOf('habit') > -1 ? 0 : 1; // default first tab

  const [tab, setTab] = useState('habit');

  return (
    <PageContainer
      size="small"
      header={{
        title: 'Routine',
        ghost: true
        // breadcrumb: {
        //   routes: [
        //     {
        //       path: '',
        //       breadcrumbName: 'Task'
        //     }
        //     // {
        //     //   path: '',
        //     //   breadcrumbName: '二级页面'
        //     // },
        //     // {
        //     //   path: '',
        //     //   breadcrumbName: '当前页面'
        //     // }
        //   ]
        // }
        // extra: [
        //   <Button key="1">次要按钮</Button>,
        //   <Button key="2" type="primary">
        //     主要按钮
        //   </Button>,
        //   <Dropdown
        //     key="dropdown"
        //     trigger={['click']}
        //     overlay={
        //       <Menu>
        //         <Menu.Item key="1">下拉菜单</Menu.Item>
        //         <Menu.Item key="2">下拉菜单2</Menu.Item>
        //         <Menu.Item key="3">下拉菜单3</Menu.Item>
        //       </Menu>
        //     }
        //   >
        //     <Button key="4" style={{ padding: '0 8px' }}>
        //       <EllipsisOutlined />
        //     </Button>
        //   </Dropdown>
        // ]
      }}
      tabList={[
        {
          tab: 'Habits Planning',
          key: 'habit',
          closable: false
        },
        {
          tab: 'Daily Summary',
          key: 'dailySummary',
          closable: false
        }
      ]}
      // tabProps={{
      //   type: 'editable-card',
      //   hideAdd: true,
      //   onEdit: (e, action) => console.log(e, action)
      // }}
      onTabChange={k => setTab(k)}
    >
      {tab === 'habit' && <Habit />}
      {tab === 'dailySummary' && <DailySummary />}
    </PageContainer>
  );
};
