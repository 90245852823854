import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

import { fromJS } from 'immutable';
import moment from 'moment';

import {
  AnkiStackActions,
  getLearningAnkisListSelector,
  getLearnableSelectedAnkiListSelector
} from '../../redux/modules/ankiStacks';

const styles = () => ({
  paper: {
    maxWidth: '100vw',
    margin: 'auto',
    overflow: 'hidden'
  },
  card: {
    maxWidth: '100vw',
    margin: 'auto',
    overflow: 'hidden'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  textField: {
    marginLeft: 10,
    marginRight: 10,
    width: 200
  }
});

class AnkiCard extends Component {
  state = {
    showAnswer: false,
    editMode: false
  };

  updateAnki = (type, currentAnki) => {
    const { selectedAnkiStack } = this.props;

    const newItem = fromJS(currentAnki).toJS();

    if (type === 'Okay') {
      newItem.learningTimes += 1;

      const daysToAdd = Math.floor(1.5 ** newItem.learningTimes);

      const newDate = moment()
        .add(daysToAdd, 'days')
        .format('YYYY-MM-DD');
      newItem.learningNextDate = newDate;
      newItem.isLearning = false;
    }

    if (type === 'Pass') {
      if (newItem.passingTimes === null) {
        newItem.passingTimes = 0;
      }

      newItem.passingTimes += 1;

      if (newItem.passingTimes === 3) {
        newItem.learningTimes -= newItem.learningTimes > 0 ? 1 : 0;
        newItem.passingTimes -= 3;
      }

      newItem.passingLastDate = moment.utc().format();
    }

    this.setState({
      showAnswer: false
    });

    this.props.AnkiStackActions.updateNested(
      'ankis',
      JSON.parse(selectedAnkiStack).id,
      fromJS(newItem).toJS()
    );
  };

  render() {
    const {
      learningAnkisList,
      learnableSelectedAnkisList,
      classes,
      selectedAnkiStack
    } = this.props;
    const { showAnswer, editMode } = this.state;

    return (
      <Card className={classes.card}>
        {learningAnkisList.length > 0 ? (
          <CardContent
            onClick={() => {
              this.setState({
                showAnswer: !showAnswer
              });
            }}
            style={{
              minHeight: 200
            }}
          >
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              {!showAnswer ? 'Question' : 'Answer'} - {learningAnkisList.length}{' '}
              left{' '}
              {`Learning:
                ${learningAnkisList[0].learningTimes} - Passing:
                ${learningAnkisList[0].passingTimes}`}
            </Typography>

            {editMode && (
              <React.Fragment>
                <TextField
                  label="Question"
                  className={classes.textField}
                  defaultValue={learningAnkisList[0].question}
                  margin="dense"
                  multiline
                  rows={4}
                  onKeyDown={event => {
                    if (event.which === 13) {
                      learningAnkisList[0].question = event.target.value;

                      this.props.AnkiStackActions.updateNested(
                        'ankis',
                        JSON.parse(selectedAnkiStack).id,
                        learningAnkisList[0]
                      ).then(() => {
                        this.setState({
                          editMode: !editMode
                        });
                      });
                    }
                  }}
                  variant="outlined"
                />

                <TextField
                  label="Answer"
                  className={classes.textField}
                  defaultValue={learningAnkisList[0].answer}
                  margin="dense"
                  rows={4}
                  onKeyDown={event => {
                    if (event.which === 13) {
                      learningAnkisList[0].answer = event.target.value;

                      this.props.AnkiStackActions.updateNested(
                        'ankis',
                        JSON.parse(selectedAnkiStack).id,
                        learningAnkisList[0]
                      ).then(() => {
                        this.setState({
                          editMode: !editMode
                        });
                      });
                    }
                  }}
                  variant="outlined"
                />
              </React.Fragment>
            )}

            {!editMode && (
              <Typography variant="h5" component="h2">
                {!showAnswer
                  ? learningAnkisList[0].question
                  : learningAnkisList[0].answer}
              </Typography>
            )}
          </CardContent>
        ) : (
          <CardContent
            style={{
              minHeight: 200
            }}
          >
            <Typography variant="h5" component="h2">
              {!learnableSelectedAnkisList[0].length ? (
                'Congratulations !'
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    // Update 20 items
                    for (let i = 0; i < 10; i += 1) {
                      if (learnableSelectedAnkisList[0][i] !== undefined) {
                        learnableSelectedAnkisList[0][i].isLearning = true;

                        this.props.AnkiStackActions.updateNested(
                          'ankis',
                          JSON.parse(selectedAnkiStack).id,
                          learnableSelectedAnkisList[0][i]
                        );
                      }
                    }
                  }}
                >
                  Start Learning 10
                </Button>
              )}
            </Typography>
          </CardContent>
        )}

        {learningAnkisList.length > 0 && !editMode && (
          <CardActions>
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={() => {
                this.updateAnki('Okay', learningAnkisList[0]);
              }}
            >
              Okay
            </Button>
            <Button
              size="small"
              onClick={() => {
                this.updateAnki('Pass', learningAnkisList[0]);
              }}
            >
              Pass
            </Button>
            <Button
              size="small"
              style={{
                float: 'right'
              }}
              onClick={() => {
                this.setState({
                  editMode: !editMode
                });
              }}
            >
              Edit
            </Button>
          </CardActions>
        )}
      </Card>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    learnableSelectedAnkisList: getLearnableSelectedAnkiListSelector(state),
    selectedAnkiStack: state.ui.getIn(['anki', 'selectedAnkiStack']),
    learningAnkisList: getLearningAnkisListSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    AnkiStackActions: bindActionCreators(AnkiStackActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AnkiCard));
