import React from 'react';

import TaskRowSimplified from './TaskRowSimplified';

export default ({ tasksFilteredList, taskTypes, hideCompleted = false }) => {
  // const totalTasksCount = () => {
  //   let count = 0;
  //   taskTypes.forEach(type => {
  //     if (tasksFilteredList.has(type)) {
  //       count += tasksFilteredList.get(type).count();
  //     }
  //   });

  //   return count;
  // };

  // {
  //   /* <div className={clsx(classes.title)}>
  //     <span className={clsx(classes.titleName)}>
  //       {taskTypes.join(', ')} Tasks
  //     </span>
  //     {totalTasksCount()}
  //   </div> */
  // }

  return (
    <>
      {taskTypes.map(
        type =>
          tasksFilteredList.has(type) &&
          tasksFilteredList
            .get(type)
            .sort((a, b) => {
              if (
                a.get('targetCompletionDate') !== b.get('targetCompletionDate')
              ) {
                return a.get('targetCompletionDate') <
                  b.get('targetCompletionDate')
                  ? -1
                  : 1;
              }

              return a.get('isKeyTask') ? -1 : 1;
            })
            .filter(t => {
              if (hideCompleted) {
                return !t.get('isCompleted');
              }

              return true;
            })
            .map((record, index) => (
              <TaskRowSimplified
                key={index}
                record={record}
                index={index}
                type="daily"
              />
            ))
      )}
    </>
  );
};
