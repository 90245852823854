import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { AnkiStackActions } from '../../redux/modules/ankiStacks';

class AnkiItem extends Component {
  state = {};

  deleteAnki = record => {
    const { selectedAnkiStack } = this.props;

    // eslint-disable-next-line no-restricted-globals
    if (window.confirm(`Deleting anki: ${record.get('question')}`)) {
      this.props.AnkiStackActions.deleteNestedRecord(
        'ankis',
        JSON.parse(selectedAnkiStack).id,
        record
      );
    }
  };

  render() {
    const { record } = this.props;

    return (
      <TableRow>
        <TableCell align="left">
          {`${record.get('question')}/${record.get('answer')}`}
        </TableCell>
        <TableCell align="left">
          {record.get('learningTimes')}/{record.get('passingTimes')}
        </TableCell>
        <TableCell align="left">{record.get('passingLastDate')}</TableCell>
        <TableCell align="left">{record.get('learningNextDate')}</TableCell>
        <TableCell>
          <IconButton
            edge="end"
            aria-label="Delete"
            onClick={() => this.deleteAnki(record)}
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    selectedAnkiStack: state.ui.getIn(['anki', 'selectedAnkiStack']),
    record: ownProps.record
  };
}

function mapDispatchToProps(dispatch) {
  return {
    AnkiStackActions: bindActionCreators(AnkiStackActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AnkiItem);
