import ProCard from '@ant-design/pro-card';
import React from 'react';
import { useSelector } from 'react-redux';

export default () => {
  const currentUser = useSelector(state => state.user);

  return (
    <ProCard title="User Info" headerBordered>
      <div>
        <div>Email</div>
        <div>{currentUser.get('email')}</div>
      </div>

      <h2> User Settings</h2>

      {currentUser.has('userSettings') &&
        currentUser.get('userSettings').map((setting, index) => (
          <div key={index}>
            <span>{setting.get('key')}: </span>
            <span>{setting.get('value')}</span>
          </div>
        ))}
    </ProCard>
  );
};
