import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import parse from 'autosuggest-highlight/parse';
import { LocationActions } from '../../redux/modules/locations';

const styles = theme => ({});
const { google } = window;
class LocationNew extends Component {
  state = {
    searchResults: [],
    selectedLocation: null,
    selectedLocationGeometry: null,
    notes: ''
  };

  updateSearchResults = event => {
    const autoCompleteService = new google.maps.places.AutocompleteService();

    autoCompleteService.getPlacePredictions(
      { input: event.target.value },
      res => {
        this.setState({
          searchResults: res
        });
      }
    );
  };

  saveRecord = () => {
    const { selectedLocation, selectedLocationGeometry, notes } = this.state;
    const newRec = {
      description: selectedLocation.description,
      longitude: selectedLocationGeometry.location.lng(),
      latitude: selectedLocationGeometry.location.lat(),
      notes
    };

    this.props.LocationActions.create(newRec);
  };

  updateSelectedLocation = location => {
    this.setState({
      selectedLocation: location
    });

    const map = new google.maps.Map(document.getElementById('map'), {
      center: this.sydney,
      zoom: 15
    });

    // Get details
    const request = {
      query: location.description,
      fields: ['name', 'geometry']
    };

    const service = new google.maps.places.PlacesService(map);

    // let that = this;
    service.findPlaceFromQuery(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        this.setState({
          selectedLocationGeometry: results[0].geometry
          // selectedLocationLat: results[0].geometry.location.lat(),
          // selectedLocationLng: results[0].geometry.location.lng()
        });

        map.setCenter(results[0].geometry.location);
      }
    });
  };

  render() {
    const { classes } = this.props;
    const { searchResults, selectedLocationGeometry } = this.state;

    return (
      <Paper>
        <Autocomplete
          onChange={(event, value) => {
            this.updateSelectedLocation(value);
          }}
          id="combo-box-demo"
          options={searchResults}
          getOptionLabel={option => option.description}
          style={{ width: 300 }}
          renderInput={params => (
            <TextField
              {...params}
              label="search..."
              variant="standard"
              onKeyDown={this.updateSearchResults}
            />
          )}
          renderOption={option => {
            const matches =
              option.structured_formatting.main_text_matched_substrings;
            const parts = parse(
              option.structured_formatting.main_text,
              matches.map(match => [match.offset, match.offset + match.length])
            );

            return (
              <Grid container alignItems="center">
                <Grid item>
                  <LocationOnIcon className={classes.icon} />
                </Grid>
                <Grid item xs>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{ fontWeight: part.highlight ? 700 : 400 }}
                    >
                      {part.text}
                    </span>
                  ))}

                  <Typography variant="body2" color="textSecondary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            );
          }}
        />
        lat:{' '}
        {selectedLocationGeometry === null
          ? 'NA'
          : selectedLocationGeometry.location.lat()}
        / lng:
        {selectedLocationGeometry === null
          ? 'NA'
          : selectedLocationGeometry.location.lng()}
        <TextareaAutosize
          aria-label="minimum height"
          rowsMin={3}
          placeholder="Minimum 3 rows"
          style={{
            marginTop: 10,
            width: '100%'
          }}
          onChange={event => {
            this.setState({
              notes: event.target.value
            });
          }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => this.saveRecord()}
        >
          Save
        </Button>
      </Paper>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    LocationActions: bindActionCreators(LocationActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(LocationNew));
