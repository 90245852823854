import React from 'react';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import moment from 'moment';
import RoutineRowItem from './RoutineRowItem';

// const useStyles = makeStyles(() => ({}));

export default ({ value }) => {
  // const classes = useStyles();

  return (
    <TableRow
      style={{
        backgroundColor:
          moment().format('YYYY-MM-DD') === value.get('dailyRecordDate')
            ? 'green'
            : 'white'
      }}
    >
      <TableCell component="th" scope="row">
        {value.get('dailyRecordDate')}
      </TableCell>

      <TableCell component="th" scope="row">
        <RoutineRowItem
          value={value}
          filePaths={[['wakeHour'], ['wakeMinute']]}
        />
      </TableCell>

      <TableCell component="th" scope="row">
        <RoutineRowItem
          value={value}
          filePaths={[['sleepHour'], ['sleepMinute']]}
        />
      </TableCell>

      <TableCell component="th" scope="row">
        <RoutineRowItem value={value} filePaths={[['health', 'weight']]} />
      </TableCell>
    </TableRow>
  );
};
