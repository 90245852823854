import React, { useEffect } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory
} from 'react-router-dom';
// import ApolloClient from 'apollo-boost';
// import { InMemoryCache } from 'apollo-cache-inmemory';
// import { HttpLink } from 'apollo-link-http';
import { Layout } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

// import { currentMonthHabitSelector } from 'redux/modules/habits';
// import { selectedDailyPlannedHourblockSelector } from 'redux/modules/dailyPlannedHourblocks';

import { UiActions } from 'redux/modules/ui';

import LeftMenu from './components/organisms/LeftMenu';
import Hourblock from './1-Hourblock/_index';
import Workout from './5-Workout/_index';
import Setting from './9-Setting/index';
import Task from './3-Task/_index';
import Calendar from './2-2-Calendar';
import Routine from './4-Routine/_index';
import Planning from './2-Planning/_index';
import Anki from './8-Anki/_index';
import Location from './21-Location/_index';
import Housing from './22-Housing/_index';
import Diary from './23-Diary/index';
import Overview from './pages/Overview';
import BuildNumber from './components/atoms/BuildNumber';
import MobilePage from './indexMobile';

const { Content, Sider } = Layout;

export default () => {
  const dispatch = useDispatch();

  const isMobile = useSelector(state => state.ui.getIn(['common', 'isMobile']));

  const menuItems = [
    { path: '/hourblock', content: <Hourblock /> },
    { path: '/routine', content: <Routine /> },
    { path: '/workout', content: <Workout /> },
    { path: '/diary', content: <Diary /> },

    { path: '/planning', content: <Planning /> },
    { path: '/calendar', content: <Calendar /> },
    { path: '/task', content: <Task /> },

    { path: '/anki', content: <Anki /> },

    { path: '/setting', content: <Setting /> }
  ];

  // const history = useHistory();

  const downHandler = k => {
    // keyCode - 49 is 1
    const { keyCode, metaKey, shiftKey } = k;

    if (metaKey && keyCode >= 49 && keyCode <= 57) {
      // Push the route
      window.location.pathname = menuItems[keyCode - 49].path;
    } else if (metaKey && keyCode === 84) {
      // T + meta
      dispatch(UiActions.updateIn(['common', 'showTaskModal'], true));
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
  }, []);

  return (
    <Router>
      <Layout>
        <BuildNumber />
        {!isMobile && (
          <Sider
          // collapsible
          // collapsed={collapsed}
          // onCollapse={this.onCollapse}
          >
            <LeftMenu />
          </Sider>
        )}

        <Layout className="site-layout">
          {isMobile ? (
            <MobilePage />
          ) : (
            <Content
              className="site-layout-background"
              style={{
                margin: 0,
                overflow: 'auto',
                height: '100vh'
              }}
            >
              <Switch>
                <Route exact path="/">
                  <Redirect to="/hourblock" />
                </Route>

                {menuItems.map(i => (
                  <Route path={i.path}>{i.content}</Route>
                ))}

                {/* <Route path="/location">
                  <Location />
                </Route>
                <Route path="/housing">
                  <Housing />
                </Route> */}

                <Route path="/overview">
                  <Overview />
                </Route>
              </Switch>
            </Content>
          )}
        </Layout>
      </Layout>
    </Router>
  );
};
