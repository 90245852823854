import React from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';

import { Menu, Divider } from 'antd';
import ProCard from '@ant-design/pro-card';

import { YearlySubTaskActions } from 'redux/modules/yearlySubTasks';

export default ({ yearlySubTask }) => {
  const dispatch = useDispatch();

  return (
    <ProCard size="small" bordered>
      {yearlySubTask.get('name')}
      <Divider plain />

      <Menu size="small">
        <Menu.Item
          key="1"
          icon={<DeleteOutlined />}
          onClick={() => {
            if (window.confirm(`Deleting task: ${yearlySubTask.get('name')}`)) {
              dispatch(YearlySubTaskActions.deleteRecord(yearlySubTask.toJS()));
            }
          }}
        >
          Delete Sub Task
        </Menu.Item>
        <Menu.Item key="2">XXX</Menu.Item>
      </Menu>
    </ProCard>
  );
};
