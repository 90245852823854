import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Select from '@material-ui/core/Select';

const useStyles = makeStyles(() => ({
  select: {}
}));

export default ({ onChangeCallBack, currentValue, values }) => {
  const classes = useStyles();

  return (
    <Select
      native
      className={classes.select}
      value={`${currentValue}`}
      onChange={event => onChangeCallBack(event)}
    >
      <option value="" />
      {values.map(value => (
        <option value={`${value}`} key={`${value}`}>
          {`${value}`}
        </option>
      ))}
    </Select>
  );
};
