import React, { Component } from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
// import { Engine } from 'json-rules-engine';
import moment from 'moment';

import ProCard from '@ant-design/pro-card';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';

import { todayDailyRecordSelector } from '../../redux/modules/dailyRecords';

class Gamification extends Component {
  state = { rules: [], facts: {} };

  componentDidMount() {
    const rule = {
      conditions: {
        all: [
          {
            fact: 'currentTime',
            operator: 'greaterThanInclusive',
            value: 12
          },
          {
            fact: 'wakeupHour',
            operator: 'equal',
            value: ''
          },
          {
            fact: 'wakeupMinute',
            operator: 'equal',
            value: ''
          }
        ]
      },
      event: {
        type: 'didNotRecordWakeUp',
        params: {
          message: 'Did not record wake up time!'
        }
      }
    };

    this.setState({
      rules: [rule]
    });

    setInterval(_ => {
      this.triggerRule();
    }, 1000 * 60 * 5); // 2 minutes the ui update for
  }

  triggerRule = () => {
    // const { rules } = this.state;
    const { todayDailySummaryRecord } = this.props;

    const facts = {
      wakeupHour: todayDailySummaryRecord.hasIn(['health', 'wakeupHour'])
        ? todayDailySummaryRecord.getIn(['health', 'wakeupHour'])
        : null,
      wakeupMinute: todayDailySummaryRecord.hasIn(['health', 'wakeupMinute'])
        ? todayDailySummaryRecord.getIn(['health', 'wakeupMinute'])
        : null,
      sleepHour: todayDailySummaryRecord.hasIn(['health', 'sleepHour'])
        ? todayDailySummaryRecord.getIn(['health', 'sleepHour'])
        : null,
      sleepMinute: todayDailySummaryRecord.hasIn(['health', 'sleepMinute'])
        ? todayDailySummaryRecord.getIn(['health', 'sleepMinute'])
        : null,
      currentTime: moment().hours()
    };

    this.setState({
      facts
    });

    // const engine = new Engine();
    // engine.addRule(rules[0]);
  };

  render() {
    const { rules, facts } = this.state;

    return (
      <ProCard>
        Table to show a global warning message. [1] did not record wake up time
        and it is already 9pm [2]
        <Table
          size="small"
          style={{
            minWidth: 650
          }}
          aria-label="simple table"
        >
          <TableHead
            style={{
              background: 'whitesmoke'
            }}
          >
            <TableRow>
              <TableCell>Index</TableCell>
              <TableCell>Rule</TableCell>
              <TableCell>Facts</TableCell>
              <TableCell>-</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rules.map((rule, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell key={index}>{JSON.stringify(rule)}</TableCell>
                <TableCell>{JSON.stringify(facts)}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      this.triggerRule();
                    }}
                  >
                    Trigger
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </ProCard>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    todayDailySummaryRecord: todayDailyRecordSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Gamification);
