import React, { useState, useEffect } from 'react';
import { Input, notification } from 'antd';

export default () => {
  const { TextArea } = Input;

  const [text, setText] = useState('default');
  // const [saving, setSaving] = useState(false);

  const openNotification = () => {
    notification.open({
      message: 'Note updated',
      duration: 2
      // description:
      //   'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
      // onClick: () => {
      //   console.log('Notification Clicked!');
      // }
    });
  };

  useEffect(() => {
    setText(localStorage.getItem('text'));
  }, []);

  return (
    <TextArea
      rows={6}
      value={text}
      onKeyDown={event => {
        if (event.which === 13) {
          localStorage.setItem('text', text);
          openNotification();
        }
      }}
      onChange={v => {
        setText(v.target.value);
      }}
    />
  );
};
