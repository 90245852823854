import React from 'react';

import { Card } from 'antd';
import { useSelector } from 'react-redux';
import ProCard from '@ant-design/pro-card';

import { categoriesByNameSelector } from 'redux/modules/categories';
import { dailyPlannedHourblocksMetricsSelector } from 'redux/modules/dailyPlannedHourblocks';
import { todayTasksSelector } from 'redux/modules/dailyTasks';

import DailyTaskTable from '../components/organisms/Table/DailyTaskTable';

export default () => {
  // const { plannedPomosMetrics, categoriesByName, todayTasks } = this.props;

  const plannedPomosMetrics = useSelector(state =>
    dailyPlannedHourblocksMetricsSelector(state)
  );
  const categoriesByName = useSelector(state =>
    categoriesByNameSelector(state)
  );
  const todayTasks = useSelector(state => todayTasksSelector(state));

  return (
    <>
      {/* <ProCard size="small">
        {Object.values(plannedPomosMetrics[1]).length > 0
          ? Object.values(plannedPomosMetrics[1]).reduce((a, b) => a + b)
          : ''}

        {Object.values(plannedPomosMetrics[1]).length > 0 &&
          Object.keys(plannedPomosMetrics[1])
            .sort((a, b) => {
              return categoriesByName[a] === undefined
                ? -1
                : categoriesByName[a].order - categoriesByName[b].order;
            })
            .map(cat => (
              <span
                key={cat}
                style={{
                  borderWidth: 2,
                  borderStyle: 'solid',
                  borderColor:
                    categoriesByName[cat] !== undefined
                      ? `#${categoriesByName[cat].color}`
                      : 'black',
                  marginRight: 10,
                  color: 'black',
                  backgroundColor:
                    todayTasks.hasIn(['metrics', cat]) &&
                    todayTasks.getIn(['metrics', cat]) / 2 >
                      plannedPomosMetrics[1][cat]
                      ? '#2ecc71'
                      : !todayTasks.hasIn(['metrics', cat])
                      ? 'orange'
                      : '#c0392b'
                }}
              >
                {`${
                  categoriesByName[cat] !== undefined
                    ? categoriesByName[cat].name
                    : '-'
                } - ${
                  todayTasks.hasIn(['metrics', cat])
                    ? todayTasks.getIn(['metrics', cat]) / 2
                    : 0
                }/${plannedPomosMetrics[1][cat]}`}
              </span>
            ))}
      </ProCard>

      <br />

      <ProCard size="small">
        <DailyTaskTable tasksFilteredArray={todayTasks.get('tasks')} />
      </ProCard> */}
    </>
  );
};
