import React, { useState, useEffect } from 'react';
import { Line, Column } from '@ant-design/charts';
import { Radio, Row, Col } from 'antd';
import MATERIAL_COLORS from 'views/styles/colors';

export default ({
  chartName,
  dailyData,
  weeklyData,
  monthlyData,
  defaultTab = 'weekly',
  height = 200,
  additionalComments = '',
  chartType = 'line'
}) => {
  const [tabSelected, setTabSelected] = useState(defaultTab);
  const [chartData, setChartData] = useState([]);

  // const dataSet = (chartName, dataSetData, backgroundColor = 'green') => {
  //   return {
  //     showTooltips: false,
  //     label: chartName,
  //     spanGaps: true,
  //     fill: false,
  //     backgroundColor,
  //     lineTension: 0,
  //     borderColor: backgroundColor,
  //     borderWidth: 1,
  //     hoverBackgroundColor: backgroundColor,
  //     hoverBorderColor: backgroundColor,
  //     data: dataSetData
  //   };
  // };

  // It will have 3 keys: date, value and category
  const config = {
    data: chartData,
    height,
    xField: 'date',
    yField: 'value',
    seriesField: 'category',
    point: {
      size: 2,
      shape: 'diamond'
    },
    label: {
      style: {
        fill: '#aaa'
      }
    },
    color: [
      MATERIAL_COLORS.LightBlue,
      MATERIAL_COLORS.Primary,
      MATERIAL_COLORS.PrimaryLight
    ]
    // annotations: [
    //   {
    //     type: 'text',
    //     position: ['min', 'median'],
    //     content: '中位数',
    //     offsetY: -4,
    //     style: { textBaseline: 'bottom' }
    //   }
    // ]
  };

  useEffect(() => {
    if (defaultTab === 'daily') {
      setChartData(dailyData);
    }

    if (defaultTab === 'weekly') {
      setChartData(weeklyData);
    }

    if (defaultTab === 'monthly') {
      setChartData(monthlyData);
    }
  }, [weeklyData, dailyData, monthlyData]);

  const onChange = event => {
    if (event.target.value === 'daily') {
      setChartData(dailyData);
    }

    if (event.target.value === 'weekly') {
      setChartData(weeklyData);
    }

    if (event.target.value === 'monthly') {
      setChartData(monthlyData);
    }

    setTabSelected(event.target.value);
  };

  return (
    <>
      {/* size="small"
      style={{
        width: '100%'
      }} */}
      {/* <div>{chartName}</div> */}
      <Row
        style={{
          marginBottom: 16
        }}
      >
        <Col
          span={12}
          style={{
            fontSize: 16,
            fontWeight: 'bold'
          }}
        >
          {chartName}
        </Col>

        <Col
          span={12}
          style={{
            textAlign: 'right'
          }}
        >
          <Radio.Group value={tabSelected} onChange={onChange}>
            <Radio.Button value="daily" disabled={dailyData === undefined}>
              日
            </Radio.Button>
            <Radio.Button value="weekly" disabled={weeklyData === undefined}>
              周
            </Radio.Button>
            <Radio.Button value="monthly" disabled={monthlyData === undefined}>
              月
            </Radio.Button>
          </Radio.Group>
        </Col>
      </Row>
      {chartType === 'line' && <Line {...config} />}
      {chartType === 'column' && <Column {...config} />}
      {additionalComments.length > 0 && <hr />}
      <span>{additionalComments}</span>
    </>
  );
};
