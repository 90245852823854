import React from 'react';
import { isImmutable } from 'immutable';
import { useSelector } from 'react-redux';
import { DualAxes } from '@ant-design/charts';
import ProCard from '@ant-design/pro-card';
import { dailyRecordsDataForChartSelector } from 'redux/modules/dailyRecords';

export default () => {
  const dailyRecords = useSelector(state => state.dailyRecords);

  // [[days], [weights], [wake-up time], [sleep time]]
  const dailyRecordsDataForChart = useSelector(state =>
    dailyRecordsDataForChartSelector(state)
  );

  const dailyData = [];

  if (isImmutable(dailyRecords)) {
    dailyRecordsDataForChart[0].forEach((day, index) => {
      dailyData.push({
        date: day,
        wake: dailyRecordsDataForChart[2][index]
        // sleep: dailyRecordsDataForChart[3][index]
      });
    });
  }

  const config = {
    height: 300,
    data: [dailyData, dailyData],
    xField: 'date',
    yField: ['wake', 'sleep'],
    // ref https://g2plot.antv.vision/en/docs/api/plots/dual-axes/#max
    yAxis: {
      wake: {
        max: 1400,
        min: 600
      },
      sleep: {
        max: 2600,
        min: 2000
      }
    },
    geometryOptions: [
      {
        geometry: 'line',
        color: '#5B8FF9'
      },
      {
        geometry: 'line',
        color: '#5AD8A6'
      }
    ]
  };

  // isImmutable(dailyRecords)
  //   ? {
  //       data: dailyRecordsDataForChart[3],
  //       chartName: 'Rest Time',
  //       color: MATERIAL_COLORS.DarkBlue
  //     }
  //   : {};

  // Only saving wake time for now
  return (
    <ProCard size="small" type="inner" bordered>
      <div
        style={{
          fontSize: 16,
          fontWeight: 'bold'
        }}
      >
        Rest Time
      </div>
      <DualAxes
        {...config}
        style={{
          height: 200
        }}
      />
    </ProCard>
  );
};

// <Chart
//   chartName="Sleep Time"
//   defaultTab="daily"
//   // labels={isImmutable(dailyRecords) ? dailyRecordsDataForChart[0] : []}
//   dailyData={dailyData}
// />;
