import moment from 'moment';
import { createSelector } from 'reselect';
import { isImmutable } from 'immutable';
import _ from 'lodash';
import { actionCreate } from '../_base/actionCreate';
import { reducerCreate } from '../_base/reducerCreate';
import { todayDailyRecordSelector } from './dailyRecords';

export default reducerCreate('habit');
export const { HabitActions } = actionCreate('habit');

const getHabits = state => state.habits;
export const currentMonthHabitSelector = createSelector([getHabits], habits =>
  habits.has(moment().format('YYYY-MM'))
    ? habits.get(moment().format('YYYY-MM'))
    : null
);

// export const habitsByTypeSelector = createSelector([getHabits], habits => {
//   const res = {
//     daily: null,
//     weekly: null,
//     monthly: null,
//     noType: null
//   };
// });

export const habitsCompletionSelector = createSelector(
  [todayDailyRecordSelector, getHabits],
  (todayRecord, habits) => {
    const res = [0, 0]; // total habits to complete, habits completed tody
    // const habits = habits.valueSeq().toJS();
    if (isImmutable(todayRecord)) {
      habits
        .valueSeq()
        .toJS()
        .forEach(habit => {
          if (
            todayRecord.hasIn(['habit', habit.id]) &&
            todayRecord.getIn(['habit', habit.id])
          ) {
            res[0] += 1;
          }
          res[1] += 1;
        });
    }
    return res;
  }
);

const getHabitCompletedCount = (offset, habits) => {
  let habitCompletedCount = 0;

  _.each(habits, habit => {
    for (let i = 0; i < 7; i += 1) {
      const day = moment()
        .add(offset, 'week')
        .startOf('isoWeek')
        .add(i, 'day')
        .format('YYYY-MM-DD');
      if (
        habit.completedDates !== null &&
        habit.completedDates.indexOf(day) > -1
      ) {
        habitCompletedCount += 1;
      }
    }
  });

  return habitCompletedCount;
};

/**
 * Geting metrics for habit for display on left, mostly % completion
 * Return: [% of habit completion, last week]
 */
export const lastWeekHabitMetricsSelector = createSelector(
  [getHabits],
  habits => {
    const res = [0, 0];
    if (isImmutable(habits)) {
      const habitObject = habits.toJS();

      const dailyHabits = Object.values(habitObject).filter(
        a => a.type === 'daily'
      );

      res[0] = getHabitCompletedCount(0, dailyHabits);
      res[1] = getHabitCompletedCount(-1, dailyHabits);
      // From last monday to last sunday - get last week
    }

    return res;
  }
);

// Array of data and # of habits completed
// Only get last 30 days
export const dailyHabitCompletionSelector = createSelector(
  [getHabits],
  habits => {
    const res = {};
    if (isImmutable(habits)) {
      for (let i = 0; i < 30; i++) {
        res[
          moment()
            .add(-i, 'days')
            .format('YYYY-MM-DD')
        ] = 0;
      }

      const habitObject = habits.toJS();

      const dailyHabits = Object.values(habitObject).filter(
        a => a.type === 'daily'
      );

      dailyHabits.forEach(h => {
        if (h.completedDates !== null) {
          h.completedDates.split(' ').forEach(d => {
            if (res[d] !== undefined) res[d] += 1;
          });
        }
      });
    }

    return res;
  }
);
