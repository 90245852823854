import React from 'react';
import { Column } from '@ant-design/charts';
import { useSelector } from 'react-redux';
import { allWeeksHourblockByCategorySelector } from 'redux/modules/dailyRecords';

// {
//   /* <Bar
//         width={10}
//         height={height}
//         options={options}
//         data={{
//           labels,
//           datasets
//         }}
//       /> */
// }

export default ({ height = 200 }) => {
  const [allWeeksHourblockByCategory, categoriesColors] = useSelector(state =>
    allWeeksHourblockByCategorySelector(state)
  );

  const config = {
    color: categoriesColors,
    data: allWeeksHourblockByCategory,
    legend: {
      layout: 'horizontal',
      position: 'top'
    },
    isStack: true,
    xField: 'week',
    yField: 'count',
    seriesField: 'category'
  };

  return <Column {...config} />;
};
