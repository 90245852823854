import axios from 'axios';
import { message } from 'antd';
import BASE_URL from 'views/constants/urlConstants';
import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

const UserActions = {
  getCurrentUser() {
    return dispatch =>
      axios({
        method: 'GET',
        url: `${BASE_URL}/user`,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
          Accept: 'application/json'
        }
      })
        .then(res => {
          dispatch({
            type: 'LOAD_CURRENT_USER_SUCCESS',
            data: res.data
          });
        })
        .catch(error => {
          message.error(error);
        });
  }
};

const initialState = fromJS({});

const UserReducers = (state = initialState, action) => {
  switch (action.type) {
    case `LOAD_CURRENT_USER_SUCCESS`: {
      return fromJS(action.data);
    }

    default:
      return state;
  }
};

const getCurrentUser = state => state.user;

export const userSettingsMapperSelector = createSelector(
  [getCurrentUser],
  currentUser => {
    const res = {};

    if (currentUser.has('userSettings')) {
      currentUser
        .get('userSettings')
        .toJS()
        .forEach(rec => {
          res[rec.key] = rec.value;
        });
    }

    return res;
  }
);

export const userSettingsValueSelector = createSelector(
  [userSettingsMapperSelector, (state, key) => key],
  (userSettingsMapper, key) => {
    return userSettingsMapper[key];
  }
);

export { UserActions, UserReducers };
