import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Anki from './anki';
import AnkiStack from './ankiStack';

class AnkiPage extends Component {
  constructor(props) {
    super(props);

    // Only loading when use opens this page
    this.props.getAnkis();
  }

  state = {};

  render() {
    const selectedTab = this.props.location.pathname.split('/anki')[1];
    const selectedTabIndex = selectedTab.indexOf('stack') > -1 ? 1 : 0; // default first tab

    return (
      <React.Fragment>
        {selectedTabIndex === 0 && <Anki />}
        {selectedTabIndex === 1 && <AnkiStack />}
      </React.Fragment>
    );
  }
}

AnkiPage.defaultProps = {};

AnkiPage.propTypes = {};

function mapStateToProps(state, ownProps) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    getAnkis: () =>
      dispatch({
        type: 'LOADING_ANKIS'
      })
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AnkiPage)
);
