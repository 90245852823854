import ProCard from '@ant-design/pro-card';
import { Divider, Button, Space } from 'antd';
import React from 'react';

import moment from 'moment';

import { DeleteOutlined } from '@ant-design/icons';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import MATERIAL_COLORS from 'views/styles/colors';
import { useDispatch, useSelector } from 'react-redux';
import { HabitActions } from 'redux/modules/habits';

export default ({ habit }) => {
  const dispatch = useDispatch();

  const selectedDate = useSelector(state =>
    state.ui.getIn(['hourblock', 'selectedDate'])
  );

  const titleStyle = {
    color: 'gray',
    fontSize: 12
  };

  const isChecked = dateToCheck => {
    return (
      habit.get('completedDates') !== null &&
      habit.get('completedDates').indexOf(dateToCheck) > -1
    );
  };

  const getDateForMonth = i =>
    moment(selectedDate)
      .startOf('month')
      .add(i, 'days')
      .format('YYYY-MM-DD');

  const monthDates = [];
  for (let i = 0; i < 31; i += 1) {
    monthDates.push(i);
  }

  const getDate = i =>
    moment(selectedDate)
      .startOf('isoweek')
      .add(i, 'days')
      .format('YYYY-MM-DD');

  const padZero = num => (num !== null && num < 10 ? `0${num}` : num);

  const getConsecutiveCompletion = habit => {
    let res = 0;

    if (!habit.has('completedDates') || habit.get('completedDates') === '') {
      return res;
    }

    let arr = habit
      .get('completedDates')
      .replace(/\s+/g, ' ')
      .split(' ')
      .reverse();

    arr.forEach((t, index) => {
      if (
        moment(arr[0])
          .add(-index, 'days')
          .format('YYYY-MM-DD') === moment(t).format('YYYY-MM-DD')
      ) {
        res += 1;
      }
    });

    return res;
  };

  return (
    <ProCard
      size="small"
      title={habit.get('name')}
      bordered
      headerBordered
      style={{
        maxWidth: 300
      }}
    >
      {/* <div style={titleStyle}>Manage Current Date Count</div>
      <Space>
        <Button size="small">+1</Button>

        <Button size="small">-1</Button>
      </Space>
      <Divider style={{ margin: '5px 0' }} plain /> */}
      <div style={titleStyle}>Time</div>
      {padZero(habit.get('targetCompletionHour'))}:
      {padZero(habit.get('targetCompletionMinute'))}
      <div style={titleStyle}>Habit Details</div>
      Goal: {habit.get('goal')}
      <Divider style={{ margin: '5px 0' }} plain />
      <div style={titleStyle}>Weekly completed dates</div>
      {[0, 1, 2, 3, 4, 5, 6].map(i => (
        <span key={i}>
          <FiberManualRecordIcon
            style={{
              color: isChecked(getDate(i))
                ? MATERIAL_COLORS.Primary
                : getDate(i) === selectedDate
                ? MATERIAL_COLORS.LightRed
                : MATERIAL_COLORS.LightGray
            }}
            fontSize="small"
          />
        </span>
      ))}
      <Divider style={{ margin: '5px 0' }} plain />
      <div style={titleStyle}>Monthly completed dates</div>
      {monthDates.map(i => (
        <span key={i}>
          <FiberManualRecordIcon
            style={{
              color: isChecked(getDateForMonth(i))
                ? MATERIAL_COLORS.Primary
                : getDate(i) === selectedDate
                ? MATERIAL_COLORS.LightRed
                : MATERIAL_COLORS.LightGray
            }}
            fontSize="small"
          />
        </span>
      ))}
      <Divider style={{ margin: '5px 0' }} plain />
      <div style={titleStyle}>Consecutive completed counts</div>
      {getConsecutiveCompletion(habit)}
      <Divider style={{ margin: '5px 0' }} plain />
      <div style={titleStyle}>Operations</div>
      <DeleteOutlined
        onClick={() => {
          if (window.confirm(`Deleting task: ${habit.get('name')}`)) {
            dispatch(HabitActions.deleteRecord(habit.toJS()));
          }
        }}
      />
    </ProCard>
  );
};
