import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import clsx from 'clsx';

import { useSelector, useDispatch } from 'react-redux';

import { fromJS, isImmutable } from 'immutable';
import { Dropdown } from 'antd';

import { UiActions } from 'redux/modules/ui';
import {
  DailyPlannedHourblockActions,
  selectedDailyPlannedHourblockSelector
} from 'redux/modules/dailyPlannedHourblocks';

import { orderedCategoriesSelector } from 'redux/modules/categories';

import RightBlock from './RightBlock';
import LeftBlock from './LeftBlock';
import HourblockItemMainTask from './HourblockItemMainTask';
import HourblockItemRightClickBox from './HourblockItemRightClickBox';
import MATERIAL_COLORS from 'views/styles/colors';

export default ({ block, curentSectionOfDay, selectedDailyRecord }) => {
  const itemStyle = {
    paddingLeft: 5,
    paddingRight: 5,
    height: 40
    // borderBottom: '1px #bdc3c7 solid'
  };
  const itemSectionBottomStyle = {
    paddingLeft: 5,
    paddingRight: 5,
    height: 40
  };

  const dispatch = useDispatch();

  const orderedCategories = useSelector(state =>
    orderedCategoriesSelector(state)
  );

  const savingPomo = useSelector(state =>
    state.ui.getIn(['hourblock', 'savingPomo'])
  );

  const selectedPlannedPomo = useSelector(state =>
    selectedDailyPlannedHourblockSelector(state, block)
  );

  const updateDailyPlannedHourblock = (type, value, updatedRecord) => {
    dispatch(UiActions.updateIn(['hourblock', 'savingPomo'], true));

    const newRec = updatedRecord.set(type, value);

    dispatch(DailyPlannedHourblockActions.update(newRec)).then(() => {
      dispatch(UiActions.updateIn(['hourblock', 'savingPomo'], false));
    });
  };

  const getSelectedDailyRecordHourblock = () => {
    if (
      isImmutable(selectedDailyRecord) &&
      selectedDailyRecord.has('dailyRecordHourblocks') &&
      selectedDailyRecord.get('dailyRecordHourblocks') !== null
    ) {
      return selectedDailyRecord
        .get('dailyRecordHourblocks')
        .find(rec => rec.get('sectionOfDay') === block.index);
    }

    return fromJS({});
  };

  const getColorOfItem = () => {
    if (curentSectionOfDay === block.index) {
      return MATERIAL_COLORS.Primary;
    }

    if (savingPomo) {
      return '#f39c12';
    }

    return curentSectionOfDay > block.index &&
      getSelectedDailyRecordHourblock() !== undefined &&
      !getSelectedDailyRecordHourblock().has('sectionOfDay') &&
      selectedPlannedPomo !== undefined
      ? '#e74c3c'
      : 'white';
  };

  return (
    <ListItem
      className={clsx(
        block.index % 4 === 3 ? itemSectionBottomStyle : itemStyle,
        'hourblockListItem'
      )}
      style={{
        backgroundColor: getColorOfItem(),
        padding: '0 5px'
      }}
    >
      <LeftBlock
        block={block}
        selectedDailyRecord={selectedDailyRecord}
        orderedCategories={orderedCategories}
        selectedPlannedPomo={selectedPlannedPomo}
      />
      {/* <LockIcon
          style={{
            paddingRight: '5px',
            marginRight: '5px',
            borderRight: '1px solid black',
            color:
              isImmutable(selectedPlannedPomo) &&
              selectedPlannedPomo.getIn(['isLocked'])
                ? 'green'
                : 'whitesmoke'
          }}
        /> */}

      <Dropdown
        overlay={
          <HourblockItemRightClickBox
            selectedPlannedPomo={selectedPlannedPomo}
          />
        }
        trigger={['contextMenu']}
      >
        <div
          style={{
            flex: 1,
            marginRight: 5
          }}
        >
          <HourblockItemMainTask
            block={block}
            updateDailyPlannedHourblock={updateDailyPlannedHourblock}
            selectedPlannedPomo={selectedPlannedPomo}
          />

          {/* <HourblockItemRecurTask
          block={block}
          selectedPlannedPomo={selectedPlannedPomo}
          selectedDailyRecordHourblock={getSelectedDailyRecordHourblock()}
        /> */}
        </div>
      </Dropdown>

      {/* This is the dropdown select */}
      <RightBlock
        block={block}
        selectedPlannedPomo={selectedPlannedPomo}
        selectedDailyRecord={selectedDailyRecord}
        selectedDailyRecordHourblock={getSelectedDailyRecordHourblock()}
      />
    </ListItem>
  );
};
