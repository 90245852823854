import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tag, Calendar } from 'antd';
import { UiActions } from 'redux/modules/ui';

import { tasksByDateSelector } from 'redux/modules/dailyTasks';
import { workoutRecordsByDateSelector } from 'redux/modules/workoutRecords';

export default ({ type }) => {
  const dispatch = useDispatch();

  const onSelect = v => {
    dispatch(
      UiActions.updateIn(['hourblock', 'selectedDate'], v.format('YYYY-MM-DD'))
    );
  };

  const tasksByDate = useSelector(state => tasksByDateSelector(state));
  const workoutRecordsByDate = useSelector(state =>
    workoutRecordsByDateSelector(state)
  );

  const dateCellRenderWorkout = value => {
    const currentDate = value.format('YYYY-MM-DD');

    // Show number of tasks
    return (
      <>
        {workoutRecordsByDate.has(currentDate) &&
        workoutRecordsByDate.get(currentDate).size ? (
          <Tag
            color="green"
            style={{
              padding: '0 5px',
              fontSize: 10
            }}
          >
            {workoutRecordsByDate.get(currentDate).size}
          </Tag>
        ) : (
          ''
        )}
      </>
    );
  };

  const dateCellRenderTask = value => {
    const currentDate = value.format('YYYY-MM-DD');

    // Show number of tasks
    return (
      <>
        {tasksByDate.has(currentDate) &&
        tasksByDate.get(currentDate).filter(t => !t.get('isCompleted')).size ? (
          <Tag
            style={{
              padding: '0 5px',
              fontSize: 10
            }}
          >
            {tasksByDate.get(currentDate).size}
          </Tag>
        ) : (
          ''
        )}
      </>
    );
  };

  return (
    <div
      style={{
        width: '100%',
        border: '1px solid #f0f0f0',
        borderRadius: 2
      }}
    >
      <Calendar
        fullscreen={false}
        onSelect={onSelect}
        dateCellRender={
          type === 'workout' ? dateCellRenderWorkout : dateCellRenderTask
        }
      />
    </div>
  );
};
