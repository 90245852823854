import React, { useState, useEffect } from 'react';
import { Card, Select, Space, InputNumber, Button, Switch } from 'antd';

import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';

import { WorkoutRecordActions } from 'redux/modules/workoutRecords';

import { sortedWorkoutsSelector } from '../../../redux/modules/workouts';

import { todayDailyRecordSelector } from '../../../redux/modules/dailyRecords';

const { Option } = Select;

export default ({
  selectedRecordName,
  selectedSet,
  selectedRep,
  selectedWeight
}) => {
  const isMobile = useSelector(state => state.ui.getIn(['common', 'isMobile']));

  const dispatch = useDispatch();

  const [isWorkout, setIsWorkout] = useState(true);
  const [workoutId, setWorkoutId] = useState('');
  const [set, setSet] = useState(null);
  const [rep, setRep] = useState(null);
  const [weight, setWeight] = useState(null);
  const [distance, setDistance] = useState(null);
  const [time, setTime] = useState(null);

  const todayDailySummaryRecord = useSelector(state =>
    todayDailyRecordSelector(state)
  );

  const sortedWorkouts = useSelector(state => sortedWorkoutsSelector(state));

  const saveWorkoutRecord = () => {
    let newRecord = {
      workoutId: JSON.parse(workoutId).id,
      workoutDate: moment().format('YYYY-MM-DD'), // FIXME
      resistanceSets: set,
      resistanceRepetitions: rep,
      resistanceWeightsKg: weight
    };

    dispatch(WorkoutRecordActions.create(newRecord)).then(res => {
      setWorkoutId('');
      setSet(4);
      setRep(0);
      setWeight(0);
    });
  };

  const saveAerobic = () => {
    dispatch(
      WorkoutRecordActions.create({
        workoutDate: moment().format('YYYY-MM-DD'), // FIXME
        runDistanceKm: parseFloat(distance),
        runTimeMinutes: parseFloat(time)
      })
    ).then(res => {
      setDistance(0);
      setTime(0);
    });
  };

  const capFirstLetter = str => str.replace(/^\w/, c => c.toUpperCase());

  useEffect(() => {
    if (selectedRecordName && selectedRecordName.length) {
      const rec = sortedWorkouts
        .valueSeq()
        .toJS()
        .find(workout => workout.name === selectedRecordName);

      setWorkoutId(rec.workoutId);
    }

    if (selectedSet !== '') {
      setSet(selectedSet);
    }
    if (selectedRep !== '') {
      setRep(selectedRep);
    }

    if (selectedWeight !== '') {
      setWeight(selectedWeight);
    }
  }, [selectedRecordName]);

  return (
    <Card size="small">
      <Switch
        checkedChildren="W"
        unCheckedChildren="A"
        defaultChecked
        onChange={v => setIsWorkout(v)}
        style={{
          marginRight: 10
        }}
      />

      {/* <Typography component="div">
        <Box fontSize={16}>{moment().format('YYYY-MM-DD')}</Box>
      </Typography> */}

      {isWorkout ? (
        <>
          <Select
            size="small"
            style={{
              width: 240
            }}
            value={workoutId}
            onChange={e => {
              setWorkoutId(e);
            }}
          >
            <Option value="" />
            {sortedWorkouts
              .valueSeq()
              .toJS()
              .map(workout => (
                <Option value={workout.workoutId} key={JSON.stringify(workout)}>
                  {`${workout.focus}${workout.isMachine ? '[M]' : ''} - ${
                    workout.name
                  }`}
                </Option>
              ))}
          </Select>

          {['set', 'rep', 'weight'].map(i => (
            <InputNumber
              key={i}
              label={i}
              type="number"
              value={eval(i)}
              placeholder={i}
              style={{
                margin: '0px 5px 5px 5px'
              }}
              onChange={e => eval(`set${capFirstLetter(i)}(${e})`)}
              size="small"
            />
          ))}

          <Button
            size="small"
            type="primary"
            onClick={() => saveWorkoutRecord()}
          >
            Save
          </Button>
        </>
      ) : (
        <Space wrap>
          {['distance', 'time'].map(i => (
            <InputNumber
              key={i}
              label={i}
              type="number"
              value={eval(i)}
              placeholder={i}
              onChange={e => eval(`set${capFirstLetter(i)}(${e})`)}
              size="small"
            />
          ))}

          <Button size="small" type="primary" onClick={() => saveAerobic()}>
            Save
          </Button>
        </Space>
      )}
    </Card>
  );
};
