import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

import { DailyTaskActions, Task } from 'redux/modules/dailyTasks';
import TaskRowMainTask from 'views/components/molecules/task/TaskRowMainTask';
import { Input, Button } from 'antd';

export default ({ day, tasks }) => {
  const dispatch = useDispatch();

  const [newTaskName, setNewTaskName] = useState('');
  const [estimatedHours, setEstimatedHours] = useState(0);

  const updateDailyPlannedHourblock = () => {
    const newTask = new Task(newTaskName, true, day, '', '');

    dispatch(DailyTaskActions.create(newTask.asJSON));

    setNewTaskName('');
  };

  return (
    <React.Fragment>
      <div>
        <span
          style={{
            fontWeight: 'bold',
            color: moment(day).isoWeekday() > 5 ? 'green' : 'black'
          }}
        >
          {day} ({moment(day).format('ddd')})
        </span>
        {` - Tasks: ${tasks.size}`}

        <div
          style={{
            float: 'right'
          }}
        >
          <Input
            id="taskName"
            size="small"
            value={newTaskName}
            style={{ width: 200 }}
            placeholder="set task name"
            onChange={event => setNewTaskName(event.target.value)}
            onKeyDown={event => {
              if (event.which === 13) {
                updateDailyPlannedHourblock();
              }
            }}
          />

          <Input
            id="hour"
            size="small"
            value={estimatedHours}
            type="number"
            style={{ width: 200 }}
            placeholder="set pomo plan"
            onChange={event => setEstimatedHours(event.target.value)}
          />

          <Button
            variant="contained"
            size="small"
            onClick={() => updateDailyPlannedHourblock()}
          >
            Save
          </Button>
        </div>
      </div>
      <hr />

      <Table
        size="small"
        style={{
          minWidth: 650
        }}
        aria-label="simple table"
      >
        <TableBody>
          {tasks
            .sort((a, b) => {
              if (a.get('isKeyTask') === b.get('isKeyTask')) {
                return a.getIn(['ref', 'category']) <
                  b.getIn(['ref', 'category'])
                  ? 1
                  : -1;
              }
              return a.get('isKeyTask') ? -1 : 1;
            })
            .map((task, taskIndex) => (
              <TaskRowMainTask key={taskIndex} task={task} type="daily" />
            ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
};
