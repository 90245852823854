import React from 'react';
import moment from 'moment';
import { Row, Col, PageHeader, Tag } from 'antd';

import { useSelector } from 'react-redux';

import { PageContainer } from '@ant-design/pro-layout';
import HourblockTable from 'views/1-Hourblock/HourblockTable';
import HourblockTableHeaderButtons from './HourblockTableHeaderButtons';
import HourblockRightPanel from './rightPanel/HourblockRightPanel';
import HourblockChartModal from '../components/organisms/Chart/ChartHourblockModal';
import ChartHourblock from 'views/components/organisms/Chart/ChartHourblock';

const RIGHT_COL_WIDTH = 380;

export default () => {
  // const dispatch = useDispatch();

  // const showWeeklyPlanned = useSelector(state =>
  //   state.ui.getIn(['hourblock', 'showWeeklyPlanned'])
  // );

  // const showRightBLock = useSelector(state =>
  //   state.ui.getIn(['hourblock', 'showRightBLock'])
  // );

  const selectedDate = useSelector(state =>
    state.ui.getIn(['hourblock', 'selectedDate'])
  );

  return (
    <PageContainer
      fixedHeader
      // style={{ height: '100%', padding: '10px 5px' }}
      className="site-page-header"
      // onBack={() => null}
      title={moment(selectedDate).format('YYYY-MM-DD')}
      subTitle={moment(selectedDate).format('ddd')}
      tags={
        moment().format('YYYY-MM-DD') ===
          moment(selectedDate).format('YYYY-MM-DD') && (
          <Tag color="green">TODAY</Tag>
        )
      }
      extra={[<HourblockTableHeaderButtons selectedDate={selectedDate} />]}
    >
      <HourblockChartModal />

      <HourblockTable selectedDate={selectedDate} />

      <ChartHourblock showHourblockOnly />
    </PageContainer>
  );
};
