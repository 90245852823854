import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import { categoriesByNameSelector } from 'redux/modules/categories';

import {
  AnkiStackActions,
  ankiStackSortedListSelector
} from '../../redux/modules/ankiStacks';

class AnkiStacksPage extends Component {
  state = {
    name: ''
  };

  saveAnkiStack = () => {
    const { name } = this.state;
    this.props.AnkiStackActions.create({
      name
    }).then(() => {
      this.setState({
        name: ''
      });
    });
  };

  render() {
    const { ankiStacks, categoriesByName } = this.props;
    const { name } = this.state;

    const handleChange = varName => event => {
      this.setState({
        [varName]: event.target.value
      });
    };

    return (
      <React.Fragment>
        <Paper>
          <TextField
            id="standard-name"
            label="Name"
            // className={classes.textField}
            value={name}
            margin="dense"
            multiline
            onChange={handleChange('name')}
            variant="outlined"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.saveAnkiStack()}
          >
            Save
          </Button>
        </Paper>
        <br />

        <Paper>
          {ankiStacks !== undefined &&
            ankiStacks.map(ankiStack => (
              <React.Fragment key={ankiStack.get('name')}>
                <Select
                  native
                  autoWidth
                  style={{
                    // height: "inherit",
                    width: 80,
                    backgroundColor:
                      categoriesByName !== undefined &&
                      categoriesByName[ankiStack.get('category')] !== undefined
                        ? `#${
                            categoriesByName[ankiStack.get('category')].color
                          }`
                        : 'whitesmoke'
                  }}
                  value={ankiStack.get('category')}
                  onChange={event => {
                    const newRec = ankiStack.set(
                      'category',
                      event.target.value
                    );
                    this.props.AnkiStackActions.update(newRec.delete('ankis'));
                  }}
                >
                  <option value="" />
                  {Object.values(categoriesByName).map(cat => (
                    <option value={cat.name} key={cat.id}>
                      {`${cat.order} ${cat.name}`}
                      {/* ${proj.category.name} */}
                    </option>
                  ))}
                </Select>

                <TextField
                  label="Name"
                  style={{ width: 300 }}
                  margin="dense"
                  // placeholder={record.get('name')}
                  defaultValue={ankiStack.get('name')}
                  onKeyDown={event => {
                    if (event.which === 13) {
                      const newRecord = ankiStack.set(
                        'name',
                        event.target.value
                      );
                      this.props.AnkiStackActions.update(newRecord);
                    }
                  }}
                  variant="outlined"
                />

                <span>
                  Anki Count:{' '}
                  {ankiStack.has('ankis') ? ankiStack.get('ankis').size : 0}
                </span>

                <IconButton
                  edge="end"
                  disabled={
                    ankiStack.has('ankis') && ankiStack.get('ankis').size > 0
                  }
                  aria-label="Delete"
                  onClick={() => {
                    // eslint-disable-next-line no-restricted-globals
                    if (
                      window.confirm(
                        `Deleting ankistack ${ankiStack.get('name')}`
                      )
                    ) {
                      this.props.AnkiStackActions.deleteRecord(ankiStack);
                    }
                  }}
                >
                  <DeleteIcon />
                </IconButton>
                <br />
              </React.Fragment>
            ))}
        </Paper>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    ankiStacks: ankiStackSortedListSelector(state),
    categoriesByName: categoriesByNameSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    AnkiStackActions: bindActionCreators(AnkiStackActions, dispatch)
    // UiActions: bindActionCreators(UiActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AnkiStacksPage);
