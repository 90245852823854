import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import { Menu, Tag } from 'antd';
import { MailOutlined } from '@ant-design/icons';

import LoopIcon from '@material-ui/icons/Loop';
import TodayIcon from '@material-ui/icons/Today';

import { makeStyles } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import SettingsIcon from '@material-ui/icons/Settings';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import PeopleIcon from '@material-ui/icons/People';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import { useHistory } from 'react-router-dom';
import MapIcon from '@material-ui/icons/Map';
import HouseIcon from '@material-ui/icons/House';
import NoteIcon from '@material-ui/icons/Note';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';

import moment from 'moment';

import MATERIAL_COLORS from 'views/styles/colors';

import {
  todayTasksSelector,
  unfinishedTasksSelector
} from 'redux/modules/dailyTasks';
import {
  dailyRecordMetricsSelector,
  scoreSelectedDailyRecordSelector
} from 'redux/modules/dailyRecords';

import ProgressBars from './ProgressBars';

// const { SubMenu } = Menu;

// import { last4WeekAveragePomoSelector } from 'redux/modules/dailyRecordSummaries';
// import { habitsCompletionSelector } from 'redux/modules/habits';

const categories = [
  {
    id: 'RECORDING',
    children: [
      {
        id: 'Hourblock',
        icon: <HourglassFullIcon />,
        path: 'hourblock'
      },
      {
        id: 'Routine',
        icon: <LoopIcon />,
        path: 'routine'
      },
      {
        id: 'Workout',
        icon: <FitnessCenterIcon />,
        path: 'workout'
      },
      {
        id: 'Diary',
        icon: <HouseIcon />,
        path: 'diary',
        defaultSubheader: ''
      }
    ]
  },
  {
    id: 'PLANNING',
    children: [
      {
        id: 'Planning',
        icon: <TodayIcon />,
        path: 'planning'
      },
      {
        id: 'Calendar',
        icon: <FormatListNumberedIcon />,
        path: 'calendar'
      },
      {
        id: 'Task',
        icon: <FormatListNumberedIcon />,
        path: 'task'
      }
    ]
  },
  {
    id: 'LEARNING',
    children: [
      {
        id: 'Anki',
        icon: <NoteIcon />,
        path: 'anki',
        defaultSubheader: 'Anki'
      }
      // 		{ id: 'Analytics', icon: <SettingsIcon /> },
      // 		{ id: 'Performance', icon: <TimerIcon /> },
      // 		{ id: 'Test Lab', icon: <PhonelinkSetupIcon /> },
    ]
  },
  {
    id: 'INSIGHTS',
    children: [
      // {
      //   id: 'Location',
      //   icon: <MapIcon />,
      //   path: 'location',
      //   defaultSubheader: ''
      // },
      // {
      //   id: 'Housing',
      //   icon: <HouseIcon />,
      //   path: 'housing',
      //   defaultSubheader: ''
      // }
    ]
  },
  {
    id: 'ADMIN',
    children: [
      // {
      //   id: 'User',
      //   icon: <PeopleIcon />,
      //   path: 'user'
      // },
      // { id: 'Setting', icon: <SettingsIcon />, path: '/setting' }
    ]
  }
];

const useStyles = makeStyles({
  categoryHeader: {
    paddingTop: 5, // theme.spacing(1),
    paddingBottom: 0 // theme.spacing(1)
  },
  categoryHeaderPrimary: {
    color: 'white',

    borderBottom: `1px solid ${MATERIAL_COLORS.DarkerGray}`
  },
  categoryHeaderPrimaryText: {
    fontSize: 12,
    fontWeight: 'bold'
  },
  item: {
    paddingTop: 1,
    paddingBottom: 1,
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover,&:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)'
    }
  },
  itemIcon: {
    color: 'white'
  },

  scoreSection: {
    color: 'white',
    textAlign: 'center'
  },
  scoreSectionText: {
    color: 'white',
    width: 200,
    fontSize: 18,
    marginTop: 10
  },
  detailSection: {
    fontSize: 24
  },
  details: {
    fontSize: 14
  },
  score: props => ({
    fontSize: 36,
    fontWeight: 'bold',
    color:
      props.scoreSelectedDailyRecord.total >= 75
        ? MATERIAL_COLORS.Primary
        : props.scoreSelectedDailyRecord.total >= 50
        ? MATERIAL_COLORS.Primary
        : MATERIAL_COLORS.PrimaryLight
  })
});

export default () => {
  const todayTasks = useSelector(state => todayTasksSelector(state));
  const unfinishedTasks = useSelector(state => unfinishedTasksSelector(state));

  const dailyRecordMetrics = useSelector(state =>
    dailyRecordMetricsSelector(state)
  );

  const scoreSelectedDailyRecord = useSelector(state =>
    scoreSelectedDailyRecordSelector(state)
  );

  const classes = useStyles({
    scoreSelectedDailyRecord
  });

  const menuStyle = {
    height: 30,
    paddingLeft: 15,
    margin: 0
  };
  // const todayDailyKeyTasksCount =
  //   tasksFilteredList !== undefined && tasksFilteredList.has('Today')
  //     ? tasksFilteredList
  //         .get('Today')
  //         .filter(r => r.get('isKeyTask'))
  //         .count()
  //     : 0;
  // const todayDailyKeyTasksCompletedCount =
  //   tasksFilteredList !== undefined && tasksFilteredList.has('Today')
  //     ? tasksFilteredList
  //         .get('Today')
  //         .filter(r => r.get('isKeyTask') && r.get('isCompleted'))
  //         .count()
  //     : 0;

  const history = useHistory();

  const [selectedTab, setSelectedTab] = useState(history.location.pathname);

  const handleClick = e => {
    const joinedPath = `/${e.keyPath.reverse().join('/')}`;
    setSelectedTab(joinedPath);
    history.push(joinedPath);
  };

  const getJwtExpiry = () => {
    const token = sessionStorage.getItem('accessToken');
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return moment(JSON.parse(jsonPayload).exp * 1000).fromNow();
  };

  return (
    <>
      <Menu
        onClick={handleClick}
        style={{ width: 200 }}
        defaultSelectedKeys={['1']}
        selectedKeys={selectedTab.split('/').reverse()}
        mode="inline"
        theme="dark"
      >
        <div
          style={{
            height: 50
          }}
        />

        <Menu.Item
          key="overview"
          icon={<MailOutlined />}
          title="Overview"
          style={menuStyle}
        >
          Overview
        </Menu.Item>

        {categories.map(({ id, children }) => (
          <React.Fragment key={id}>
            <ListItem className={classes.categoryHeader}>
              <ListItemText className={classes.categoryHeaderPrimary}>
                <span className={classes.categoryHeaderPrimaryText}>{id}</span>
              </ListItemText>
            </ListItem>

            {children.map(({ id: childId, icon, path }) => (
              <Menu.Item
                key={path}
                icon={icon}
                title={childId}
                style={menuStyle}
              >
                {childId}

                {/* <Badge size="small" count={22} /> */}
                {childId === 'Hourblock' && (
                  <Tag
                    style={{
                      float: 'right',
                      marginTop: 10
                      // minWidth: 20
                    }}
                    color={
                      dailyRecordMetrics.filledHourblockHour <= 24
                        ? 'orange'
                        : 'green'
                    }
                    size="small"
                  >
                    {dailyRecordMetrics.filledHourblockHour}/24
                  </Tag>
                )}
              </Menu.Item>
            ))}
          </React.Fragment>
        ))}

        <Menu.Item
          key="logout"
          icon={<MailOutlined />}
          title="Logout"
          style={menuStyle}
        >
          <span
            onClick={() => {
              sessionStorage.removeItem('accessToken');
              window.location.reload();
            }}
          >
            Logout
          </span>

          <Tag size="small">{getJwtExpiry()}</Tag>
        </Menu.Item>
      </Menu>

      {/* TODO: hiding score for now */}
      {/* <div className={classes.scoreSection}>
        <div className={classes.scoreSectionText}>
          <Tooltip
            title="Daily Points: 25 (Pomo) for 12H core work, 50 for 12H completing task,
          25 for completing 5 key tasks
          <br />
          Weekly Points: 25% for completing the planned hours, 25% for
          completing weekly tasks, 50% from daily points
          <br />
          Need to customize this"
            placement="bottom"
          >
            <span>Current Day Score</span>
          </Tooltip>
        </div>

        <div className={classes.score}>{scoreSelectedDailyRecord.total}</div>
        <span className={classes.detailSection}>
          {scoreSelectedDailyRecord.pointFromPomo}
          <span className={classes.details}>/25</span> +
          {scoreSelectedDailyRecord.pointFromCompletingTaskPomo}
          <span className={classes.details}>/50</span> +
          {scoreSelectedDailyRecord.pointFromCompletingKeyTasks}
          <span className={classes.details}>/25</span>
        </span>
      </div> */}

      <ProgressBars />
    </>
  );
};
