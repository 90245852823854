import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Button, Dropdown, Menu } from 'antd';
import moment from 'moment';
import {
  SyncOutlined,
  EllipsisOutlined,
  PushpinOutlined,
  ClearOutlined,
  CalendarOutlined
} from '@ant-design/icons';

import IconButton from '@material-ui/core/IconButton';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import { useDispatch, useSelector } from 'react-redux';
import { UiActions } from 'redux/modules/ui';
import { DailyPlannedHourblockActions } from 'redux/modules/dailyPlannedHourblocks';

import {
  DailyRecordActions,
  selectedDailyRecordSelector
} from 'redux/modules/dailyRecords';
import HourblockHeaderPomo from './HourblockHeaderPomo';
import MATERIAL_COLORS from 'views/styles/colors';

const useStyles = makeStyles(() => ({
  rightButton: {
    marginRight: -1
  }
}));

export default ({ selectedDate }) => {
  const isMobile = useSelector(state => state.ui.getIn(['common', 'isMobile']));

  const classes = useStyles({ selectedDate, isMobile });

  const selectedDailyRecord = useSelector(state =>
    selectedDailyRecordSelector(state)
  );

  const isShowingRecommendedTask = useSelector(state =>
    state.ui.getIn(['hourblock', 'isShowingRecommendedTask'])
  );

  const hourblockShowChart = useSelector(state =>
    state.ui.getIn(['hourblock', 'showChart'])
  );

  const showWeeklyPlanned = useSelector(state =>
    state.ui.getIn(['hourblock', 'showWeeklyPlanned'])
  );

  const showRightBLock = useSelector(state =>
    state.ui.getIn(['hourblock', 'showRightBLock'])
  );

  const showCalendarView = useSelector(state =>
    state.ui.getIn(['hourblock', 'showCalendarView'])
  );

  const dailyPlannedHourblocks = useSelector(
    state => state.dailyPlannedHourblocks
  );

  const dispatch = useDispatch();

  const changeDay = offset => {
    dispatch(
      UiActions.updateIn(
        ['hourblock', 'selectedDate'],
        moment(selectedDate)
          .add(offset, 'days')
          .format('YYYY-MM-DD')
      )
    );
  };

  const unsetPlannedPomo = () => {
    // Unset planned pomo from 6-24 for today...
    const res = window.confirm(
      'Sure to clear all planned tasks on this day (except sleep)?'
    );
    if (!res) return;

    const dayOfWeek =
      moment(selectedDailyRecord.get('dailyRecordDate')).isoWeekday() - 1;

    const startSectionOfWeek = dayOfWeek * 48 + 6 * 2; // Skipping the first 6 hours for zzz
    const endSectionOfWeek = (dayOfWeek + 1) * 48;

    dailyPlannedHourblocks
      .valueSeq()
      .toJS()
      .forEach(block => {
        if (
          block.sectionOfWeek >= startSectionOfWeek &&
          block.sectionOfWeek < endSectionOfWeek &&
          !block.taskMainLocked
        ) {
          block.projectId = null;
          dispatch(DailyPlannedHourblockActions.update(block));
        }
      });
  };

  const updateCalendar = () => {
    dispatch(DailyPlannedHourblockActions.updateCalendarFile());
  };

  const syncTodayData = () => {
    dispatch(DailyRecordActions.get(selectedDailyRecord));
    dispatch(DailyPlannedHourblockActions.getAll());
  };

  const goToCurrentDateTime = () => {
    dispatch(
      UiActions.updateIn(
        ['hourblock', 'selectedDate'],
        moment().format('YYYY-MM-DD')
      )
    );

    const currentSectionOfDay =
      moment().hour() * 2 + parseInt(moment().minute() / 30, 10); // 1-indexed

    const ind = currentSectionOfDay > 0 ? currentSectionOfDay - 1 : 0;
    if (document.getElementById('hourblockList') !== null) {
      document
        .getElementsByClassName('hourblockListItem')
        [ind].scrollIntoView(); // Scroll to current section
    }
  };

  const createNewDailyRecord = () => {
    dispatch(
      DailyRecordActions.create({
        dailyRecordDate: selectedDate
      })
    );
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <IconButton
          color="inherit"
          onClick={() => {
            dispatch(
              UiActions.updateIn(
                ['hourblock', 'showWeeklyPlanned'],
                !showWeeklyPlanned
              )
            );
          }}
        >
          <HelpOutlineIcon color={showWeeklyPlanned ? 'primary' : 'action'} />
          Show Weekly Plan
        </IconButton>
      </Menu.Item>
      <Menu.Item>
        <IconButton
          color="inherit"
          onClick={() => {
            dispatch(
              UiActions.updateIn(
                ['hourblock', 'showRightBLock'],
                !showRightBLock
              )
            );
          }}
        >
          <HelpOutlineIcon color={showRightBLock ? 'primary' : 'action'} />
          Show Right Block
        </IconButton>
      </Menu.Item>
    </Menu>
  );

  const DropdownMenu = () => (
    <Dropdown key="more" overlay={menu}>
      <Button
        style={{
          border: 'none',
          padding: 0
        }}
      >
        <EllipsisOutlined
          style={{
            fontSize: 20,
            verticalAlign: 'top'
          }}
        />
      </Button>
    </Dropdown>
  );

  return (
    <>
      {/* Only able to create if the record does not exist and the record is in future */}
      {selectedDailyRecord === undefined && (
        <Button
          variant="contained"
          // color="secondary"
          className={classes.rightButton}
          onClick={() => {
            createNewDailyRecord();
          }}
        >
          +
        </Button>
      )}

      {/* <Button
        variant="contained"
        // color="secondary"
        className={classes.rightButton}
        onClick={() => {
          updateCalendar();
        }}
        icon={<CalendarOutlined />}
      /> */}

      <Button
        variant="contained"
        className={classes.rightButton}
        style={{
          color: showCalendarView ? MATERIAL_COLORS.LightBlue : 'black'
        }}
        onClick={() => {
          dispatch(
            UiActions.updateIn(
              ['hourblock', 'showCalendarView'],
              !showCalendarView
            )
          );
        }}
        icon={<CalendarOutlined />}
      />

      <Button
        variant="contained"
        // color="secondary"
        className={classes.rightButton}
        onClick={() => {
          syncTodayData();
        }}
        icon={<SyncOutlined />}
      />

      <Button
        variant="contained"
        type="secondary"
        className={classes.rightButton}
        onClick={() => changeDay(-1)}
      >
        -1
      </Button>

      <Button className={classes.rightButton} onClick={() => changeDay(1)}>
        + 1
      </Button>

      <Button
        // color="secondary"
        className={classes.rightButton}
        onClick={() => {
          goToCurrentDateTime();
        }}
        // icon={<DownloadOutlined />}
      >
        NOW
      </Button>

      <Button
        variant="contained"
        className={classes.rightButton}
        onClick={() => {
          unsetPlannedPomo();
        }}
        icon={<ClearOutlined />}
      />

      {/* <Button
        className={classes.rightButton}
        onClick={() => {
          dispatch(
            UiActions.updateIn(['hourblock', 'showChart'], !hourblockShowChart)
          );
        }}
        icon={<LineChartOutlined />}
      /> */}

      <Button
        type={isShowingRecommendedTask ? 'primary' : 'default'}
        className={classes.rightButton}
        onClick={() => {
          dispatch(
            UiActions.updateIn(
              ['hourblock', 'isShowingRecommendedTask'],
              !isShowingRecommendedTask
            )
          );
        }}
        icon={<PushpinOutlined />}
      />

      {!isMobile && <HourblockHeaderPomo />}

      {/* <Button
              style={{
                float: 'right'
              }}
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => {
                this.scrollToCurrent(curentSectionOfDay);
              }}
            >
              Go Current
              <RefreshIcon />
            </Button> */}
    </>
  );
};
