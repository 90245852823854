import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import DailyTaskTable from 'views/components/organisms/Table/DailyTaskTable';
// import clsx from 'clsx';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import { tasksFilteredListSelector } from 'redux/modules/dailyTasks';

const useStyles = makeStyles(() => ({
  modalContent: {
    // maxHeight: 600
  }
}));

export default ({ openTaskModal, setOpenTaskModal }) => {
  const classes = useStyles();

  const tasksFilteredList = useSelector(state =>
    tasksFilteredListSelector(state)
  );

  return (
    <Dialog
      // habit.has('habitId') && habit.get('habitId') === 4
      open={openTaskModal}
      fullWidth
      maxWidth="lg"
      onClose={() => setOpenTaskModal(false)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Edit Tasks</DialogTitle>
      <DialogContent className={classes.modalContent}>
        <DailyTaskTable tasksFilteredArray={tasksFilteredList.get('All')} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenTaskModal(false)} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
