/**
 * Getting various pomo metrics for display
 * @param {dailyRecord} record
 * @param {project object, including categories} categoriesByProject
 */
export const getMetricsForOneDailyRecord = (record, categoriesByProject) => {
  const res = {
    isPomoHour: 0,
    filledHourblockHour: 0,
    isProjectFollowPlan: 0,
    isCompletedTaskMainHour: 0,
    isCompletedTaskRecurHour: 0,
    isPomodoroed: 0
  };

  if (
    record !== undefined &&
    record.dailyRecordHourblocks !== undefined &&
    record.dailyRecordHourblocks !== null
  ) {
    Object.values(record.dailyRecordHourblocks).forEach(p => {
      res.filledHourblockHour += 0.5;

      if (Object.values(categoriesByProject).length) {
        if (
          categoriesByProject[p.projectId] !== undefined &&
          categoriesByProject[p.projectId].category.isPomo
        ) {
          res.isPomoHour += 0.5;
        }
      }

      if (p.isProjectFollowPlan) {
        res.isProjectFollowPlan += 0.5;
      }

      if (p.isCompletedTaskMain) {
        res.isCompletedTaskMainHour += 0.5;
      }

      if (p.isCompletedTaskRecur) {
        res.isCompletedTaskRecurHour += 0.5;
      }

      if (p.isPomodoroed) {
        res.isPomodoroed += 0.5;
      }

      // if (p.recurTaskFinished) {
      //   res.recurTaskFinishedHour += 0.5;
      // }
    });
  }

  return res;
};

export const scoreSelectedDailyRecordSelectorUtil = (
  dailyRecordMetrics,
  selectedDayDailyTasks
) => {
  let pointFromPomo = 0; // out of 25
  let pointFromCompletingTaskPomo = 0; // from 50
  let pointFromCompletingKeyTasks = 0; // from 25

  const countKeyTasks = selectedDayDailyTasks
    .filter(a => a.get('isKeyTask'))
    .count();

  const completedCountKeyTasks = selectedDayDailyTasks
    .filter(a => a.get('isKeyTask') && a.get('isCompleted'))
    .count();

  pointFromPomo =
    dailyRecordMetrics.isPomoHour >= 12
      ? 25
      : dailyRecordMetrics.isPomoHour * 2;

  pointFromCompletingTaskPomo =
    dailyRecordMetrics.isCompletedTaskMainHour >= 12
      ? 50
      : dailyRecordMetrics.isCompletedTaskMainHour * 4;

  pointFromCompletingKeyTasks = parseInt(
    (completedCountKeyTasks / (countKeyTasks || 1)) * 25,
    10
  );

  return {
    pointFromPomo,
    pointFromCompletingTaskPomo,
    pointFromCompletingKeyTasks,
    total:
      pointFromPomo + pointFromCompletingTaskPomo + pointFromCompletingKeyTasks
  };
};
