import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { Card } from 'antd';

import {
  getDataForDailyRecordsSelector,
  getWeeklyPomoChartSelector,
  getMonthylPomoChartSelector
} from 'redux/modules/dailyRecordSummaries';

import Chart from './Chart';

export default ({ showHourblockOnly = false }) => {
  const graphDataForDailyRecords = useSelector(state =>
    getDataForDailyRecordsSelector(state)
  );

  const weeklyPomoCount = useSelector(state =>
    getWeeklyPomoChartSelector(state)
  );

  const monthlyPomoCount = useSelector(state =>
    getMonthylPomoChartSelector(state)
  );

  const dailyData = [];
  const weeklyData = [];
  const monthlyData = [];

  Object.keys(graphDataForDailyRecords)
    .sort((a, b) =>
      graphDataForDailyRecords[a].day < graphDataForDailyRecords[b].day ? 1 : -1
    )
    .forEach(index => {
      dailyData.push({
        date: graphDataForDailyRecords[index].day,
        value: graphDataForDailyRecords[index].totalRecorded / 2, // Show in hour
        category: 'Record'
      });

      dailyData.push({
        date: graphDataForDailyRecords[index].day,
        value: graphDataForDailyRecords[index].isUsefulHourblock,
        category: 'Pomo'
      });

      // dailyData.push({
      //   date: graphDataForDailyRecords[index].day,
      //   value: graphDataForDailyRecords[index].isProjectFollowPlanCount,
      //   category: 'Followed Pomo'
      // });
    });

  Object.keys(weeklyPomoCount)
    .sort((a, b) => (a < b ? 1 : -1))
    .forEach(week => {
      weeklyData.push({
        date: week,
        value: weeklyPomoCount[week][0],
        category: 'Pomo'
      });

      // weeklyData.push({
      //   date: week,
      //   value: weeklyPomoCount[week][1],
      //   category: 'Followed Pomo'
      // });

      // weeklyData.push({
      //   date: week,
      //   value: weeklyPomoCount[week][3],
      //   category: 'Pomo'
      // });

      // weekCount.push(weeklyPomoCount[week][0]);
      // weekFollowCount.push(weeklyPomoCount[week][1]);
      // weekPomodoroHourCount.push(weeklyPomoCount[week][3]);
    });

  Object.keys(monthlyPomoCount)
    .sort((a, b) => (a < b ? 1 : -1))
    .forEach(month => {
      monthlyData.push({
        date: month,
        value: monthlyPomoCount[month][0],
        category: 'Hourblock'
      });

      // monthlyData.push({
      //   date: month,
      //   value: monthlyPomoCount[month][1],
      //   category: 'Follow'
      // });

      // monthlyData.push({
      //   date: month,
      //   value: monthlyPomoCount[month][3],
      //   category: 'Pomo'
      // });

      // weekCount.push(weeklyPomoCount[week][0]);
      // weekFollowCount.push(weeklyPomoCount[week][1]);
      // weekPomodoroHourCount.push(weeklyPomoCount[week][3]);
    });

  return (
    <>
      <Card
        size="small"
        style={{
          width: '100%'
        }}
      >
        <Chart
          chartName="Hourblock Chart"
          defaultTab="daily"
          dailyData={dailyData}
          weeklyData={weeklyData}
          monthlyData={monthlyData}
        />
      </Card>

      {!showHourblockOnly && (
        <Card
          size="small"
          style={{
            width: '100%'
          }}
        >
          <Chart
            chartName="Hourblock Chart"
            defaultTab="weekly"
            dailyData={dailyData}
            weeklyData={weeklyData}
            monthlyData={monthlyData}
          />
        </Card>
      )}

      {!showHourblockOnly && (
        <Card
          size="small"
          style={{
            width: '100%'
          }}
        >
          <Chart
            chartName="Hourblock Chart"
            defaultTab="monthly"
            dailyData={dailyData}
            weeklyData={weeklyData}
            monthlyData={monthlyData}
          />
        </Card>
      )}
    </>
  );
};
