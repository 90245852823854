import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal, Input, DatePicker, Space, Checkbox, Select } from 'antd';
import { UiActions } from 'redux/modules/ui';
import moment from 'moment';
import { DailyTaskActions } from 'redux/modules/dailyTasks';
import { orderedCategoriesSelector } from 'redux/modules/categories';

export default () => {
  const [isKeyTask, setIsKeyTask] = useState(false);
  const [projectId, setProjectId] = useState('');
  const categories = useSelector(state => orderedCategoriesSelector(state));

  const { Option } = Select;

  const showModal = useSelector(state =>
    state.ui.getIn(['common', 'showTaskModal'])
  );

  const selectedDate = useSelector(state =>
    state.ui.getIn(['hourblock', 'selectedDate'])
  );

  const [name, setName] = useState('');
  const [targetCompletionDate, setTargetCompletionDate] = useState(
    selectedDate
  );

  const inputRef = React.useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    setTargetCompletionDate(selectedDate);

    inputRef.current.focus({
      cursor: 'start'
    });
  }, [selectedDate]);

  return (
    <Modal
      title="Quick Task Adder"
      width="60vw"
      visible={showModal}
      // onOk={() => {
      //   dispatch(UiActions.updateIn(['common', 'showTaskModal'], false));
      // }}
      okButtonProps={{ disabled: true }}
      // cancelButtonProps={{ disabled: true }}
      onCancel={() => {
        dispatch(UiActions.updateIn(['common', 'showTaskModal'], false));
      }}
    >
      {/* Note that by default this will be daily tasks */}

      <Space>
        <DatePicker
          value={moment(targetCompletionDate)}
          onChange={(date, dateString) => {
            setTargetCompletionDate(dateString);
          }}
        />

        <Select
          value={projectId}
          onChange={value => {
            setProjectId(value);
          }}
          style={{
            minWidth: 140
          }}
        >
          <Option value="" />
          {categories
            .valueSeq()
            .toJS()
            .map(cat =>
              Object.values(cat.projects)
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map(pro => (
                  <Option value={pro.id} key={`${cat.name},${pro.name}`}>
                    {`${cat.displayOrder}.${cat.name}: ${pro.name}`}
                  </Option>
                ))
            )}
        </Select>

        <Input
          ref={inputRef}
          id="name"
          style={{
            width: 300
          }}
          value={name}
          onChange={event => setName(event.target.value)}
          onKeyDown={event => {
            // enter
            if (event.which === 13) {
              const regex = /\d+\/\d+/g;
              const foundDate = name.match(regex);
              console.log(name, foundDate);

              let completedDate = null;
              if (foundDate !== null && foundDate.length) {
                completedDate = moment(foundDate[0])
                  .year(moment().year())
                  .format('YYYY-MM-DD');
              }

              console.log('🔥🔥projectId🔥🔥');
              console.log(projectId);

              const recordData = {
                name,
                yearlyTaskId: null,
                targetCompletionDate:
                  completedDate === null ? targetCompletionDate : completedDate,
                projectId: projectId === null ? null : parseInt(projectId, 10),
                isKeyTask,
                isCompleted: false,
                targetCompletionPomo: 0
              };

              dispatch(DailyTaskActions.create(recordData));
              dispatch(UiActions.updateIn(['common', 'showTaskModal'], false));
            }
          }}
        />

        <Checkbox checked={isKeyTask} onChange={() => setIsKeyTask(!isKeyTask)}>
          Imp
        </Checkbox>
      </Space>
    </Modal>
  );
};
