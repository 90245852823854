import React from 'react';

import ProCard from '@ant-design/pro-card';
import HabitList from 'views/components/organisms/List/HabitList';
import { Divider } from 'antd';

import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { UiActions } from 'redux/modules/ui';
import ChartHabit from 'views/components/organisms/Chart/ChartHabit';

export default () => {
  const dispatch = useDispatch();
  const routineTypes = ['daily', 'weekly', 'monthly'];

  const showHiddenHabits = useSelector(state =>
    state.ui.getIn(['hourblock', 'showHiddenHabits'])
  );

  // const handleChangeRoutine = (event, newValue) => {
  //   setRoutineValue(newValue);
  // };

  const updateHiddenHabit = () =>
    dispatch(
      UiActions.updateIn(['hourblock', 'showHiddenHabits'], !showHiddenHabits)
    );

  return (
    <ProCard
      size="small"
      ghost
      // bordered
      bordered="false"
      // headerBordered
      direction="column"
      gutter={[0, 8]}
      style={{
        background: 'whitesmoke'
      }}
    >
      {routineTypes.map(routineType => (
        <ProCard
          size="small"
          extra={
            showHiddenHabits ? (
              <EyeOutlined onClick={() => updateHiddenHabit()} />
            ) : (
              <EyeInvisibleOutlined onClick={() => updateHiddenHabit()} />
            )
          }
          title={`${routineType} habits`}
          type="inner"
          bordered
        >
          <HabitList habitType={routineType} />

          <Divider />

          {routineType === 'daily' && <ChartHabit />}
        </ProCard>
      ))}
    </ProCard>
  );
};
