import React from 'react';

import { isImmutable } from 'immutable';

import _ from 'lodash';
// import Tooltip from '@material-ui/core/Tooltip';
import { useSelector, useDispatch } from 'react-redux';

import { CheckOutlined, ClearOutlined, CloseOutlined } from '@ant-design/icons';

// import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import { Button, Badge, Popconfirm } from 'antd';
import ProjectSelect from 'views/components/atoms/ProjectSelect';
import { UiActions } from 'redux/modules/ui';
import moment from 'moment';
// import { DailyRecordHourblockActions } from 'redux/modules/hourblock/dailyRecordHourblocks';

import { DailyRecordActions } from 'redux/modules/dailyRecords';
import {
  DailyPlannedHourblockActions,
  selectedSectionSelector
} from 'redux/modules/dailyPlannedHourblocks';

import { categoriesByProjectSelector } from 'redux/modules/categories';
import MATERIAL_COLORS from 'views/styles/colors';
import { DailyRecordSummaryActions } from 'redux/modules/dailyRecordSummaries';
import { DailyTaskActions } from 'redux/modules/dailyTasks';
import { DailyRecordHourblockActions } from 'redux/modules/dailyRecordHourblocks';

// import { loadavg } from 'os';

export default ({
  block,
  selectedPlannedPomo,
  selectedDailyRecordHourblock,
  selectedDailyRecord
}) => {
  const savingPomo = useSelector(state =>
    state.ui.getIn(['hourblock', 'savingPomo'])
  );

  const categoriesByProject = useSelector(state =>
    categoriesByProjectSelector(state)
  );

  const sectionOfWeek = useSelector(state =>
    selectedSectionSelector(state, block)
  );

  const dispatch = useDispatch();

  /**
   * Show the selected project for a dailyRecordHourblock
   */
  const selectedProject = () => {
    const res = { category: {} };

    if (categoriesByProject) {
      if (
        selectedDailyRecordHourblock !== undefined &&
        selectedDailyRecordHourblock.has('projectId') &&
        categoriesByProject[selectedDailyRecordHourblock.get('projectId')] !==
          undefined
      ) {
        return categoriesByProject[
          selectedDailyRecordHourblock.get('projectId')
        ];
      }
    }
    return res;
  };

  const getIconColor = field =>
    selectedDailyRecordHourblock !== undefined &&
    selectedDailyRecordHourblock.has(field) &&
    selectedDailyRecordHourblock.get(field)
      ? MATERIAL_COLORS.Primary
      : MATERIAL_COLORS.LightGray;

  /**
   * Create the pomoRecord and update UI
   * @param {new pomo object} newPomo
   */
  const createDailyRecordHourblock = dailyRecordHourblock => {
    dispatch(
      DailyRecordHourblockActions.create(
        // 'dailyRecordHourblocks',
        // selectedDailyRecord.get('id'),
        dailyRecordHourblock,
        'dailyRecordHourblockId'
      )
    ).then(() => {
      dispatch(UiActions.updateIn(['hourblock', 'savingPomo'], false));
      dispatch(DailyRecordActions.getAll());
    });
  };

  /**
   * Saving the pomoRecord and update UI
   * @param {new pomo object} newPomo
   */
  const updateDailyPomoRecord = dailyRecordHourblock => {
    dispatch(
      DailyRecordHourblockActions.update(
        // 'dailyRecordHourblocks',
        // selectedDailyRecord.get('id'),
        dailyRecordHourblock,
        dailyRecordHourblock.get('dailyRecordHourblockId')
      )
    ).then(() => {
      dispatch(UiActions.updateIn(['hourblock', 'savingPomo'], false));
      dispatch(DailyRecordActions.getAll());
    });
  };

  // const updateRecord = (field, valueToSet = null) => {
  //   dispatch(UiActions.updateIn(['hourblock', 'savingPomo'], true));

  //   const newDailyRecordHourblock = selectedDailyRecordHourblock.set(
  //     field,
  //     valueToSet !== null
  //       ? valueToSet
  //       : !selectedDailyRecordHourblock.get(field)
  //   );

  //   dispatch(
  //     DailyRecordActions.updateNested(
  //       'dailyRecordHourblocks',
  //       selectedDailyRecord.get('dailyRecordId'),
  //       newDailyRecordHourblock,
  //       newDailyRecordHourblock.get('dailyRecordHourblockId')
  //     )
  //   ).then(() => {
  //     dispatch(UiActions.updateIn(['hourblock', 'savingPomo'], false));
  //   });
  // };

  /**
   * Return newDailyRecordHourblock
   */
  const getMainTaskAndUnset = () => {
    const taskMain =
      selectedPlannedPomo.get('taskMain') != null &&
      selectedPlannedPomo.get('taskMain').length > 0
        ? selectedPlannedPomo.get('taskMain')
        : '';

    if (taskMain.length > 0) {
      const newPlannedHourblock = selectedPlannedPomo.set('taskMain', '');
      dispatch(DailyPlannedHourblockActions.update(newPlannedHourblock));
    }

    return taskMain;
  };

  /**
   * Update the current daily record hourblock - selected project
   * Replace current mainTask with the plannedPomo mainTask
   * @param {???} categoryName selected catgoryBane
   * @param {???} projectName selected proejctName
   */
  const updateExistingHourblock = (categoryName, projectName) => {
    dispatch(UiActions.updateIn(['hourblock', 'savingPomo'], true));

    const selectedProjectToUpdate = _.find(
      Object.values(categoriesByProject),
      rec => {
        return rec.name === projectName;
      }
    );

    const taskMain = getMainTaskAndUnset();

    let newDailyRecordHourblock = null;

    if (selectedDailyRecordHourblock !== undefined) {
      const newRecordFields = {
        taskMain: taskMain.length
          ? taskMain
          : selectedDailyRecordHourblock.get('taskMain')
      };

      // if (selectedProjectToUpdate != null) {
      newRecordFields.projectId =
        selectedProjectToUpdate != null ? selectedProjectToUpdate.id : null;
      // }

      newDailyRecordHourblock = selectedDailyRecordHourblock.merge(
        newRecordFields
      );

      updateDailyPomoRecord(newDailyRecordHourblock);
    } else {
      newDailyRecordHourblock = {
        dailyRecordId: selectedDailyRecord.get('id'),
        // sectionOfWeek,
        sectionOfDay: block.index,
        taskMain,
        isPomodoroed: false,
        isProjectFollowPlan: false,
        isCompletedTaskMain: false
      };

      if (selectedProjectToUpdate != null) {
        newDailyRecordHourblock.projectId = selectedProjectToUpdate.projectId;
      }

      createDailyRecordHourblock(newDailyRecordHourblock);
    }

    // FIXME: there will be delay as actions are not synchronous
    dispatch(DailyRecordSummaryActions.getAll());
  };

  const saveNewHourblock = isFollowed => {
    // If there is recur task then must finish first
    if (
      isImmutable(selectedPlannedPomo) &&
      selectedPlannedPomo.get('taskRecur') !== ''
    ) {
      const res = window.confirm(
        `Did you complete ${selectedPlannedPomo.get('taskRecur')}`
      );
      if (!res) {
        return;
      }
    }

    dispatch(UiActions.updateIn(['hourblock', 'savingPomo'], true));

    const newDailyRecordHourblock = {
      dailyRecordId: selectedDailyRecord.get('id'),
      taskMain: getMainTaskAndUnset(),
      isPomodoroed: true,
      isProjectFollowPlan:
        categoriesByProject[selectedPlannedPomo.toJS().projectId].category
          .isPomo && isFollowed, // Follow only considered is
      isCompletedTaskMain: false,
      sectionOfDay: block.index,

      projectId: isFollowed ? selectedPlannedPomo.get('projectId') : 17
    };

    createDailyRecordHourblock(newDailyRecordHourblock);
    dispatch(DailyRecordSummaryActions.getAll());
  };

  return (
    <>
      {/* {selectedDailyRecordHourblock !== undefined &&
        selectedDailyRecordHourblock.getIn(['projectId']) !== null && (
          <div
            style={{
              height: 25,
              padding: 0,
              width: 80,
              display: 'block'
            }}
          >
            <HourglassOutlined
              style={{
                color: getIconColor('isPomodoroed')
              }}
              onClick={() => {
                updateRecord('isPomodoroed');
              }}
            />
            <PlaylistAddCheckIcon
              style={{
                color: getIconColor('isCompletedTaskMain')
              }}
              onClick={() => {
                updateRecord('isCompletedTaskMain');
              }}
            />
            <CheckCircleOutlined
              style={{
                marginLeft: 5,
                color: getIconColor('isCompletedTaskRecur')
              }}
              onClick={() => {
                updateRecord('isCompletedTaskRecur');
              }}
            />
          </div>
        )} */}

      {/* <Tooltip title="Finish Minor">
        <DoneAllIcon
          style={{
            height: 25,
            width: 25,
            color: getIconColor('recurTaskFinished')
          }}
          onClick={() => {
            const newPomo = updateRecord('recurTaskFinished');
            updateDailyPomoRecord(newPomo);
          }}
        />
      </Tooltip> */}

      {/* <div
        style={{
          height: 25,
          padding: 0,
          width: 80,
          overflow: 'hidden',
          display: 'block'
        }}
      >
        <ProjectSelect
          selectedProject={selectedProject()}
          onChangeCallBack={updateExistingHourblock}
        />
      </div> */}

      {selectedDailyRecordHourblock === undefined ||
      selectedDailyRecordHourblock.get('projectId') === null ? (
        <div
          style={{
            height: 25,
            padding: 0,
            width: 100,
            textAlign: 'center',
            overflow: 'hidden',
            display: 'block'
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              saveNewHourblock(true);
            }}
            disabled={
              savingPomo ||
              !isImmutable(selectedPlannedPomo) ||
              selectedPlannedPomo.getIn(['projectId']) === null
            }
            style={{
              width: 30,
              color: 'white',
              backgroundColor:
                savingPomo ||
                !isImmutable(selectedPlannedPomo) ||
                selectedPlannedPomo.getIn(['projectId']) === null
                  ? MATERIAL_COLORS.Gray
                  : MATERIAL_COLORS.Primary
            }}
            icon={<CheckOutlined />}
          />

          <Button
            variant="outlined"
            onClick={() => {
              saveNewHourblock(false);
            }}
            disabled={savingPomo}
            style={{
              width: 30,
              color: 'white',
              backgroundColor: savingPomo
                ? MATERIAL_COLORS.Gray
                : MATERIAL_COLORS.LightRed
            }}
            icon={<CloseOutlined />}
          />

          <Button
            variant="outlined"
            disabled={
              savingPomo ||
              !isImmutable(selectedPlannedPomo) ||
              selectedPlannedPomo.getIn(['projectId']) === null
            }
            onClick={() => {
              // Saving the task to right tasks
              dispatch(
                DailyTaskActions.create({
                  name: selectedPlannedPomo.get('taskMain'),
                  yearlyTaskId: null,
                  targetCompletionDate: moment().format('YYYY-MM-DD'),
                  projectId: selectedPlannedPomo.getIn(['projectId']),
                  isKeyTask: false,
                  isCompleted: false,
                  targetCompletionPomo: 0
                })
              );
            }}
            style={{
              // width: 20,
              // width: 25,
              color: 'black',
              textAlign: 'center',
              padding: '-2px 7px 0',
              backgroundColor: 'whitesmoke'
            }}
            icon={<ClearOutlined />}
          />
        </div>
      ) : (
        <div
          style={{
            height: 25,
            padding: 0,
            width: 80,
            overflow: 'hidden',
            display: 'block'
          }}
        >
          <ProjectSelect
            disabled={savingPomo}
            selectedProject={selectedProject()}
            onChangeCallBack={updateExistingHourblock}
          />
          <Badge
            status={
              selectedDailyRecordHourblock.get('isProjectFollowPlan')
                ? 'success'
                : 'default'
            }
            style={{
              position: 'absolute',
              right: -15,
              top: 10
            }}
          />
        </div>
      )}
    </>
  );
};
